@import "config";
@import "icon_names";

.btn {
	border-radius: 4px;
	font-size: 15px;
	font-weight: 400;
	min-height: 40px;
	display: inline-flex;
	justify-content: center;
	padding: 5px 46px;
	align-items: center;
	text-decoration: none;
	color: map_get($colors, black);
	background: map_get($colors, white);
	position: relative;
	flex-shrink: 0;
	border: 1px solid transparent;
	line-height: 1;

	&:active {
		opacity: .9;
	}

	&:focus {
		box-shadow: none !important;
	}

	.icon {
		$size: 24px;
		width: $size;
		height: $size;
		line-height: $size;
		position: absolute;
		left: 12px;
		top: 8px;
		margin: -1px 0 0 -1px;
		font-size: 18px;
		transition: none;

		&~.icon {
			left: auto;
			right: 8px;
		}
	}

	&._green {
		background: map_get($colors, green);
		color: map_get($colors, white);

		@include hover() {
			background: map_get($colors, dgreen) !important;
			color: map_get($colors, white) !important;
		}
	}

	&._blue {
		background: map_get($colors, blue);
		color: map_get($colors, white);

		@include hover() {
			background: map_get($colors, dblue) !important;
			color: map_get($colors, white) !important;
		}
	}

	&._pblue {
		background: map_get($colors, pblue);
		color: map_get($colors, blue);
		padding: 10px 16px;

		@include hover() {
			background: map_get($colors, blue) !important;
			color: map_get($colors, white) !important;
		}
	}

	&._red {
		background: map_get($colors, red);
		color: map_get($colors, white);

		@include hover() {
			background: #e94344 !important;
			color: map_get($colors, white) !important;
		}
	}

	&._circle {
		width: 32px;
		min-height: 32px;
		border-radius: 50%;
		box-shadow: 0 2px 20px rgba(0, 0, 0, .08);
		padding: 0;

		.icon {
			top: 4px;
			left: 4px;
			font-size: 12px;

			&-chevron-top {
				transform: scaleY(-1);
				will-change: transform;
				line-height: 22px;
				transition: transform .2s;
			}
		}
	}

	&._shadow {
		box-shadow: 0 1px 4px rgba(0, 0, 0, .14);

		@include hover() {
			box-shadow: none;
			border-color: map_get($colors, lgray);
			color: map_get($colors, black);
		}
	}

	&._block {
		display: flex;
		width: 100%;
	}

	&._lg {
		min-height: 56px;
		font-weight: 500;
	}

	&._md {
		padding: 8px 24px;
	}

	&._sm {
		min-height: 30px;
		font-size: 14px;
	}

	&._disabled,
	&[disabled] {
		pointer-events: none;
		box-shadow: none;
		opacity: 1 !important;
		background: map_get($colors, mgray);
		color: map_get($colors, lgray);
	}
}

.link {
	&-abs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&-invert {
		color: map_get($colors, blue);

		&:visited {
			color: map_get($colors, blue);
		}

		@include hover() {
			color: map_get($colors, black);
		}
	}

	&-blue {
		color: map_get($colors, blue);

		&:visited {
			color: map_get($colors, blue);
		}

		@include hover() {
			color: map_get($colors, dblue);
		}
	}

	&-underline {
		text-decoration: underline;

		@include hover() {
			text-decoration: underline;
		}
	}

	&-hover-underline {
		text-decoration: none;

		@include hover() {
			text-decoration: underline;
		}
	}

	&-hover-blue {
		@include hover() {
			color: map_get($colors, dblue) !important;
		}
	}

	&-blue-background {
		background-color: map-get($colors, pblue);
		color: map-get($colors, mail) !important;
		padding: 4px 16px;
		border-radius: 4px;
		transition-property: background-color, color;
		transition-duration: .15s;

		&:hover {
			background-color: map-get($colors, dblue);
			color: map-get($colors, white) !important;
		}
	}
}

.img {
	&-fluid {
		display: block;
		width: 100%;
	}

	&-cover {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		flex-shrink: 0;
	}

	&-center {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		&._logo-room,
		&._logo-room-lg,
		&._logo-room-xl {
			width: 40px;
			height: 40px;
			padding: 4px;
			background: map_get($colors, white);
			border-radius: 4px;
			border: 1px solid map_get($colors, ulgray);
		}

		&._logo-room-lg {
			width: 48px;
			height: 48px;
			padding: 5px;
		}

		&._logo-room-xl {
			width: 56px;
			height: 56px;
			padding: 5px;
		}

		img,
		svg {
			display: block;
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}
}

.text {
	font-size: 15px;
	line-height: 20px;

	&._lg {
		font-size: 16px;
		line-height: 22px;
	}

	&._sm {
		font-size: 14px;
	}

	&._xsm {
		font-size: 13px;
		line-height: 18px;
	}
}

.icon {
	$sizes: 18, 20, 24, 28, 32, 40, 48, 80;

	@each $size in $sizes {
		&._#{$size} {
			width: #{$size}px;
			height: #{$size}px;
			line-height: #{$size}px;
		}
	}
}

.vs-line {
	width: 1px;
	height: 24px;
	flex-shrink: 0;
	margin: 0 12px;
	background: map_get($colors, ulgray);
	pointer-events: none;
}

.ps {
	&__rail-y {
		width: 8px;
		background: #f9f9f9 !important;
		opacity: 1 !important;
	}

	&__rail-x {
		height: 8px;
		background: #f9f9f9 !important;
		opacity: 1 !important;
	}

	&__thumb-y {
		background: map_get($colors, mgray) !important;
		width: 4px !important;
		right: 2px;
	}

	&__thumb-x {
		background: map_get($colors, mgray) !important;
		height: 4px !important;
		bottom: 2px;
	}
}

.avatar {
	$sizes:
		32 16px,
		40 18px,
		48 20px,
		72 34px,
		88 38px;
	$size: 48px;
	width: $size;
	height: $size;
	line-height: $size - 1px;
	border-radius: 50%;
	position: relative;
	flex-shrink: 0;
	background-color: map_get($colors, white);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	color: map_get($colors, ulgray);
	font-size: 22px;

	&:before {
		@include icomoonInit();
		content: $icon-avatar;
		display: none;
	}

	&:not([style]) {
		box-shadow: 0 0 0 1px map_get($colors, ulgray) inset;

		&:before {
			display: block;
		}
	}

	&._letter {
		box-shadow: none;

		&:before {
			display: none;
		}
	}

	@each $size,
	$font_size in $sizes {
		&._#{$size} {
			width: #{$size}px;
			height: #{$size}px;
			line-height: #{$size - 1}px;
			font-size: $font_size;
		}
	}
}

.separator {
	position: relative;
	pointer-events: none;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 2px;
		height: 2px;
		width: calc(50% - 39px);
		background: linear-gradient(90deg, currentColor 0%, rgba(0, 0, 0, 0) 100%);
		opacity: .3;
	}

	&:before {
		left: 0;
		transform: scaleX(-1);
	}

	&:after {
		right: 0;
	}

	div {
		position: relative;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: currentColor;
		margin: 0 auto;

		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: currentColor;
			top: 0;
		}

		&:before {
			left: -12px;
		}

		&:after {
			left: 12px;
		}
	}
}

.swiper {
	&-pagination {
		position: static !important;
		padding: 0;
		line-height: 0;

		&-bullet {
			margin: 0 3px !important;
			opacity: 1;
			transition: background .2s;
			
			background: map_get($colors, mgray);
			@include hover() {
				background: map_get($colors, black);
			}
			&-active {
				background: map_get($colors, blue);
				
				@include hover() {
					background: map_get($colors, blue);
				}
			}
			
		}
		&._green {
			.swiper-pagination {
			&-bullet {
				&-active {
				background: #08A23B;
					
				@include hover() {
					background: #08A23B;
				}
			}
		}
		}
		}
	}

	&-button {
		&-prev,
		&-next {
			position: static !important;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			margin: 0 1px 1px;
			width: auto;
			height: auto;
			color: map_get($colors, black);

			&:before,
			&:after {
				display: none;
			}

			.icon {
				transition: color .2s;

				&:before {
					display: block;
					transform: translateX(2px);
				}
			}

			@include hover() {
				.icon {
					color: map_get($colors, black) !important;
				}
			}
		}

		&-prev {
			.icon {
				transform: scaleX(-1);
			}
		}
	}
}

.tooltip {
	pointer-events: none;
	box-shadow: 0 2px 20px rgba(0, 0, 0, .08);
	background: map_get($colors, white);
	border-radius: 4px;
	padding: 12px;
	font-family: $font_roboto;
	line-height: 18px;
	color: map_get($colors, black);
	left: 50%;
	bottom: 100%;
	transform: translateX(-50%);
	width: 0;
	transition: opacity .2s, margin .2s, width 0s .2s, font-size 0s .2s;
	margin-bottom: 15px;
	overflow: hidden;
	font-size: 0;

	&:before,
	&:after {
		content: '';
		position: absolute;
		pointer-events: none;
		right: 50%;
		background: map_get($colors, white);
	}

	&:before {
		width: 10px;
		height: 10px;
		border-radius: 2px;
		box-shadow: 0 2px 20px rgba(0, 0, 0, .08);
		bottom: -4px;
		margin-right: -5px;
		transform: rotate(45deg);
	}

	&:after {
		width: 20px;
		height: 10px;
		bottom: 0;
		margin-right: -10px;
	}

	&-trigger {
		position: relative;
		transition: color .2s;
		cursor: pointer;

		&__body {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				pointer-events: none;
				border-bottom: 2px dashed currentColor;
				opacity: .24;
				bottom: -2px;
				left: 0;
				width: 100%;
			}
		}

		@include hover() {
			color: map_get($colors, black) !important;
		}

		&:hover {
			.tooltip {
				opacity: 1;
				width: 230px;
				margin-bottom: 5px;
				transition: opacity .2s, margin .2s, width 0s 0s, font-size 0s 0s;
				overflow: visible;
				font-size: 13px;
			}
		}
	}
}

.notification {
	box-shadow:
		0 2px 20px rgba(0, 0, 0, .08),
		2px 0 0 0 currentColor inset;
	cursor: pointer;
	width: 320px;

	&__wrap {
		position: fixed;
		right: 0;
		top: $header_height;
		padding-top: 20px;
		padding-right: 20px;
		z-index: $notification_zindex;
		overflow: auto;
		max-height: calc(100vh - #{$header_height});
	}

	.icon {
		$size: 32px;
		width: $size;
		height: $size;
		line-height: $size;
		margin-right: 16px;
		font-size: 21px;
	}
}

.category-badge {
	top: 16px;
	right: 16px;
}

.rating {
	.icon {
		transition: color .2s;

		&:first-child {
			@include hover() {
				color: map_get($colors, red) !important;
			}
		}

		&:last-child {
			@include hover() {
				color: map_get($colors, green) !important;
			}
		}
	}
}

.add-bookmark {
	.icon {
		position: relative;

		&:after {
			@include icomoonInit();
			content: $icon-bookmark-solid;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: opacity .2s;
		}
	}

	&._active {
		.icon:after {
			opacity: 1;
		}
	}

	@include hover() {
		.icon:after {
			opacity: 1;
		}
	}
}

.fade-in {
	animation: fadeIn .5s backwards;
}

.gradient-animation {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 300%;
		height: 100%;
		background: linear-gradient(90deg, #F2F2F2 0%, #F9F9F9 33.33%, #F2F2F2 66.66%, #F9F9F9 100%);
		animation: translateN66 2s infinite;
	}
}

.circle-progress {
	width: 18px;
	height: 18px;
	position: absolute;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 18px;
		height: 18px;
		border: 3px solid map_get($colors, ulgray);
		z-index: 1;
		border-radius: 50%;
	}

	div {
		width: 18px;
		height: 18px;
		position: absolute;
		clip: rect(0px, 18px, 18px, 9px);
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		animation-timing-function: linear;
		animation-duration: .01s;
		animation-delay: 1s;
		animation-name: closeWrapper;
		pointer-events: none;
		flex-shrink: 0;
		z-index: 2;

		&:before,
		&:after {
			content: '';
			top: 0;
			left: 0;
			position: absolute;
			width: 18px;
			height: 18px;
			border: 3px solid map_get($colors, green);
			border-radius: 50px;
			clip: rect(0px, 9px, 18px, 0px);
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			animation-timing-function: linear;
		}

		&:before {
			animation-duration: 2s;
			animation-name: leftSpin;
		}

		&:after {
			animation-duration: 1s;
			animation-name: rightSpin;
		}
	}
}

.slide {
	&-trigger {
		.icon {
			&:before {
				display: block;
				transition: transform .2s;
				transform: scaleY(-1);
			}
		}

		&[data-status='show'] {
			.icon:before {
				transform: scaleY(1);
			}
		}

		&__body {
			&[data-status='show'] {
				overflow: visible !important;
			}

			&[data-status='hide'] {
				display: none !important;
			}
		}
	}
}

.list {
	margin: 0;
	padding: 0;
	font-size: 15px;
	line-height: 20px;
	list-style: none;

	li {
		position: relative;
		padding-left: 40px;
		min-height: 32px;

		&+li {
			margin-top: 16px;
		}

		&:before {
			@include icomoonInit();
			position: absolute;
			width: 32px;
			font-size: 21px;
			top: 0;
			left: 0;
			pointer-events: none;
		}
	}

	&._bullet {
		li {
			&:before {
				content: "";
				width: 4px;
				height: 4px;
				border-radius: 50%;
				top: 9px;
				left: 14px;
				background: currentColor;
			}
		}
	}

	&._check {
		li {
			&:before {
				content: $icon-check-circle;
				color: map-get($colors, green);
			}
		}
	}

	&._blocked {
		li {
			&:before {
				content: $icon-blocked;
				color: map-get($colors, red);
			}
		}
	}

	&._star {
		li {
			&:before {
				content: $icon-star;
				font-size: 24px;
				color: map-get($colors, yellow);
			}
		}
	}

	&._ordered {
		counter-reset: list 0;

		li {
			padding-left: 42px;
			padding-top: 5px;

			&:before {
				content: counter(list);
				counter-increment: list;
				color: map-get($colors, black);
				font-family: $font_roboto !important;
				font-weight: 500;
				font-size: 16px;
				line-height: 22px;
				padding-top: 6px;
				height: 32px;
				border-radius: 50%;
				box-shadow: 0 0 0 1px map-get($colors, black);
			}
		}
	}
}

.table {
	thead th {
		border: 0;
		vertical-align: middle;
		padding: 20px 16px;
	}

	tbody td {
		vertical-align: middle;
		border: 0;
		border-bottom: 1px solid map_get($colors, ulgray);
		padding: 20px 16px;
	}

	&-bordered {
		border-collapse: separate;
		border-spacing: 1px;
		border: 0;

		th,
		td {
			border: 0;
		}
	}

	&-rounded {
		tbody {
			tr {
				&:first-child {
					td {
						&:first-child {
							border-top-left-radius: 4px;
						}

						&:last-child {
							border-top-right-radius: 4px;
						}
					}
				}

				&:last-child {
					td {
						&:first-child {
							border-bottom-left-radius: 4px;
						}

						&:last-child {
							border-bottom-right-radius: 4px;
						}
					}
				}
			}
		}

		thead {
			tr {
				&:first-child {
					th {
						&:first-child {
							border-top-left-radius: 4px;
						}

						&:last-child {
							border-top-right-radius: 4px;
						}
					}
				}
			}

			&+tbody {
				tr {
					&:first-child {
						td {
							&:first-child {
								border-top-left-radius: 0;
							}

							&:last-child {
								border-top-right-radius: 0;
							}
						}
					}
				}
			}
		}
	}
}


.social {
	&-services {
		display: flex;
		align-items: flex-start;
		margin: 0 -8px;
		flex-shrink: 0;

		&__icon {
			$services:
				facebook,
				vk,
				telegram,
				ok,
				youtube,
				insta,
				skype,
				twitter,
				whatsapp,
				mail,
				chat,
				www;
			$size: 28px;
			flex-shrink: 0;
			position: relative;
			margin: 0 8px;
			border-radius: 50%;
			width: $size;
			height: $size;
			line-height: $size;
			color: #fff;
			font-size: 17px;
			transition: background .2s;

			@each $service in $services {
				&.icon-#{$service} {
					background: map_get($colors, $service);

					@include hover() {
						background: mix(map_get($colors, $service), map_get($colors, black), 90%);
					}
				}
			}

			&.icon-telegram {
				&:before {
					display: block;
					transform: translateX(-1px);
				}
			}

			&.icon-www {
				font-size: 140%;
			}

			.abs-link {
				border-radius: 50%;
			}
		}

		&__item {
			display: flex;
			align-items: center;
		}

		&__text {
			font-size: 14px;
		}

		&._list {
			display: block;
			margin: 0;

			.social-services__item {
				&+.social-services__item {
					margin-top: 16px;
				}
			}

			.social-services__icon {
				$size: 24px;
				width: $size;
				height: $size;
				line-height: $size;
				font-size: 95%;
				margin: 0 8px 0 0;
			}
		}

		&._square {
			.social-services__icon {
				border-radius: 4px;
			}
		}

		&._40 {
			.social-services__icon {
				$size: 40px;
				width: $size;
				height: $size;
				line-height: $size;
				font-size: 140%;

				&.icon-www {
					font-size: 170%;
				}
			}
		}

		&._32 {
			.social-services__icon {
				$size: 32px;
				width: $size;
				height: $size;
				line-height: $size;
				font-size: 130%;

				&.icon-www {
					font-size: 160%;
				}
			}
		}

		&._p4 {
			margin: 0 -4px;

			.social-services__icon {
				margin: 0 4px;
			}
		}

		&-slider {
			.social-services {
				min-width: 108px;

				&[data-status='hide'] {
					width: 0;
				}
			}

			.js-slide {
				&:before {
					display: block;
					transform: translateX(1px);
					transition: all .2s;
				}

				&[data-status='show'] {
					&:before {
						transform: translateX(-1px) scaleX(-1);
					}
				}
			}
		}
	}
}

.form {
	&-control {
		$placeholder_color: map_get($colors, lgray);
		color: map_get($colors, black);
		height: 40px;
		background-color: map_get($colors, white);
		border: 1px solid map_get($colors, ulgray);
		padding: 0 12px;
		font-size: 14px;
		border-radius: 4px;
		transition: color .2s, border-color .2s, box-shadow .2s, background .2s;
		font-family: $font_roboto;
		box-shadow: 0 1px 4px rgba(0, 0, 0, .14) inset;

		&::-webkit-input-placeholder {
			color: $placeholder_color;
		}

		&::-moz-placeholder {
			color: $placeholder_color;
		}

		&:-moz-placeholder {
			color: $placeholder_color;
		}

		&:-ms-input-placeholder {
			color: $placeholder_color;
		}

		&:focus {
			color: map_get($colors, black);
			background-color: map_get($colors, white);
			border-color: map_get($colors, ulgray);
			box-shadow: 0 1px 4px rgba(0, 0, 0, .14) inset;
		}

		&:not(:placeholder-shown):not(:focus) {
			background-color: map_get($colors, mulgray);
		}

		&._no-shadow {
			box-shadow: none;
		}

		&._error {
			border-color: map_get($colors, red);
			box-shadow: none;
		}

		&._disabled,
		&[disabled] {
			background-color: map_get($colors, mulgray);
			color: map_get($colors, gray);
			box-shadow: none;
		}
	}

	&-input {
		position: relative;
		min-height: 62px;
		margin-bottom: 5px;

		&__label {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			margin-bottom: 8px;
		}

		&__error {
			display: none;
			padding-top: 42px;
			z-index: 2;
		}

		&__link {
			position: absolute;
			top: 10px;
			right: 12px;
			z-index: 3;

			&+.form-control {
				padding-right: 90px;
			}
		}

		&__list-item {
			transition: color .2s;
			margin-top: 12px;
			cursor: pointer;

			&:first-child {
				margin-top: 0;
			}

			@include hover() {
				color: map_get($colors, blue);
			}
		}

		.form-control {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;

			&._error {
				&~.form-input__error {
					display: block;
				}
			}
		}

		textarea.form-control {
			height: 118px !important;
			padding: 16px;
		}

		&_textarea {
			min-height: 140px;

			.form-input__error {
				padding-top: 120px;
			}
		}

		&._lg {
			min-height: 78px;

			.form-control {
				height: 56px;
				padding: 0 16px;
				font-size: 15px;
			}

			.form-input__error {
				padding-top: 58px;
			}

			.form-input__link {
				top: 18px;
				right: 16px;

				&+.form-control {
					padding-right: 100px;
				}
			}
		}

		&._dropdown {
			.icon-chevron-top {
				$size: 20px;
				width: $size;
				height: $size;
				line-height: $size - 2px;
				top: 10px;
				right: 10px;
				font-size: 10px;
				transform: scaleY(-1);
				will-change: transform;
				transition: transform .2s;
				position: absolute;
				pointer-events: none;
				z-index: 3;
			}

			.img-center {
				width: 24px;
				height: 24px;
				padding: 2px;
				margin-right: 10px;
			}

			.form-control {
				padding-right: 46px;
				background-color: map_get($colors, white);
				cursor: pointer;
				display: flex;
				align-items: center;

				&._disabled {
					background-color: map_get($colors, mulgray);
					cursor: default;
					pointer-events: none;

					&~.icon.icon-chevron-top {
						display: none;
					}
				}
			}

			.popover {
				box-shadow: 0 2px 20px rgba(0, 0, 0, .08);
				top: 48px;
				max-width: none;
				width: 100%;
				padding: 12px;
				font-size: 14px;
				max-height: 496px;
			}

			&._lg {
				.icon-chevron-top {
					$size: 32px;
					width: $size;
					height: $size;
					line-height: $size - 2px;
					top: 12px;
					right: 12px;
					font-size: 16px;
				}

				.img-center {
					width: 32px;
					height: 32px;
					padding: 3px;
					margin-right: 8px;
				}

				.form-control {
					padding-right: 56px;
				}

				.popover {
					top: 64px;
					padding-left: 16px;
					padding-right: 16px;
					font-size: 15px;
				}
			}

			&._open-popover {
				.icon-chevron-top {
					transform: scaleY(1);
				}
			}
		}
	}

	&-checkbox {
		input {
			top: 0;
			left: 0;
			z-index: -1;
			opacity: 0;
			height: 1px;
			width: 1px;

			&:checked~.form-checkbox__check {
				.icon {
					opacity: 1;
				}
			}
		}

		.icon {
			transition: opacity .2s;
			opacity: 0;
		}

		.text {
			transition: color .2s;
		}

		@include hover() {
			.text {
				color: map_get($colors, dblue);
			}
		}
	}
}

.rating {
	&-counter {
		display: flex;
		align-items: center;

		.icon {
			$size: 28px;
			width: $size;
			height: $size;
			line-height: $size;
			font-size: 12px;
			color: map_get($colors, lgray);
			cursor: pointer;
			box-shadow: 0 0 0 1px map_get($colors, ulgray) inset;
			transition: color .2s;

			&-minus {
				@include hover() {
					color: map_get($colors, red);
				}
			}

			&-plus {
				@include hover() {
					color: map_get($colors, green);
				}
			}
		}

		.text {
			margin: 0 8px;
			flex-shrink: 0;
			color: map_get($colors, gray);
		}

		&._disabled {
			.icon {
				pointer-events: none;
				opacity: 0;
			}
		}
	}
}

.tip {
	&__btn {
		margin-left: 84px;
	}
}

.tabs {
	background: map_get($colors, white);
	display: flex;
	position: relative;
	align-items: center;
}

.tab {
	position: relative;
	min-height: 60px;
	flex: 1 1 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
	cursor: pointer;
	margin: 0;
	overflow: hidden;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 20px;
	color: map_get($colors, lgray);
	letter-spacing: 1.5px;

	@include hover() {
		.tab__text {
			color: map_get($colors, black) !important;
		}
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		opacity: 0;

		&:checked {
			&~.tab__text {
				color: map_get($colors, black) !important;

				&:before {
					transform: translateY(0);
				}
			}
		}
	}

	&__text {
		transition: color .2s;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 4px;
			background: map_get($colors, blue);
			pointer-events: none;
			transform: translateY(-100%);
			transition: transform .2s;
		}
	}

	&._sm {
		min-height: 40px;
		font-size: 12px;
		padding: 5px 16px;

		.tab__text {
			&:before {
				display: none;
			}
		}
	}

	&__content {
		&:not(._active) {
			display: none !important;
		}
	}

	&-rubrics {
		position: relative;

		&._open-popover {
			.tab-rubrics__btn {
				&._shadow {
					box-shadow: none;
					border-color: map_get($colors, lgray);
					color: map_get($colors, black);
				}

				&._blue {
					color: map_get($colors, white);
					background: map_get($colors, dblue);
				}
			}
		}

		&__popover {
			top: 49px !important;
		}
	}
}

.notifications {
	&__item {
		border-top: 1px solid map_get($colors, ulgray);
		padding: 12px 16px;
		display: flex;

		&:first-child {
			border-top: 0;
		}

		.avatar {
			.icon {
				$size: 16px;
				width: $size;
				height: $size;
				line-height: $size;
				position: absolute;
				top: 50%;
				left: -8px;
				pointer-events: none;
				margin-top: -8px;
				font-size: 8px;
				font-weight: 700;
			}
		}
	}
}

.search-result {
	&__item {
		padding: 20px 16px;
		border-top: 1px solid map_get($colors, ulgray);

		&:first-child {
			border-top: 0;
		}

		a.link-underline {
			color: map_get($colors, blue);

			@include hover() {
				color: map_get($colors, black);
			}
		}
	}
}

.reviews {
	&__item {
		padding: 24px;
		border-radius: 4px;
		border: 1px solid map_get($colors, ulgray);

		&+.reviews__item {
			margin-top: 10px;
		}
	}
}

.table-cards {
	&__head {
		border: 1px solid map_get($colors, pblue);

		[class*='table-cards__w'] {
			border-right: 1px solid map_get($colors, pblue);
		}
	}

	&__w96 {
		width: 96px;
	}

	&__w120 {
		width: 120px;
	}

	&__w186 {
		width: 186px;
	}

	&__w222 {
		width: 222px;
	}

	&__w230 {
		width: 230px;
	}

	&__w240 {
		width: 240px;
	}

	&__w278 {
		width: 278px;
	}

	&__w330 {
		width: 330px;
	}

	&__w340 {
		width: 340px;
	}

	&__collapsed {
		&[data-status='hide'] {
			display: none !important;
		}

		&[data-status='show'] {
			overflow: visible !important;
		}
	}

	&__slide-trigger {
		.icon {
			transition: all .2s;
		}

		&[data-status='hide'] {
			.text-status-hide {
				display: block !important;
			}

			.icon {
				transform: scaleY(-1);
			}
		}

		&[data-status='show'] {
			background: map_get($colors, pblue);

			@include hover() {
				background: mix(map_get($colors, pblue), map_get($colors, blue), 95%);
			}

			.text-status-show {
				display: block !important;
			}

			.icon {
				color: map_get($colors, blue);
			}
		}
	}

	&__item {
		&:not([data-state='ready']) {
			[data-state='ready'] {
				display: none !important;
			}
		}

		&:not([data-state='pending']) {
			[data-state='pending'] {
				display: none !important;
			}
		}

		&[data-state='ready'] {
			[data-state='initial'] {
				display: none !important;
			}
		}

		&[data-state='pending'] {
			[data-state='initial'] {
				display: none !important;
			}

			[data-state='ready'] {
				display: none !important;
			}
		}

		.gradient-animation {
			top: 8px;
			left: 8px;
			right: 8px;
			bottom: 8px;
		}
	}

	[class*='table-cards__w'] {
		flex-shrink: 0;
	}
}

.scroll-content {
	&__title {
		top: 0;
		left: 0;
		z-index: 5;
	}
}

.comments {
	.tab {
		flex: auto;
		width: 120px;
		flex-shrink: 0;
		padding: 0;
	}
}

.comment {
	transition: background .2s;

	&__action {
		transition: color .2s;

		>* {
			transition: color .2s;
		}

		@include hover() {
			color: map_get($colors, black) !important;

			>* {
				color: map_get($colors, black) !important;
			}
		}
	}

	&__img {
		max-width: 426px;

		.icon {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&-indent {
		border-left: 1px dashed map_get($colors, mgray);
	}

	&-input {
		&__bg {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: map_get($colors, white);
			border: 1px solid map_get($colors, ulgray);
			transition: color .2s, border-color .2s, box-shadow .2s, background .2s;
			box-shadow: 0 1px 4px rgba(0, 0, 0, .14) inset;
			z-index: 1;
		}

		.form-control {
			box-shadow: none !important;
			background: transparent !important;
			min-height: 164px;
			padding: 16px;
			font-size: 16px;
			z-index: 3;
			border-color: transparent;
			overflow: auto;

			&:before {
				content: 'Написать комментарий...';
				display: inline;
				color: map_get($colors, lgray);
				pointer-events: none;
			}

			&:focus,
			&:not(:empty) {
				&:before {
					display: none;
				}
			}

			&:not(:empty):not(:focus) {
				&~.comment-input__bg {
					background-color: map_get($colors, mulgray);
				}
			}

			&._error {
				&~.comment-input__bg {
					border-color: map_get($colors, red);
					box-shadow: none;
				}
			}

			&._disabled,
			&[disabled] {
				&~.comment-input__bg {
					background-color: map_get($colors, mulgray);
					color: map_get($colors, gray);
					box-shadow: none;
				}
			}
		}

		&-media {
			z-index: 2;
			max-width: 810px;
			overflow: auto;
			margin-left: 1px;

			&:after {
				content: '';
				display: block;
				width: 28px;
				flex-shrink: 0;
				height: 1px;
				pointer-events: none;
			}

			&~.form-control {
				min-height: 100px;
			}

			&__item {
				background-color: map_get($colors, white);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;

				&+.comment-input-media__item {
					margin-left: 28px;
				}
			}

			&__icon {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&__remove {
				z-index: 5;
				top: -12px;
				right: -12px;
			}
		}

		&__replied {
			z-index: 2;
		}

		.ps__rail-x {
			background: transparent !important;
		}
	}

	&-controls {
		.icon {
			transition: color .2s;

			@include hover() {
				color: map_get($colors, black) !important;
			}

			input {
				top: 0;
				left: 0;
				width: .1px;
				height: .1px;
				opacity: 0;
				overflow: hidden;
				position: absolute;
				z-index: -1;
			}
		}

		.btn {
			min-width: 0;
		}
	}
}

.faq {
	transition: background .2s;

	&[data-status='show'] {
		background: map_get($colors, mulgray) !important;

		.faq__title {
			font-weight: 500;
		}

		.faq__icon {
			&:before {
				transform: rotate(0deg);
			}

			&:after {
				opacity: 0;
				transform: rotate(90deg);
			}
		}
	}

	&[data-status='hide'] {}

	&__icon {
		top: 22px;
		right: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 12px;
			height: 2px;
			background: map_get($colors, lgray);
			top: 50%;
			left: 50%;
			margin: -1px 0 0 -6px;
			transition: all .2s;
			transform: rotate(90deg);
		}

		&:after {
			transform: rotate(180deg);
		}
	}

	&__title {
		min-height: 40px;


		@include hover() {
			&+.faq__icon {

				&:before,
				&:after {
					background: map_get($colors, black);
				}
			}
		}
	}

	&__body {
		&[data-status='show'] {
			overflow: visible !important;
		}

		&[data-status='hide'] {
			display: none !important;
		}
	}
}

.hover-img {
	position: relative;
	z-index: 3;
	transition-property: box-shadow, background-color;
	transition-duration: 0.2s;
}

.hover-img:hover {
	box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
	background-color: #fafafa !important;
}

@media ($media_min_phone) {
	.table {
		thead th {
			&:first-child {
				border-radius: 4px 0 0 4px;
			}

			&:last-child {
				border-radius: 0 4px 4px 0;
			}
		}
	}

	.table-cards {
		&__container {
			transition: all .2s;

			&[data-status='show'] {
				background-color: map_get($colors, mulgray) !important;
				box-shadow: 0 0 0 1px map_get($colors, ulgray) !important;
			}
		}

		&__item {
			min-height: 80px;

			&[class*='c-bg-'] {
				[class*='table-cards__w'] {
					background: none !important;
				}
			}
		}
	}

	.scroll-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&-static {
			max-height: 620px;
			position: relative;
		}
	}

	.comments {
		.tabs {
			margin-right: -24px;
		}
	}

	.comment {
		.rating {
			position: absolute;
			right: 0;
			top: 2px;
		}

		&._answered {
			background: map_get($colors, mulgray);
			padding: 12px 8px 8px;
			margin: -12px -8px 16px -8px !important;

			.rating {
				top: 14px;
				right: 8px;
			}
		}

		&-controls {
			.btn {
				&._blue {
					min-width: 220px !important;
				}

				&._shadow {
					min-width: 120px !important;
				}
			}
		}
	}
}

@media ($media_max_phone) {
	.btn {
		&._sm {
			min-height: 40px;
		}
	}

	.vs-line {
		height: 24px;
	}

	.separator {

		&:before,
		&:after {
			width: calc(50% - 23px);
		}
	}

	.tooltip {
		line-height: 20px;

		&:before,
		&:after {
			display: none;
		}

		&-trigger {
			&:hover {
				.tooltip {
					width: 100%;
				}
			}
		}
	}

	.notification {
		&__wrap {
			top: $header_height_phone;
			z-index: 1050;
		}

		.icon {
			$size: 24px;
			width: $size;
			height: $size;
			line-height: $size;
			margin-right: 8px;
			font-size: 16px;
		}
	}

	.table {
		thead th {
			padding: 10px 16px;
		}

		tbody td {
			padding: 10px 16px;
		}
	}

	.swiper {
		&-container {
			.tooltip {
				transform: translateX(-75%);

				&-trigger:hover {
					.tooltip {
						width: 200%;
					}
				}
			}
		}

		&-steps-phone {
			.swiper-pagination {
				margin-top: 20px;
				height: 6px;

				&-bullet {
					width: 6px;
					height: 6px;
					background: map_get($colors, ulgray);

					&-active {
						background: map_get($colors, blue);
					}
				}
			}
		}
	}


	.social {
		&-services {
			&-slider {
				width: 100%;
			}
		}
	}

	.form {
		&-control {
			font-size: 16px;
		}

		&-input {
			min-height: 40px;
			margin-bottom: 20px;

			&_textarea {
				min-height: 118px;
			}

			&._dropdown {
				.form-control {
					font-size: 14px;
				}

				.popover {
					overflow: auto;
					max-height: 276px;
				}

				&._lg {
					margin-bottom: 5px;

					.form-control {
						font-size: 15px;
					}
				}
			}
		}
	}

	.tip {
		&__btn {
			margin-left: 0;
		}
	}

	.tabs {
		&._phone-list {
			display: block;
		}
	}

	.tab {
		._phone-list & {
			min-height: 0;
			padding: 10px 16px;

			&:first-child {
				padding-top: 20px;
			}

			&:last-child {
				padding-bottom: 20px;
			}

			.tab__text {
				&:before {
					display: none;
				}
			}

			&._sm {
				padding: 5px 16px;

				&:first-child {
					padding-top: 10px;
				}

				&:last-child {
					padding-bottom: 10px;
				}
			}
		}
	}

	.notifications {
		&__item {
			padding: 16px 20px;

			&:first-child {
				padding-top: 0;
			}
		}
	}

	.search-result {
		&__item {
			padding: 16px 20px;
			font-size: 15px !important;
			line-height: 20px;
		}
	}

	.reviews {
		&__item {
			padding: 20px;
		}
	}

	.table-cards {
		background: none !important;

		&__container,
		&__item {
			box-shadow: none !important;
			border-radius: 0 !important;
		}

		&__collapsed {
			.table-cards__item {
				background-color: transparent !important;
			}
		}

		&__item {
			.gradient-animation {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

		[class*='table-cards__w'] {
			width: 100%;
			background: none !important;
			position: relative;
		}

		.tooltip {
			&-trigger {
				position: static;
			}
		}
	}

	.scroll-content,
	.scroll-content-static {
		position: relative;
		overflow: auto;
		max-height: 450px;
	}

	.comments {
		.tab {
			padding: 0;
			width: auto;
			flex-shrink: 0;

			&:first-child {
				margin-right: 12px;
			}
		}
	}

	.comment {
		&-input {
			&-media {
				&~.form-control {
					min-height: 180px;
				}
			}
		}
	}
}

.content-layout-small {
	max-width: 700px;
	margin: 0 auto;
}

.btn-4-16 {
	padding: 4px 16px;
	user-select: none;
	transition-property: background-color, white;
	transition-duration: .15s;

	&:hover {
		background-color: map-get($colors, dblue) !important;
		color: map-get($colors, white) !important;
	}
}

.bpr-card {
	box-shadow: 0px 1px 4px 0px #00000024;
	position: relative;

	&-rating {
		position: absolute;
		top: 16px;
		right: 16px;
		;
	}

	&-country {
		padding-left: 4px;

		& div.width-min-content {
			width: min-content;
		}

		& svg {
			width: 18px;
			height: 18px;
		}
	}

	&-promo {
		align-self: flex-end;
		cursor: pointer
	}

	&-container {
		padding-bottom: 16px;
		;

		& .bpr-card {
			&:not(:last-child) {
				margin-bottom: 16px
			}
		}
	}
}

.banner {
	overflow: hidden;
	transition-property: outline;
	transition-duration: .15s;
	position: relative;
	outline: 3px solid transparent;
	cursor: pointer;

	&.w-380 {
		width: 380px;
		max-width: 380px;
		min-width: 380px;
		padding: 16px;
	}

	&.w-280 {
		width: 280px;
		max-width: 280px;
		min-width: 280px;
		padding: 12px;
	}

	&-icon {
		&-container {
			&.w-48 {
				padding: 8px;

				& .banner-icon {
					width: 48px;
					height: 48px;
				}
			}

			&.w-42 {
				padding: 7px;
				margin-right: 12px;

				& .banner-icon {
					width: 42px;
					height: 42px;
				}
			}
		}
	}

	&.c-bg-pgreen:hover {
		outline: 3px solid #15873B;
	}

	&.c-bg-white:hover {
		outline: 3px solid #AAAAAA;
	}
}

@media ($media_max_phone) {
	.banner {
		overflow: hidden;
		transition-property: background-color;
		transition-duration: .15s;
		position: relative;

		&-container {
			& .banner:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&.w-380 {
			width: 100%;
			max-width: initial;
			min-width: initial;
			padding: 16px;
		}

		&.w-280 {
			width: 100%;
			max-width: initial;
			min-width: initial;
			padding: 12px;
		}
	}
}

.freerolls-filter-button {
	display: flex;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	background-color: #FFF;
	border: 1px solid #e9e9e9;
	padding: 10px 20px;
	cursor: pointer;
	transition: all .3s;
	color: #000000;

	&:hover {
		border: 1px solid #EBF8EF;
		background: #EBF8EF;
		color: #000000;
	}

	&._active {
		background: #EBF8EF;
		border: 1px solid #EBF8EF;
		color: #08A23B;
	}
}

.draggable-widgets {
	position: sticky;
	top: 70px;
	margin-bottom: 20px;
	z-index: 10;
}

@media ($media_max_phone) {
.html_content .table {
	th {
		opacity: .5;
		border-bottom: 1px solid #e9e9e9;
		padding: 16px 0!important;
	}
	td {
		padding: 16px 0!important;
		vertical-align: top;
	}
	td:last-child {
		max-width: 70px;
	}
	td:first-child {
		max-width: 210px;
		padding-right: 40px!important;
	}
	
	@media (max-width: 641px) {
	display: block;
	max-width: 100%;
	margin: 0 auto;
	overflow-x: auto;
	white-space: nowrap;
	th, td {
		&:not(:first-child) {
			padding: 16px!important;
		}
	}
	}
	}
}

.spoiler-box-text {
	color: #346EB8;
	border-bottom: 2px solid rgba(52, 110, 184, 0.24);
	font-size: 14px;
	font-style: normal;
	line-height: 20px;
	transition: .1s;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
}
.spoiler-box-text:hover {
	color: #3564A0;
	border-bottom: 2px solid transparent;
}
.spoiler-box-text.active {
	color: #444444;
	border-bottom: 2px solid transparent;
}

.spoiler-box-inner {
	visibility: hidden;
	opacity: 0;
	height: 0;
	transition: visibility 0s linear 0s, opacity 300ms;
}
.spoiler-box-inner img {
	width: 100%;
	height: 100%;
}

.spoiler-box-inner.active {
	visibility: visible;
	opacity: 1;
	height: 100%;
	margin-top: 10px;
}