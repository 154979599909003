.poker-rooms{
    &__link{
        text-decoration: none;
        position: relative;
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            border-radius: 4px;
            position: absolute;
            right: 0;
            left: 0;
            bottom: -2px;
            background-color: rgba(52, 110, 184, 0.24);
            transition: background-color .2s;
            transition-property: opacity;
            transition-duration: .15s;
        }
        &:hover{
            color: map-get($colors, dblue)!important;
        }
        &:hover::after{
            opacity: 0;
        }
    }
    &__tabs{
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
        &-wrapper{
            width: 276px;
        }
        & input{
            display: none;
        }
        & input:checked + .poker-rooms__tab{
            background-color: map-get($colors, mulgray);
            box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
            border: none;
        }
    }

    &__deposit {
        padding-left: 4px;
    }
    &__card{
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
        position: relative;
        outline: 4px solid transparent;
        transition-property: outline;
        transition-duration: .2s;
        &:hover{
            outline: 4px solid #E7EEF6;
        }
        &-promo-block{
            position: absolute;
            top: -13px;
            left: 441px;
            padding: 4px 4px 4px 8px;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
            cursor: pointer;
            & p{
                margin-right: 4px!important;
            }
        }
        &-left-side{
            width: 50%;
            &-content{

            }
            &-icon{
                display: block;
                width: 100%;
                height: 100%;
                &-container{
                    width: 56px;
                    height: 56px;
                    padding: 6px;
                    border: 1px solid #E9E9E9;
                    position: relative;
                    transition-property: box-shadow, background-color;
                    transition-duration: .2s;
                    &:hover{
                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
                        background-color: map-get($colors, mulgray)!important;
                    }
                }
                &-badge{
                    display: block;
                    width: 16px;
                    height: 16px;
                    &-container{
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        width: 24px;
                        height: 24px;
                        border-radius: 100px;
                        background-color: #FAEFDE;
                        transition-property: background-color;
                        transition-duration: .1s;
                        &:hover{
                            background-color: #853232;
                            & .poker-rooms__card-left-side-icon-badge-tooltip{
                                opacity: 1;
                                visibility: visible;
                                transform: translateX(-50%) translateY(-8px);
                            }
                        }
                    }
                    &-tooltip{
                        padding: 4px 8px;
                        position: absolute;
                        top: -100%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-1px);
                        transition: opacity .2s, visibility .2s, transform .2s;
                        opacity: 0;
                        visibility: hidden;
                        white-space: nowrap;
                        z-index: 9;
                        & p{
                            opacity: 0.64;
                        }
                    }
                }
            }
            &-name-container{
                & a{
                    margin-bottom: 6px;
                    line-height: 22px;
                    // padding: 4px;
                }
            }
            &-rating{
                &-stars-container{
                    margin-right: 4px;
                }
            }
            &-deposit {
                text-wrap: nowrap;
            }
        }
        &-middle{
            height: 64px;
            width: 1px;
            border: 1px solid map-get($colors, ulgray);
        }
        &-right-side{
            width: 50%;
            padding-left: 25px;
            justify-content: space-between;
            &-list{
                & li:not(:last-child){
                    margin-bottom: 4px;
                }
                &-icon{
                    display: block;
                    width: 100%;
                    height: 100%;
                    &-container{
                        width: 20px;
                        height: 20px;
                        margin-right: 4px;
                    }
                }
            }
            &-buttons{
                max-width: 120px;
            }
            &-btn{
                &-review{
                    border: none;
                    padding: 10px 26px;
                    width: 100%;
                    flex: 1;
                    &-icon{
                        display: block;
                        width: 100%;
                        height: 100%;
                        fill: map-get($colors, chat);
                        &-container{
                            width: 20px;
                            height: 20px;
                            margin-right: 4px;
                        }
                    }
                    &:hover{
                        background-color: map-get($colors, dgreen)!important;
                        & .poker-rooms__card-right-side-btn-review-icon{
                            fill: map-get($colors, white);
                        }
                        & p{
                            color: map-get($colors, white)!important;
                        }
                    }
                }
                &-promo{
                    width: 100%;
                    padding: 4px 15px;
                    border: none;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
                    &-text{
                        & p{
                            margin-right: 4px!important;
                            line-height: 22px;
                        }
                    }
                    &-icon{
                        display: block;
                        width: 100%;
                        height: 100%;
                        &-container{
                            width: 18px;
                            height: 18px;
                        }
                    }
                    &-none{
                        line-height: 22px;
                    }
                }
                &-registration{
                    // display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 14px 15px;
                    border: none;
                    text-align: center;
                    flex: 1;
                    span {
                        opacity: 0.7;
                    }
                    &:hover{
                        background: #e94344!important;
                        color: #fff!important;
                    }
                    &_small{
                        padding: 10px 15px;
                    }
                }
            }
        }
        &-promo-hidden{
            display: none;
            box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
            padding: 4px 8px;
            & p{
                margin-right: 4px!important;
            }
        }
        &._widget {
            .poker-rooms__card-left-side-name-container {
                max-width: 110px;
                width: 110px;
                min-width: 110px;
            }
        }
    }
    &__tab{
        transition-property: background-color, box-shadow;
        transition-duration: .15s;
        cursor: pointer;
        user-select: none;
        &:hover:not(.poker-rooms__tabs input:checked + .poker-rooms__tab){
            background-color: #E7EEF6;
            color: map-get($colors, blue);
        }
        &-icon{
            display: block;
            width: 100%;
            height: 100%;
            &-container{
            width: 24px;
            height: 24px;
            }
        }
        &:not(:last-child){
            margin-bottom: 16px;
        }
        
    }
    &__name{
        padding: 4px;
        transition-property: color, background-color;
        transition-duration: .15s;
        &:hover{
            color: map-get($colors, blue);
            background-color: #E7EEF6;
        }
    }
}

@media($media_max_phone){
    .poker-rooms{
        &__heading{
            flex-direction: column;
            align-items: flex-start!important;    
            & h1{
                margin-bottom: 16px;
            }   
        }
        &__wrapper{
            flex-direction: column;
        }
        &__tabs{
            margin-bottom: 24px;
            &-wrapper{
                width: 100%;
                margin: 0!important;
            }
        }
        &__card{
            flex-direction: column;
            align-items: flex-start!important;
            &-left-side{
                align-items: flex-start!important;
                width: 100%;
                flex-direction: column;
                margin: 0 0 16px 0;
                & a{
                   &:hover{

                   } 
                }
                &-content{
                    margin: 0 0 16px 0!important;
                }
                &-icon-badge{
                    &-container{
                        &:hover .poker-rooms__card-left-side-icon-badge-tooltip{
                            transform: translateY(-8px);
                        }
                    }
                    &-tooltip{
                        left: 0;
                        transform: translateY(-1px);
                    }
                }
            }
            &-middle{
                height: 1px;
                width: 100%;
                margin-bottom: 16px;
            }
            &-right-side{
                width: 100%;
                flex-direction: column;
                padding: 0;
                align-items: flex-start!important;
                &-list{
                    margin: 0 0 16px 0!important;
                    & li:not(:last-child){
                        margin-bottom: 0;
                    }
                }
                &-buttons{
                    display: flex;
                    // align-items: center;
                    max-width: initial;
                    width: 100%;  
                }
                &-btn{
                    &-promo{
                        padding: 4px 8px;
                        // margin: 0px 8px 0px 0px!important;
                        width: initial;
                        &-none{
                            white-space: nowrap;
                        }
                    }
                    &-registration{

                    }
                    &-review{
                        // margin: 0px 8px 0px 0px!important;
                        width: initial;
                    }
                }
            }
            &-promo-block{
                display: none!important;
            }
            &-promo-hidden{
                display: flex;
                margin-bottom: 16px;
            }
        }
    };
}

.hidden{
    display: none!important;
}