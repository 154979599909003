@import "config";
@import "icon_names";

.post {
	&-card {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 248px;

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background: linear-gradient(0deg, map_get($colors, black) 0%, rgba(0, 0, 0, 0) 100%);
		}

		@include hover() {
			.post-card__bookmark {
				opacity: 1;

				& ~ .post-card__icon {
					transform: translateX(-48px);
				}
			}
		}

		&__link {
			z-index: 4;
		}

		&__bookmark {
			top: 16px;
			right: 16px;
			opacity: 0;
			transition: all .2s;
			border: 1px solid map_get($colors, yellow);
			background: rgba(235, 203, 36, 0.16);
			color: map_get($colors, yellow);
			cursor: pointer;
			z-index: 5;

			&:after {
				content: $icon-bookmark-solid;
				position: absolute;
				opacity: 0;
				transition: opacity .2s;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			&._active {
				opacity: 1;

				&:after {
					opacity: 1;
				}

				& ~ .post-card__icon {
					transform: translateX(-48px);
				}
			}

			@include hover() {
				border: 1px solid map_get($colors, white);
				background: rgba(255, 255, 255, 0.16);
				color: map_get($colors, white);
			}
		}

		&__icon {
			top: 16px;
			right: 16px;
			transition: transform .2s;
			z-index: 3;
		}

		&__body {
			z-index: 5;
		}

		&__category {
			z-index: 5;
		}

		a.badge {
			color: map_get($colors, white);
			transition: color .2s;
			background: rgba(235, 203, 36, 0.16);

			@include hover() {
				color: map_get($colors, yellow);
			}
		}
	}
}


@media ($media_min_phone) {
	.post {
		&-card {
			&._lg {
				min-height: 380px;
			}

			&._sm {
				min-height: 193px;
			}
		}

		&-row {
			.post-card {
				&:before,
				&__bookmark,
				&__icon,
				&__body,
				a.badge {
					display: none;
				}
			}
		}
	}
}

@media ($media_max_phone) {
	.post {
		&-card {
			min-height: 236px;

			&:before {
				background: linear-gradient(0deg, map_get($colors, black) 0%, rgba(0, 0, 0, 0.24) 100%);
			}

			&__bookmark {
				opacity: 1;

				& ~ .post-card__icon {
					transform: translateX(-48px);
				}
			}

			&__body {
				z-index: 2;
			}
		}
	}
}
