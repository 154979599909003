@import "config";

.footer {
	&__info {
		width: 406px;
	}

	&__logo {
		width: 218px;
	}

	&__text {
		max-width: 380px;
	}

	&__btn {
		min-height: 36px;
		color: map_get($colors, black);

		&:hover {
			color: map_get($colors, black);
		}

		@include hover() {
			color: map_get($colors, dblue);
		}
	}

	&__support {
		width: 220px;
	}

	&__rooms {
		width: 300px;
	}
}


@media ($media_max_phone) {
	.footer {
		&__info,
		&__support,
		&__rooms {
			width: auto;
		}

		&__logo {
			width: 152px;
		}
	}
}
