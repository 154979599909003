.box-shadow-0-1-4{
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
}

.poker-rooms{
    &__tabs-sticky{
        position: sticky;
        top: 20px;
        
    }

    &__selection{
        padding: 18px 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        transition-property: background-color;
        transition-duration: .15s;
        cursor: pointer;
        &:hover{
            background-color: map-get($colors, dgreen)!important;
            & .poker-rooms__selection-icon, span{
                fill: white;
                color: white!important;
            }
        }
        & span{
            transition-property: color;
            transition-duration: .15s;
        }
        &-icon{
            fill: #08A23B;
            transition-property: fill;
            transition-duration: .15s;
        }
    }
    
    &__tabs{
        & input:checked + .poker-rooms__tab-font > p{
            color: black!important;
        } 

        &-noshadow{
            box-shadow: none;
        }

        &-first{
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
        }

        &-wrapper-large{
            width: 300px;
        }
    }

    &__more{
        position: relative;
        cursor: pointer;
        &-wrapper{
            margin-top: -8px;
            padding: 0 16px 16px 16px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        &:hover{
            color: #3564a0 !important;
        }
        &:hover::after{
            opacity: 0;
        }
        &::after{
            content: "";
            width: 100%;
            height: 1px;
            border-radius: 4px;
            position: absolute;
            right: 0;
            left: 0;
            bottom: -2px;
            background-color: rgba(52, 110, 184, 0.24);
            transition: background-color 0.2s;
            transition-property: opacity;
            transition-duration: 0.15s;
        }
    }

    &__trainer{
        &-wrapper{
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
        }
        &-avatar{
            width: 80px;
            height: 80px;
            &-container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #E7EEF6;
                overflow: hidden;
                flex-shrink: 0;
            }
        }
    }

    &__card-right-side-buttons > :first-child{
        margin-bottom: 8px!important;
    }
}

.social-services-circle-hover{
    display: flex;
    &__icon{
        width: 35px;
        height: 35px;
        border-radius: 4px;
        position: relative;
        transition-property: width, height;
        transition-duration: .2s;
        font-size: 20px;
        &::before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%); 
            color: white;
            transition-property: font-size;
            transition-duration: .2s;
        }
        &.icon-chat{
            background-color: map-get($colors, chat);
        }
        &.icon-mail{
            background-color: map-get($colors, mail)
        }
        &.icon-telegram{
            background-color: map-get($colors, telegram);
        }
        &.icon-skype{
            background-color: map-get($colors, skype);
        }
    }
    &-background{
        width: 60px;
        height: 60px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px solid transparent;
        border-radius: 100px;
        transition-property: background-color, border;
        transition-duration: .2s;
        &:not(:last-child){
            margin-right: 4px;
        }
        &:hover{
            background-color: #E7EEF6;
            border: 4px solid rgba(52, 110, 184, 0.24);
        }
        &:hover .social-services-circle-hover__icon{
            width: 39px;
            height: 39px;
            // font-size: 30px;
        }
        &:hover .social-services-circle-hover__icon::before{
            font-size: 26px;
        }
    }
}

@media($media_max_phone){
    .poker-rooms__tabs-wrapper-large{
        width: 100%;
    }
    .poker-rooms__card-right-side-buttons > :first-child{
        margin-bottom: 0px!important;
        margin-right: 8px !important
    }
}