@import "config";
@import "icon_names";

html {
	font-size: 20px;

	&._popup {
		overflow: hidden;
	}
}

body {
	color: map_get($colors, black);
	font-family: $font_roboto;
	background: map_get($colors, ulgray);
	min-width: $content_min_width;
	font-size: 15px;
	line-height: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
	font-weight: 500;
	font-size: 30px;
	line-height: 36px;
	margin: 0;
	padding: 0;
}

h2, .h2 {
	font-weight: 500;
	font-size: 26px;
	line-height: 28px;
	margin: 0;
	padding: 0;
}

h3, .h3 {
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	margin: 0;
	padding: 0;
}

h4, .h4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	margin: 0;
	padding: 0;
}

h5, .h5 {
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	margin: 0;
	padding: 0;
}

h6, .h6 {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	margin: 0;
	padding: 0;
}

a {
	color: map_get($colors, black);
	transition: opacity .2s, color .2s;
	text-decoration: none;

	&:visited {
		color: map_get($colors, black);
	}

	&:hover {
		text-decoration: none;
		color: map_get($colors, black);
	}

	@include hover() {
		color: map_get($colors, dblue);
	}
}

* {
	outline: 0;
}

.content {
	&-wrap {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		padding-top: $header_height + $content_layout_padding;
	}

	&-layout {
		max-width: $content_min_width;
		padding: 0 $content_layout_padding;
		margin: 0 auto;
		width: 100%;
		flex-shrink: 0;
	}

	&-row {
		display: flex;
		margin: 0 -10px;
	}

	&-col {
		width: 100%;
		margin: 0 10px;

		&_widget {
			width: 300px;
			flex-shrink: 0;
		}
	}
}


@media ($media_min_phone) {
	html {
		&._popup-menu {
			overflow: auto;
		}
	}

	.show-phone {
		display: none !important;
	}
}

@media ($media_max_phone) {
	html {
		&._popover-header {
			overflow: hidden;
		}

		&._popup-rooms-filter {
			overflow: auto;
		}
	}

	body {
		min-width: $content_min_width_phone;
	}

	h1, .h1 {
		font-weight: 500;
		font-size: 30px;
		line-height: 36px;
		margin: 0;
		padding: 0;
	}

	h2, .h2 {
		font-weight: 500;
		font-size: 22px;
		line-height: 28px;
		margin: 0;
		padding: 0;
	}

	h3, .h3 {
		font-weight: 500;
		font-size: 20px;
		line-height: 26px;
		margin: 0;
		padding: 0;
	}

	h4, .h4 {
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		margin: 0;
		padding: 0;
	}

	h5, .h5 {
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
		margin: 0;
		padding: 0;
	}

	h6, .h6 {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		margin: 0;
		padding: 0;
	}

	.content {
		&-wrap {
			padding-top: $header_height_phone + 10px;
			min-height: -webkit-fill-available;
			box-sizing: content-box;
		}

		&-layout {
			padding: 0;
		}

		&-row {
			display: block;
			margin: 0;
		}

		&-col {
			margin: 0;
			width: auto;

			&_widget {
				width: auto;
			}
		}
	}

	.hide-phone {
		display: none !important;
	}
}

.font-synthesis-none {
	font-synthesis: none !important;
}