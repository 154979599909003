@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes translateN66 {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-66.66%);
	}
}

@keyframes rightSpin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}

@keyframes leftSpin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes closeWrapper {
	to {
		clip: rect(auto, auto, auto, auto);
	}
}
