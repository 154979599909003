@import "config";

//1 - 5
//2 - 10
//3 - 20
//4 - 30
//5 - 60

.rounded {
	border-radius: 4px !important;
}
.border-circle {
	border-radius: 50%;
}

.c {
	@each $name, $color in $colors {
		&-#{"" + $name} {
			color: $color !important;
		}

		&-bg-#{"" + $name} {
			background-color: $color !important;
		}
	}
}

.f {
	&s {
		$sizes: 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 24, 26, 28, 30, 34, 36, 40, 44, 68;

		@each $size in $sizes {
			&-#{$size} {
				font-size: #{$size}px !important;
			}
		}
	}

	&w {
		&-reg {
			font-weight: 400 !important;
		}

		&-med {
			font-weight: 500 !important;
		}

		&-bold {
			font-weight: 700 !important;
		}
	}
}

.l {
	&h {
		$sizes: 1, 10, 14, 15, 18, 20, 24, 26, 28, 52;

		@each $size in $sizes {
			&-#{$size} {
				@if $size == 1 {
					line-height: #{$size} !important;
				} @else {
					line-height: #{$size}px !important;
				}
			}
		}
	}

	&s {
		$sizes: 2, 8, 15;

		@each $size in $sizes {
			&-#{$size} {
				letter-spacing: #{calc($size / 10)}px !important;
			}
		}
	}
}

.m {
	$sizes: 8, 15, 16, 24, 40;

	@each $size in $sizes {
		&-#{$size} {
			margin: #{$size}px !important;
		}

		&t-#{$size} {
			margin-top: #{$size}px !important;
		}

		&b-#{$size} {
			margin-bottom: #{$size}px !important;
		}

		&b-n#{$size} {
			margin-bottom: -#{$size}px !important;
		}

		&l-#{$size} {
			margin-left: #{$size}px !important;
		}

		&r-#{$size} {
			margin-right: #{$size}px !important;
		}

		&x-#{$size} {
			margin-left: #{$size}px !important;
			margin-right: #{$size}px !important;
		}

		&x-n#{$size} {
			margin-left: -#{$size}px !important;
			margin-right: -#{$size}px !important;
		}

		&y-#{$size} {
			margin-top: #{$size}px !important;
			margin-bottom: #{$size}px !important;
		}
	}
}

.p {
	$sizes: 8, 16, 24, 40;

	@each $size in $sizes {
		&-#{$size} {
			padding: #{$size}px !important;
		}

		&t-#{$size} {
			padding-top: #{$size}px !important;
		}

		&b-#{$size} {
			padding-bottom: #{$size}px !important;
		}

		&l-#{$size} {
			padding-left: #{$size}px !important;
		}

		&r-#{$size} {
			padding-right: #{$size}px !important;
		}

		&x-#{$size} {
			padding-left: #{$size}px !important;
			padding-right: #{$size}px !important;
		}

		&y-#{$size} {
			padding-top: #{$size}px !important;
			padding-bottom: #{$size}px !important;
		}
	}
}

.s {
	$sizes: 8, 18, 20, 24, 28, 32, 40, 42, 50, 80;

	@each $size in $sizes {
		&-#{$size} {
			width: #{$size}px !important;
			height: #{$size}px !important;
		}
	}
}

.h {
	$sizes: 1, 15, 20, 24, 28, 32, 48, 52, 64, 88, 105;

	@each $size in $sizes {
		&-#{$size} {
			height: #{$size}px !important;
		}
	}
}

.w {
	$sizes: 56, 77, 220, 240, 260, 700, 780;

	@each $size in $sizes {
		&-#{$size} {
			width: #{$size}px !important;
		}

		&-m-#{$size} {
			max-width: #{$size}px !important;
		}
	}

	&-33 {
		width: 33.33% !important;
	}
}

.o {
	$sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9;

	@each $size in $sizes {
		&-#{$size} {
			opacity: #{calc($size / 10)} !important;
		}
	}
}

.pe {
	&-n {
		pointer-events: none !important;
	}

	&-a {
		pointer-events: auto !important;
	}
}

.br {
	$sizes: 2, 4, 8;

	@each $size in $sizes {
		&-#{$size} {
			border-radius: #{$size}px !important;
		}
	}
}

.cur-p {
	cursor: pointer !important;
}

.pattern {
	&-1 {
		background-color: #f9f9f9;
		background-image: url($img_dir + 'pattern_1.png');
		background-position: center center;
		background-size: 20px 20px;
	}
}

.shadow {
	&-1 {
		box-shadow: 0 1px 4px rgba(0, 0, 0, .14) !important;
	}

	&-2 {
		box-shadow: 0 2px 20px rgba(0, 0, 0, .08) !important;
	}

	&-3 {
		box-shadow:
			0 0 0 1px map_get($colors, ulgray),
			0 1px 4px rgba(0, 0, 0, .14) inset !important;
	}
}

.border {
	@each $name, $color in $colors {
		&-#{"" + $name} {
			border: 1px solid $color !important;
		}

		&-shadow-#{"" + $name} {
			box-shadow: 0 0 0 1px $color !important;
		}
	}

	&-dashed {
		border-style: dashed !important;
	}
}

.gap {
	&-5 {
	  gap: 5px;
	}
	&-10 {
	  gap: 10px;
	}
	&-20 {
	  gap: 20px;
	}
  }

  .flex {
	&-1 {
		flex: 1;
	}
  }

@media ($media_min_phone) {
	.br-sm-0 {
		border-radius: 0 !important;
	}

	.f {
		&s {
			$sizes: 13, 14, 15, 16, 30;

			@each $size in $sizes {
				&-sm-#{$size} {
					font-size: #{$size}px !important;
				}
			}
		}

		&w {
			&-sm-reg {
				font-weight: 400 !important;
			}

			&-sm-med {
				font-weight: 500 !important;
			}

			&-sm-bold {
				font-weight: 700 !important;
			}
		}
	}

	.l {
		&h {
			$sizes: 1, 22, 36;

			@each $size in $sizes {
				&-sm-#{$size} {
					@if $size == 1 {
						line-height: #{$size} !important;
					} @else {
						line-height: #{$size}px !important;
					}
				}
			}
		}
	}

	.m {
		$sizes: 0, 6, 8, 10, 16, 20, 24, 30, 40, 60;

		@each $size in $sizes {
			&-sm-#{$size} {
				margin: #{$size}px !important;
			}

			&t-sm-#{$size} {
				margin-top: #{$size}px !important;
			}

			&b-sm-#{$size} {
				margin-bottom: #{$size}px !important;
			}

			&l-sm-#{$size} {
				margin-left: #{$size}px !important;
			}

			&r-sm-#{$size} {
				margin-right: #{$size}px !important;
			}

			&x-sm-#{$size} {
				margin-left: #{$size}px !important;
				margin-right: #{$size}px !important;
			}

			&y-sm-#{$size} {
				margin-top: #{$size}px !important;
				margin-bottom: #{$size}px !important;
			}

			&x-sm-n#{$size} {
				margin-left: -#{$size}px !important;
				margin-right: -#{$size}px !important;
			}
		}
	}

	.p {
		$sizes: 0, 6, 8, 10, 16, 20, 24, 30, 40, 60;

		@each $size in $sizes {
			&-sm-#{$size} {
				padding: #{$size}px !important;
			}

			&t-sm-#{$size} {
				padding-top: #{$size}px !important;
			}

			&b-sm-#{$size} {
				padding-bottom: #{$size}px !important;
			}

			&l-sm-#{$size} {
				padding-left: #{$size}px !important;
			}

			&r-sm-#{$size} {
				padding-right: #{$size}px !important;
			}

			&x-sm-#{$size} {
				padding-left: #{$size}px !important;
				padding-right: #{$size}px !important;
			}

			&y-sm-#{$size} {
				padding-top: #{$size}px !important;
				padding-bottom: #{$size}px !important;
			}
		}
	}

	.w {
		$sizes: 1, 48, 80, 180, 220, 240, 280, 300, 330, 370, 450, 460, 500, 620, 670;

		@each $size in $sizes {
			&-sm-#{$size} {
				width: #{$size}px !important;
			}
		}
	}

	.h-sm {
		&-100 {
			height: 100% !important;
		}

		&-auto {
			height: auto !important;
		}
	}

	.w-sm-auto {
		width: auto !important;
	}

	.position-sm-relative {
		position: relative !important;
	}
}

@media ($media_max_phone) {
	.br-0 {
		border-radius: 0 !important;
	}
}
