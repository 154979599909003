$img_dir: '../img/';
$fonts_dir: '../fonts/';

$font_roboto: 'Roboto', sans-serif;
$font_icomoon: 'icomoon' !important;

$colors: (
	black: #000,
	white: #fff,
	beige: #EFEDE2,
	gray: #6c6b6b,
	dgray: #444,
	mgray: #cdcdcd,
	lgray: #aaa,
	ulgray: #e9e9e9,
	mulgray: #fafafa,
	blue: #346eb8,
	dblue: #3564a0,
	pblue: #eff3f9,
	yellow: #ebcb24,
	lyellow: #fefdf5,
	pyellow: #faf3cf,
	green: #08a23b,
	dgreen: #15873b,
	pgreen: #ebf8ef,
	red: #fb4d4e,
	pred: #fff1f1,
	facebook: #3b5998,
	vk: #4c75a3,
	telegram: #08c,
	ok: #f97400,
	youtube: #c4302b,
	insta: #3f729b,
	skype: #00aff0,
	twitter: #00acee,
	whatsapp: #25d366,
	mail: #346eb8,
	chat: #08a23b,
	www: #346eb8
);

$content_min_width: 1220px;
$content_min_width_phone: 360px;
$content_layout_padding: 20px;
$header_height: 120px;
$header_height_phone: 50px;
$popup_zindex: 3000;
$notification_zindex: 2060;
$header_zindex: 2000;
$popover_zindex: 1060;

$media_max_phone: 'max-width: 575.98px';
$media_min_phone: 'min-width: 576px';


@mixin hover {
	@media ($media_min_phone) {
		&:hover {
			@content;
		}
	}
}

@mixin icomoonInit {
	font-family: $font_icomoon;
	flex-shrink: 0;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
