@charset "UTF-8";
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes translateN66 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-66.66%);
  }
}
@keyframes rightSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes leftSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes closeWrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon {
  font-family: "icomoon" !important;
  flex-shrink: 0;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  line-height: 1;
}

.icon-triangle-top:before {
  content: "\e951";
}

.icon-cards:before {
  content: "\e900";
}

.icon-book:before {
  content: "\e901";
}

.icon-rating-null:before {
  content: "\e902";
}

.icon-rating:before {
  content: "\e903";
}

.icon-youtube:before {
  content: "\e904";
}

.icon-skype:before {
  content: "\e905";
}

.icon-twitter:before {
  content: "\e906";
}

.icon-google:before {
  content: "\e907";
}

.icon-insta:before {
  content: "\e908";
}

.icon-ok:before {
  content: "\e909";
}

.icon-telegram:before {
  content: "\e90a";
}

.icon-vk:before {
  content: "\e90b";
}

.icon-facebook:before {
  content: "\e90c";
}

.icon-chat:before {
  content: "\e90d";
}

.icon-mail:before {
  content: "\e90e";
}

.icon-whatsapp:before {
  content: "\e90f";
}

.icon-www:before {
  content: "\e910";
}

.icon-minus-round:before {
  content: "\e911";
}

.icon-plus-round:before {
  content: "\e912";
}

.icon-menu-burger:before {
  content: "\e913";
}

.icon-exit:before {
  content: "\e914";
}

.icon-arrow-down:before {
  content: "\e915";
}

.icon-people:before {
  content: "\e916";
}

.icon-fish:before {
  content: "\e917";
}

.icon-exchange:before {
  content: "\e918";
}

.icon-gears:before {
  content: "\e919";
}

.icon-wallet:before {
  content: "\e91a";
}

.icon-gift:before {
  content: "\e91b";
}

.icon-info-bubble:before {
  content: "\e91c";
}

.icon-voice:before {
  content: "\e91d";
}

.icon-reward:before {
  content: "\e91e";
}

.icon-treasure:before {
  content: "\e91f";
}

.icon-ticket:before {
  content: "\e920";
}

.icon-timetable:before {
  content: "\e921";
}

.icon-money-transfer:before {
  content: "\e922";
}

.icon-cash-hand:before {
  content: "\e923";
}

.icon-info-circle:before {
  content: "\e924";
}

.icon-chip-v:before {
  content: "\e925";
}

.icon-todo:before {
  content: "\e926";
}

.icon-reply:before {
  content: "\e927";
}

.icon-plane-solid:before {
  content: "\e928";
}

.icon-plane:before {
  content: "\e929";
}

.icon-bookmark-solid:before {
  content: "\e92a";
}

.icon-bookmark:before {
  content: "\e92b";
}

.icon-comment-solid:before {
  content: "\e92c";
}

.icon-comment:before {
  content: "\e92d";
}

.icon-star:before {
  content: "\e92e";
}

.icon-star-null:before {
  content: "\e952";
}

.icon-wifi:before {
  content: "\e92f";
}

.icon-bell-solid:before {
  content: "\e930";
}

.icon-bell:before {
  content: "\e931";
}

.icon-info:before {
  content: "\e932";
}

.icon-megaphone:before {
  content: "\e933";
}

.icon-quote:before {
  content: "\e934";
}

.icon-clock:before {
  content: "\e935";
}

.icon-warning:before {
  content: "\e936";
}

.icon-blocked:before {
  content: "\e937";
}

.icon-minus:before {
  content: "\e938";
}

.icon-plus:before {
  content: "\e939";
}

.icon-close:before {
  content: "\e93a";
}

.icon-pen:before {
  content: "\e93b";
}

.icon-video:before {
  content: "\e93c";
}

.icon-link:before {
  content: "\e93d";
}

.icon-camera:before {
  content: "\e93e";
}

.icon-image:before {
  content: "\e93f";
}

.icon-search:before {
  content: "\e940";
}

.icon-login:before {
  content: "\e941";
}

.icon-chevron-right:before {
  content: "\e942";
}

.icon-check-circle:before {
  content: "\e943";
}

.icon-check:before {
  content: "\e944";
}

.icon-arrow-right:before {
  content: "\e945";
}

.icon-avatar:before {
  content: "\e946";
}

.icon-download:before {
  content: "\e947";
}

.icon-gear:before {
  content: "\e948";
}

.icon-clock2:before {
  content: "\e949";
}

.icon-mic:before {
  content: "\e94a";
}

.icon-youtube-play:before {
  content: "\e94b";
}

.icon-bubble:before {
  content: "\e94c";
}

.icon-dots:before {
  content: "\e94d";
}

.icon-fire:before {
  content: "\e94e";
}

.icon-eye:before {
  content: "\e94f";
}

.icon-chevron-top:before {
  content: "\e950";
}

html {
  font-size: 20px;
}
html._popup {
  overflow: hidden;
}

body {
  color: #000;
  font-family: "Roboto", sans-serif;
  background: #e9e9e9;
  min-width: 1220px;
  font-size: 15px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  padding: 0;
}

h2, .h2 {
  font-weight: 500;
  font-size: 26px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

h3, .h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  padding: 0;
}

h4, .h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  padding: 0;
}

h5, .h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  padding: 0;
}

h6, .h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  transition: opacity 0.2s, color 0.2s;
  text-decoration: none;
}
a:visited {
  color: #000;
}
a:hover {
  text-decoration: none;
  color: #000;
}
@media (min-width: 576px) {
  a:hover {
    color: #3564a0;
  }
}

* {
  outline: 0;
}

.content-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 140px;
}
.content-layout {
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  flex-shrink: 0;
}
.content-row {
  display: flex;
  margin: 0 -10px;
}
.content-col {
  width: 100%;
  margin: 0 10px;
}
.content-col_widget {
  width: 300px;
  flex-shrink: 0;
}

@media (min-width: 576px) {
  html._popup-menu {
    overflow: auto;
  }
  .show-phone {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  html._popover-header {
    overflow: hidden;
  }
  html._popup-rooms-filter {
    overflow: auto;
  }
  body {
    min-width: 360px;
  }
  h1, .h1 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
    padding: 0;
  }
  h2, .h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    padding: 0;
  }
  h3, .h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin: 0;
    padding: 0;
  }
  h4, .h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    padding: 0;
  }
  h5, .h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    padding: 0;
  }
  h6, .h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }
  .content-wrap {
    padding-top: 60px;
    min-height: -webkit-fill-available;
    box-sizing: content-box;
  }
  .content-layout {
    padding: 0;
  }
  .content-row {
    display: block;
    margin: 0;
  }
  .content-col {
    margin: 0;
    width: auto;
  }
  .content-col_widget {
    width: auto;
  }
  .hide-phone {
    display: none !important;
  }
}
.font-synthesis-none {
  font-synthesis: none !important;
}

.rounded {
  border-radius: 4px !important;
}

.border-circle {
  border-radius: 50%;
}

.c-black {
  color: #000 !important;
}
.c-bg-black {
  background-color: #000 !important;
}
.c-white {
  color: #fff !important;
}
.c-bg-white {
  background-color: #fff !important;
}
.c-beige {
  color: #EFEDE2 !important;
}
.c-bg-beige {
  background-color: #EFEDE2 !important;
}
.c-gray {
  color: #6c6b6b !important;
}
.c-bg-gray {
  background-color: #6c6b6b !important;
}
.c-dgray {
  color: #444 !important;
}
.c-bg-dgray {
  background-color: #444 !important;
}
.c-mgray {
  color: #cdcdcd !important;
}
.c-bg-mgray {
  background-color: #cdcdcd !important;
}
.c-lgray {
  color: #aaa !important;
}
.c-bg-lgray {
  background-color: #aaa !important;
}
.c-ulgray {
  color: #e9e9e9 !important;
}
.c-bg-ulgray {
  background-color: #e9e9e9 !important;
}
.c-mulgray {
  color: #fafafa !important;
}
.c-bg-mulgray {
  background-color: #fafafa !important;
}
.c-blue {
  color: #346eb8 !important;
}
.c-bg-blue {
  background-color: #346eb8 !important;
}
.c-dblue {
  color: #3564a0 !important;
}
.c-bg-dblue {
  background-color: #3564a0 !important;
}
.c-pblue {
  color: #eff3f9 !important;
}
.c-bg-pblue {
  background-color: #eff3f9 !important;
}
.c-yellow {
  color: #ebcb24 !important;
}
.c-bg-yellow {
  background-color: #ebcb24 !important;
}
.c-lyellow {
  color: #fefdf5 !important;
}
.c-bg-lyellow {
  background-color: #fefdf5 !important;
}
.c-pyellow {
  color: #faf3cf !important;
}
.c-bg-pyellow {
  background-color: #faf3cf !important;
}
.c-green {
  color: #08a23b !important;
}
.c-bg-green {
  background-color: #08a23b !important;
}
.c-dgreen {
  color: #15873b !important;
}
.c-bg-dgreen {
  background-color: #15873b !important;
}
.c-pgreen {
  color: #ebf8ef !important;
}
.c-bg-pgreen {
  background-color: #ebf8ef !important;
}
.c-red {
  color: #fb4d4e !important;
}
.c-bg-red {
  background-color: #fb4d4e !important;
}
.c-pred {
  color: #fff1f1 !important;
}
.c-bg-pred {
  background-color: #fff1f1 !important;
}
.c-facebook {
  color: #3b5998 !important;
}
.c-bg-facebook {
  background-color: #3b5998 !important;
}
.c-vk {
  color: #4c75a3 !important;
}
.c-bg-vk {
  background-color: #4c75a3 !important;
}
.c-telegram {
  color: #08c !important;
}
.c-bg-telegram {
  background-color: #08c !important;
}
.c-ok {
  color: #f97400 !important;
}
.c-bg-ok {
  background-color: #f97400 !important;
}
.c-youtube {
  color: #c4302b !important;
}
.c-bg-youtube {
  background-color: #c4302b !important;
}
.c-insta {
  color: #3f729b !important;
}
.c-bg-insta {
  background-color: #3f729b !important;
}
.c-skype {
  color: #00aff0 !important;
}
.c-bg-skype {
  background-color: #00aff0 !important;
}
.c-twitter {
  color: #00acee !important;
}
.c-bg-twitter {
  background-color: #00acee !important;
}
.c-whatsapp {
  color: #25d366 !important;
}
.c-bg-whatsapp {
  background-color: #25d366 !important;
}
.c-mail {
  color: #346eb8 !important;
}
.c-bg-mail {
  background-color: #346eb8 !important;
}
.c-chat {
  color: #08a23b !important;
}
.c-bg-chat {
  background-color: #08a23b !important;
}
.c-www {
  color: #346eb8 !important;
}
.c-bg-www {
  background-color: #346eb8 !important;
}

.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-34 {
  font-size: 34px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-44 {
  font-size: 44px !important;
}
.fs-68 {
  font-size: 68px !important;
}
.fw-reg {
  font-weight: 400 !important;
}
.fw-med {
  font-weight: 500 !important;
}
.fw-bold {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}
.lh-10 {
  line-height: 10px !important;
}
.lh-14 {
  line-height: 14px !important;
}
.lh-15 {
  line-height: 15px !important;
}
.lh-18 {
  line-height: 18px !important;
}
.lh-20 {
  line-height: 20px !important;
}
.lh-24 {
  line-height: 24px !important;
}
.lh-26 {
  line-height: 26px !important;
}
.lh-28 {
  line-height: 28px !important;
}
.lh-52 {
  line-height: 52px !important;
}
.ls-2 {
  letter-spacing: 0.2px !important;
}
.ls-8 {
  letter-spacing: 0.8px !important;
}
.ls-15 {
  letter-spacing: 1.5px !important;
}

.m-8 {
  margin: 8px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-n8 {
  margin-bottom: -8px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-n8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}
.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.m-15 {
  margin: 15px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-n15 {
  margin-bottom: -15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mx-n15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.m-16 {
  margin: 16px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-n16 {
  margin-bottom: -16px !important;
}
.ml-16 {
  margin-left: 16px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mx-n16 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}
.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.m-24 {
  margin: 24px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-n24 {
  margin-bottom: -24px !important;
}
.ml-24 {
  margin-left: 24px !important;
}
.mr-24 {
  margin-right: 24px !important;
}
.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mx-n24 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.m-40 {
  margin: 40px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-n40 {
  margin-bottom: -40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.mx-n40 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}
.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.p-8 {
  padding: 8px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pb-8 {
  padding-bottom: 8px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.pr-8 {
  padding-right: 8px !important;
}
.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.p-16 {
  padding: 16px !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.pr-16 {
  padding-right: 16px !important;
}
.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.p-24 {
  padding: 24px !important;
}
.pt-24 {
  padding-top: 24px !important;
}
.pb-24 {
  padding-bottom: 24px !important;
}
.pl-24 {
  padding-left: 24px !important;
}
.pr-24 {
  padding-right: 24px !important;
}
.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.p-40 {
  padding: 40px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.s-8 {
  width: 8px !important;
  height: 8px !important;
}
.s-18 {
  width: 18px !important;
  height: 18px !important;
}
.s-20 {
  width: 20px !important;
  height: 20px !important;
}
.s-24 {
  width: 24px !important;
  height: 24px !important;
}
.s-28 {
  width: 28px !important;
  height: 28px !important;
}
.s-32 {
  width: 32px !important;
  height: 32px !important;
}
.s-40 {
  width: 40px !important;
  height: 40px !important;
}
.s-42 {
  width: 42px !important;
  height: 42px !important;
}
.s-50 {
  width: 50px !important;
  height: 50px !important;
}
.s-80 {
  width: 80px !important;
  height: 80px !important;
}

.h-1 {
  height: 1px !important;
}
.h-15 {
  height: 15px !important;
}
.h-20 {
  height: 20px !important;
}
.h-24 {
  height: 24px !important;
}
.h-28 {
  height: 28px !important;
}
.h-32 {
  height: 32px !important;
}
.h-48 {
  height: 48px !important;
}
.h-52 {
  height: 52px !important;
}
.h-64 {
  height: 64px !important;
}
.h-88 {
  height: 88px !important;
}
.h-105 {
  height: 105px !important;
}

.w-56 {
  width: 56px !important;
}
.w-m-56 {
  max-width: 56px !important;
}
.w-77 {
  width: 77px !important;
}
.w-m-77 {
  max-width: 77px !important;
}
.w-220 {
  width: 220px !important;
}
.w-m-220 {
  max-width: 220px !important;
}
.w-240 {
  width: 240px !important;
}
.w-m-240 {
  max-width: 240px !important;
}
.w-260 {
  width: 260px !important;
}
.w-m-260 {
  max-width: 260px !important;
}
.w-700 {
  width: 700px !important;
}
.w-m-700 {
  max-width: 700px !important;
}
.w-780 {
  width: 780px !important;
}
.w-m-780 {
  max-width: 780px !important;
}
.w-33 {
  width: 33.33% !important;
}

.o-1 {
  opacity: 0.1 !important;
}
.o-2 {
  opacity: 0.2 !important;
}
.o-3 {
  opacity: 0.3 !important;
}
.o-4 {
  opacity: 0.4 !important;
}
.o-5 {
  opacity: 0.5 !important;
}
.o-6 {
  opacity: 0.6 !important;
}
.o-7 {
  opacity: 0.7 !important;
}
.o-8 {
  opacity: 0.8 !important;
}
.o-9 {
  opacity: 0.9 !important;
}

.pe-n {
  pointer-events: none !important;
}
.pe-a {
  pointer-events: auto !important;
}

.br-2 {
  border-radius: 2px !important;
}
.br-4 {
  border-radius: 4px !important;
}
.br-8 {
  border-radius: 8px !important;
}

.cur-p {
  cursor: pointer !important;
}

.pattern-1 {
  background-color: #f9f9f9;
  background-image: url("../img/pattern_1.png");
  background-position: center center;
  background-size: 20px 20px;
}

.shadow-1 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14) !important;
}
.shadow-2 {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08) !important;
}
.shadow-3 {
  box-shadow: 0 0 0 1px #e9e9e9, 0 1px 4px rgba(0, 0, 0, 0.14) inset !important;
}

.border-black {
  border: 1px solid #000 !important;
}
.border-shadow-black {
  box-shadow: 0 0 0 1px #000 !important;
}
.border-white {
  border: 1px solid #fff !important;
}
.border-shadow-white {
  box-shadow: 0 0 0 1px #fff !important;
}
.border-beige {
  border: 1px solid #EFEDE2 !important;
}
.border-shadow-beige {
  box-shadow: 0 0 0 1px #EFEDE2 !important;
}
.border-gray {
  border: 1px solid #6c6b6b !important;
}
.border-shadow-gray {
  box-shadow: 0 0 0 1px #6c6b6b !important;
}
.border-dgray {
  border: 1px solid #444 !important;
}
.border-shadow-dgray {
  box-shadow: 0 0 0 1px #444 !important;
}
.border-mgray {
  border: 1px solid #cdcdcd !important;
}
.border-shadow-mgray {
  box-shadow: 0 0 0 1px #cdcdcd !important;
}
.border-lgray {
  border: 1px solid #aaa !important;
}
.border-shadow-lgray {
  box-shadow: 0 0 0 1px #aaa !important;
}
.border-ulgray {
  border: 1px solid #e9e9e9 !important;
}
.border-shadow-ulgray {
  box-shadow: 0 0 0 1px #e9e9e9 !important;
}
.border-mulgray {
  border: 1px solid #fafafa !important;
}
.border-shadow-mulgray {
  box-shadow: 0 0 0 1px #fafafa !important;
}
.border-blue {
  border: 1px solid #346eb8 !important;
}
.border-shadow-blue {
  box-shadow: 0 0 0 1px #346eb8 !important;
}
.border-dblue {
  border: 1px solid #3564a0 !important;
}
.border-shadow-dblue {
  box-shadow: 0 0 0 1px #3564a0 !important;
}
.border-pblue {
  border: 1px solid #eff3f9 !important;
}
.border-shadow-pblue {
  box-shadow: 0 0 0 1px #eff3f9 !important;
}
.border-yellow {
  border: 1px solid #ebcb24 !important;
}
.border-shadow-yellow {
  box-shadow: 0 0 0 1px #ebcb24 !important;
}
.border-lyellow {
  border: 1px solid #fefdf5 !important;
}
.border-shadow-lyellow {
  box-shadow: 0 0 0 1px #fefdf5 !important;
}
.border-pyellow {
  border: 1px solid #faf3cf !important;
}
.border-shadow-pyellow {
  box-shadow: 0 0 0 1px #faf3cf !important;
}
.border-green {
  border: 1px solid #08a23b !important;
}
.border-shadow-green {
  box-shadow: 0 0 0 1px #08a23b !important;
}
.border-dgreen {
  border: 1px solid #15873b !important;
}
.border-shadow-dgreen {
  box-shadow: 0 0 0 1px #15873b !important;
}
.border-pgreen {
  border: 1px solid #ebf8ef !important;
}
.border-shadow-pgreen {
  box-shadow: 0 0 0 1px #ebf8ef !important;
}
.border-red {
  border: 1px solid #fb4d4e !important;
}
.border-shadow-red {
  box-shadow: 0 0 0 1px #fb4d4e !important;
}
.border-pred {
  border: 1px solid #fff1f1 !important;
}
.border-shadow-pred {
  box-shadow: 0 0 0 1px #fff1f1 !important;
}
.border-facebook {
  border: 1px solid #3b5998 !important;
}
.border-shadow-facebook {
  box-shadow: 0 0 0 1px #3b5998 !important;
}
.border-vk {
  border: 1px solid #4c75a3 !important;
}
.border-shadow-vk {
  box-shadow: 0 0 0 1px #4c75a3 !important;
}
.border-telegram {
  border: 1px solid #08c !important;
}
.border-shadow-telegram {
  box-shadow: 0 0 0 1px #08c !important;
}
.border-ok {
  border: 1px solid #f97400 !important;
}
.border-shadow-ok {
  box-shadow: 0 0 0 1px #f97400 !important;
}
.border-youtube {
  border: 1px solid #c4302b !important;
}
.border-shadow-youtube {
  box-shadow: 0 0 0 1px #c4302b !important;
}
.border-insta {
  border: 1px solid #3f729b !important;
}
.border-shadow-insta {
  box-shadow: 0 0 0 1px #3f729b !important;
}
.border-skype {
  border: 1px solid #00aff0 !important;
}
.border-shadow-skype {
  box-shadow: 0 0 0 1px #00aff0 !important;
}
.border-twitter {
  border: 1px solid #00acee !important;
}
.border-shadow-twitter {
  box-shadow: 0 0 0 1px #00acee !important;
}
.border-whatsapp {
  border: 1px solid #25d366 !important;
}
.border-shadow-whatsapp {
  box-shadow: 0 0 0 1px #25d366 !important;
}
.border-mail {
  border: 1px solid #346eb8 !important;
}
.border-shadow-mail {
  box-shadow: 0 0 0 1px #346eb8 !important;
}
.border-chat {
  border: 1px solid #08a23b !important;
}
.border-shadow-chat {
  box-shadow: 0 0 0 1px #08a23b !important;
}
.border-www {
  border: 1px solid #346eb8 !important;
}
.border-shadow-www {
  box-shadow: 0 0 0 1px #346eb8 !important;
}
.border-dashed {
  border-style: dashed !important;
}

.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-20 {
  gap: 20px;
}

.flex-1 {
  flex: 1;
}

@media (min-width: 576px) {
  .br-sm-0 {
    border-radius: 0 !important;
  }
  .fs-sm-13 {
    font-size: 13px !important;
  }
  .fs-sm-14 {
    font-size: 14px !important;
  }
  .fs-sm-15 {
    font-size: 15px !important;
  }
  .fs-sm-16 {
    font-size: 16px !important;
  }
  .fs-sm-30 {
    font-size: 30px !important;
  }
  .fw-sm-reg {
    font-weight: 400 !important;
  }
  .fw-sm-med {
    font-weight: 500 !important;
  }
  .fw-sm-bold {
    font-weight: 700 !important;
  }
  .lh-sm-1 {
    line-height: 1 !important;
  }
  .lh-sm-22 {
    line-height: 22px !important;
  }
  .lh-sm-36 {
    line-height: 36px !important;
  }
  .m-sm-0 {
    margin: 0px !important;
  }
  .mt-sm-0 {
    margin-top: 0px !important;
  }
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .ml-sm-0 {
    margin-left: 0px !important;
  }
  .mr-sm-0 {
    margin-right: 0px !important;
  }
  .mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mx-sm-n0 {
    margin-left: -0px !important;
    margin-right: -0px !important;
  }
  .m-sm-6 {
    margin: 6px !important;
  }
  .mt-sm-6 {
    margin-top: 6px !important;
  }
  .mb-sm-6 {
    margin-bottom: 6px !important;
  }
  .ml-sm-6 {
    margin-left: 6px !important;
  }
  .mr-sm-6 {
    margin-right: 6px !important;
  }
  .mx-sm-6 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .my-sm-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .mx-sm-n6 {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .m-sm-8 {
    margin: 8px !important;
  }
  .mt-sm-8 {
    margin-top: 8px !important;
  }
  .mb-sm-8 {
    margin-bottom: 8px !important;
  }
  .ml-sm-8 {
    margin-left: 8px !important;
  }
  .mr-sm-8 {
    margin-right: 8px !important;
  }
  .mx-sm-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-sm-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mx-sm-n8 {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .ml-sm-10 {
    margin-left: 10px !important;
  }
  .mr-sm-10 {
    margin-right: 10px !important;
  }
  .mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mx-sm-n10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .m-sm-16 {
    margin: 16px !important;
  }
  .mt-sm-16 {
    margin-top: 16px !important;
  }
  .mb-sm-16 {
    margin-bottom: 16px !important;
  }
  .ml-sm-16 {
    margin-left: 16px !important;
  }
  .mr-sm-16 {
    margin-right: 16px !important;
  }
  .mx-sm-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-sm-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .mx-sm-n16 {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mx-sm-n20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .m-sm-24 {
    margin: 24px !important;
  }
  .mt-sm-24 {
    margin-top: 24px !important;
  }
  .mb-sm-24 {
    margin-bottom: 24px !important;
  }
  .ml-sm-24 {
    margin-left: 24px !important;
  }
  .mr-sm-24 {
    margin-right: 24px !important;
  }
  .mx-sm-24 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .my-sm-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .mx-sm-n24 {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mx-sm-n30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .m-sm-40 {
    margin: 40px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
  .ml-sm-40 {
    margin-left: 40px !important;
  }
  .mr-sm-40 {
    margin-right: 40px !important;
  }
  .mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mx-sm-n40 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .m-sm-60 {
    margin: 60px !important;
  }
  .mt-sm-60 {
    margin-top: 60px !important;
  }
  .mb-sm-60 {
    margin-bottom: 60px !important;
  }
  .ml-sm-60 {
    margin-left: 60px !important;
  }
  .mr-sm-60 {
    margin-right: 60px !important;
  }
  .mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mx-sm-n60 {
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .p-sm-0 {
    padding: 0px !important;
  }
  .pt-sm-0 {
    padding-top: 0px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0px !important;
  }
  .pl-sm-0 {
    padding-left: 0px !important;
  }
  .pr-sm-0 {
    padding-right: 0px !important;
  }
  .px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .p-sm-6 {
    padding: 6px !important;
  }
  .pt-sm-6 {
    padding-top: 6px !important;
  }
  .pb-sm-6 {
    padding-bottom: 6px !important;
  }
  .pl-sm-6 {
    padding-left: 6px !important;
  }
  .pr-sm-6 {
    padding-right: 6px !important;
  }
  .px-sm-6 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .py-sm-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .p-sm-8 {
    padding: 8px !important;
  }
  .pt-sm-8 {
    padding-top: 8px !important;
  }
  .pb-sm-8 {
    padding-bottom: 8px !important;
  }
  .pl-sm-8 {
    padding-left: 8px !important;
  }
  .pr-sm-8 {
    padding-right: 8px !important;
  }
  .px-sm-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-sm-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .pt-sm-10 {
    padding-top: 10px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .pl-sm-10 {
    padding-left: 10px !important;
  }
  .pr-sm-10 {
    padding-right: 10px !important;
  }
  .px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .p-sm-16 {
    padding: 16px !important;
  }
  .pt-sm-16 {
    padding-top: 16px !important;
  }
  .pb-sm-16 {
    padding-bottom: 16px !important;
  }
  .pl-sm-16 {
    padding-left: 16px !important;
  }
  .pr-sm-16 {
    padding-right: 16px !important;
  }
  .px-sm-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-sm-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .p-sm-24 {
    padding: 24px !important;
  }
  .pt-sm-24 {
    padding-top: 24px !important;
  }
  .pb-sm-24 {
    padding-bottom: 24px !important;
  }
  .pl-sm-24 {
    padding-left: 24px !important;
  }
  .pr-sm-24 {
    padding-right: 24px !important;
  }
  .px-sm-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .py-sm-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .pt-sm-30 {
    padding-top: 30px !important;
  }
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
  .pl-sm-30 {
    padding-left: 30px !important;
  }
  .pr-sm-30 {
    padding-right: 30px !important;
  }
  .px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .pt-sm-40 {
    padding-top: 40px !important;
  }
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
  .pl-sm-40 {
    padding-left: 40px !important;
  }
  .pr-sm-40 {
    padding-right: 40px !important;
  }
  .px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .p-sm-60 {
    padding: 60px !important;
  }
  .pt-sm-60 {
    padding-top: 60px !important;
  }
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
  .pl-sm-60 {
    padding-left: 60px !important;
  }
  .pr-sm-60 {
    padding-right: 60px !important;
  }
  .px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .w-sm-1 {
    width: 1px !important;
  }
  .w-sm-48 {
    width: 48px !important;
  }
  .w-sm-80 {
    width: 80px !important;
  }
  .w-sm-180 {
    width: 180px !important;
  }
  .w-sm-220 {
    width: 220px !important;
  }
  .w-sm-240 {
    width: 240px !important;
  }
  .w-sm-280 {
    width: 280px !important;
  }
  .w-sm-300 {
    width: 300px !important;
  }
  .w-sm-330 {
    width: 330px !important;
  }
  .w-sm-370 {
    width: 370px !important;
  }
  .w-sm-450 {
    width: 450px !important;
  }
  .w-sm-460 {
    width: 460px !important;
  }
  .w-sm-500 {
    width: 500px !important;
  }
  .w-sm-620 {
    width: 620px !important;
  }
  .w-sm-670 {
    width: 670px !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
}
@media (max-width: 575.98px) {
  .br-0 {
    border-radius: 0 !important;
  }
}
.btn {
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  min-height: 40px;
  display: inline-flex;
  justify-content: center;
  padding: 5px 46px;
  align-items: center;
  text-decoration: none;
  color: #000;
  background: #fff;
  position: relative;
  flex-shrink: 0;
  border: 1px solid transparent;
  line-height: 1;
}
.btn:active {
  opacity: 0.9;
}
.btn:focus {
  box-shadow: none !important;
}
.btn .icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  left: 12px;
  top: 8px;
  margin: -1px 0 0 -1px;
  font-size: 18px;
  transition: none;
}
.btn .icon ~ .icon {
  left: auto;
  right: 8px;
}
.btn._green {
  background: #08a23b;
  color: #fff;
}
@media (min-width: 576px) {
  .btn._green:hover {
    background: #15873b !important;
    color: #fff !important;
  }
}
.btn._blue {
  background: #346eb8;
  color: #fff;
}
@media (min-width: 576px) {
  .btn._blue:hover {
    background: #3564a0 !important;
    color: #fff !important;
  }
}
.btn._pblue {
  background: #eff3f9;
  color: #346eb8;
  padding: 10px 16px;
}
@media (min-width: 576px) {
  .btn._pblue:hover {
    background: #346eb8 !important;
    color: #fff !important;
  }
}
.btn._red {
  background: #fb4d4e;
  color: #fff;
}
@media (min-width: 576px) {
  .btn._red:hover {
    background: #e94344 !important;
    color: #fff !important;
  }
}
.btn._circle {
  width: 32px;
  min-height: 32px;
  border-radius: 50%;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  padding: 0;
}
.btn._circle .icon {
  top: 4px;
  left: 4px;
  font-size: 12px;
}
.btn._circle .icon-chevron-top {
  transform: scaleY(-1);
  will-change: transform;
  line-height: 22px;
  transition: transform 0.2s;
}
.btn._shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
}
@media (min-width: 576px) {
  .btn._shadow:hover {
    box-shadow: none;
    border-color: #aaa;
    color: #000;
  }
}
.btn._block {
  display: flex;
  width: 100%;
}
.btn._lg {
  min-height: 56px;
  font-weight: 500;
}
.btn._md {
  padding: 8px 24px;
}
.btn._sm {
  min-height: 30px;
  font-size: 14px;
}
.btn._disabled, .btn[disabled] {
  pointer-events: none;
  box-shadow: none;
  opacity: 1 !important;
  background: #cdcdcd;
  color: #aaa;
}

.link-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.link-invert {
  color: #346eb8;
}
.link-invert:visited {
  color: #346eb8;
}
@media (min-width: 576px) {
  .link-invert:hover {
    color: #000;
  }
}
.link-blue {
  color: #346eb8;
}
.link-blue:visited {
  color: #346eb8;
}
@media (min-width: 576px) {
  .link-blue:hover {
    color: #3564a0;
  }
}
.link-underline {
  text-decoration: underline;
}
@media (min-width: 576px) {
  .link-underline:hover {
    text-decoration: underline;
  }
}
.link-hover-underline {
  text-decoration: none;
}
@media (min-width: 576px) {
  .link-hover-underline:hover {
    text-decoration: underline;
  }
}
@media (min-width: 576px) {
  .link-hover-blue:hover {
    color: #3564a0 !important;
  }
}
.link-blue-background {
  background-color: #eff3f9;
  color: #346eb8 !important;
  padding: 4px 16px;
  border-radius: 4px;
  transition-property: background-color, color;
  transition-duration: 0.15s;
}
.link-blue-background:hover {
  background-color: #3564a0;
  color: #fff !important;
}

.img-fluid {
  display: block;
  width: 100%;
}
.img-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-shrink: 0;
}
.img-center {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.img-center._logo-room, .img-center._logo-room-lg, .img-center._logo-room-xl {
  width: 40px;
  height: 40px;
  padding: 4px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
}
.img-center._logo-room-lg {
  width: 48px;
  height: 48px;
  padding: 5px;
}
.img-center._logo-room-xl {
  width: 56px;
  height: 56px;
  padding: 5px;
}
.img-center img,
.img-center svg {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.text {
  font-size: 15px;
  line-height: 20px;
}
.text._lg {
  font-size: 16px;
  line-height: 22px;
}
.text._sm {
  font-size: 14px;
}
.text._xsm {
  font-size: 13px;
  line-height: 18px;
}

.icon._18 {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.icon._20 {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.icon._24 {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.icon._28 {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.icon._32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.icon._40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.icon._48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.icon._80 {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.vs-line {
  width: 1px;
  height: 24px;
  flex-shrink: 0;
  margin: 0 12px;
  background: #e9e9e9;
  pointer-events: none;
}

.ps__rail-y {
  width: 8px;
  background: #f9f9f9 !important;
  opacity: 1 !important;
}
.ps__rail-x {
  height: 8px;
  background: #f9f9f9 !important;
  opacity: 1 !important;
}
.ps__thumb-y {
  background: #cdcdcd !important;
  width: 4px !important;
  right: 2px;
}
.ps__thumb-x {
  background: #cdcdcd !important;
  height: 4px !important;
  bottom: 2px;
}

.avatar {
  width: 48px;
  height: 48px;
  line-height: 47px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #e9e9e9;
  font-size: 22px;
}
.avatar:before {
  font-family: "icomoon" !important;
  flex-shrink: 0;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  content: "\e946";
  display: none;
}
.avatar:not([style]) {
  box-shadow: 0 0 0 1px #e9e9e9 inset;
}
.avatar:not([style]):before {
  display: block;
}
.avatar._letter {
  box-shadow: none;
}
.avatar._letter:before {
  display: none;
}
.avatar._32 {
  width: 32px;
  height: 32px;
  line-height: 31px;
  font-size: 16px;
}
.avatar._40 {
  width: 40px;
  height: 40px;
  line-height: 39px;
  font-size: 18px;
}
.avatar._48 {
  width: 48px;
  height: 48px;
  line-height: 47px;
  font-size: 20px;
}
.avatar._72 {
  width: 72px;
  height: 72px;
  line-height: 71px;
  font-size: 34px;
}
.avatar._88 {
  width: 88px;
  height: 88px;
  line-height: 87px;
  font-size: 38px;
}

.separator {
  position: relative;
  pointer-events: none;
}
.separator:before, .separator:after {
  content: "";
  position: absolute;
  top: 2px;
  height: 2px;
  width: calc(50% - 39px);
  background: linear-gradient(90deg, currentColor 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.3;
}
.separator:before {
  left: 0;
  transform: scaleX(-1);
}
.separator:after {
  right: 0;
}
.separator div {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: currentColor;
  margin: 0 auto;
}
.separator div:before, .separator div:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: currentColor;
  top: 0;
}
.separator div:before {
  left: -12px;
}
.separator div:after {
  left: 12px;
}

.swiper-pagination {
  position: static !important;
  padding: 0;
  line-height: 0;
}
.swiper-pagination-bullet {
  margin: 0 3px !important;
  opacity: 1;
  transition: background 0.2s;
  background: #cdcdcd;
}
@media (min-width: 576px) {
  .swiper-pagination-bullet:hover {
    background: #000;
  }
}
.swiper-pagination-bullet-active {
  background: #346eb8;
}
@media (min-width: 576px) {
  .swiper-pagination-bullet-active:hover {
    background: #346eb8;
  }
}
.swiper-pagination._green .swiper-pagination-bullet-active {
  background: #08A23B;
}
@media (min-width: 576px) {
  .swiper-pagination._green .swiper-pagination-bullet-active:hover {
    background: #08A23B;
  }
}
.swiper-button-prev, .swiper-button-next {
  position: static !important;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  margin: 0 1px 1px;
  width: auto;
  height: auto;
  color: #000;
}
.swiper-button-prev:before, .swiper-button-prev:after, .swiper-button-next:before, .swiper-button-next:after {
  display: none;
}
.swiper-button-prev .icon, .swiper-button-next .icon {
  transition: color 0.2s;
}
.swiper-button-prev .icon:before, .swiper-button-next .icon:before {
  display: block;
  transform: translateX(2px);
}
@media (min-width: 576px) {
  .swiper-button-prev:hover .icon, .swiper-button-next:hover .icon {
    color: #000 !important;
  }
}
.swiper-button-prev .icon {
  transform: scaleX(-1);
}

.tooltip {
  pointer-events: none;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 18px;
  color: #000;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  width: 0;
  transition: opacity 0.2s, margin 0.2s, width 0s 0.2s, font-size 0s 0.2s;
  margin-bottom: 15px;
  overflow: hidden;
  font-size: 0;
}
.tooltip:before, .tooltip:after {
  content: "";
  position: absolute;
  pointer-events: none;
  right: 50%;
  background: #fff;
}
.tooltip:before {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  bottom: -4px;
  margin-right: -5px;
  transform: rotate(45deg);
}
.tooltip:after {
  width: 20px;
  height: 10px;
  bottom: 0;
  margin-right: -10px;
}
.tooltip-trigger {
  position: relative;
  transition: color 0.2s;
  cursor: pointer;
}
.tooltip-trigger__body {
  position: relative;
}
.tooltip-trigger__body:after {
  content: "";
  position: absolute;
  pointer-events: none;
  border-bottom: 2px dashed currentColor;
  opacity: 0.24;
  bottom: -2px;
  left: 0;
  width: 100%;
}
@media (min-width: 576px) {
  .tooltip-trigger:hover {
    color: #000 !important;
  }
}
.tooltip-trigger:hover .tooltip {
  opacity: 1;
  width: 230px;
  margin-bottom: 5px;
  transition: opacity 0.2s, margin 0.2s, width 0s 0s, font-size 0s 0s;
  overflow: visible;
  font-size: 13px;
}

.notification {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08), 2px 0 0 0 currentColor inset;
  cursor: pointer;
  width: 320px;
}
.notification__wrap {
  position: fixed;
  right: 0;
  top: 120px;
  padding-top: 20px;
  padding-right: 20px;
  z-index: 2060;
  overflow: auto;
  max-height: calc(100vh - 120px);
}
.notification .icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 16px;
  font-size: 21px;
}

.category-badge {
  top: 16px;
  right: 16px;
}

.rating .icon {
  transition: color 0.2s;
}
@media (min-width: 576px) {
  .rating .icon:first-child:hover {
    color: #fb4d4e !important;
  }
}
@media (min-width: 576px) {
  .rating .icon:last-child:hover {
    color: #08a23b !important;
  }
}

.add-bookmark .icon {
  position: relative;
}
.add-bookmark .icon:after {
  font-family: "icomoon" !important;
  flex-shrink: 0;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  content: "\e92a";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}
.add-bookmark._active .icon:after {
  opacity: 1;
}
@media (min-width: 576px) {
  .add-bookmark:hover .icon:after {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s backwards;
}

.gradient-animation:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  background: linear-gradient(90deg, #F2F2F2 0%, #F9F9F9 33.33%, #F2F2F2 66.66%, #F9F9F9 100%);
  animation: translateN66 2s infinite;
}

.circle-progress {
  width: 18px;
  height: 18px;
  position: absolute;
}
.circle-progress:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 3px solid #e9e9e9;
  z-index: 1;
  border-radius: 50%;
}
.circle-progress div {
  width: 18px;
  height: 18px;
  position: absolute;
  clip: rect(0px, 18px, 18px, 9px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: 0.01s;
  animation-delay: 1s;
  animation-name: closeWrapper;
  pointer-events: none;
  flex-shrink: 0;
  z-index: 2;
}
.circle-progress div:before, .circle-progress div:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 18px;
  height: 18px;
  border: 3px solid #08a23b;
  border-radius: 50px;
  clip: rect(0px, 9px, 18px, 0px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.circle-progress div:before {
  animation-duration: 2s;
  animation-name: leftSpin;
}
.circle-progress div:after {
  animation-duration: 1s;
  animation-name: rightSpin;
}

.slide-trigger .icon:before {
  display: block;
  transition: transform 0.2s;
  transform: scaleY(-1);
}
.slide-trigger[data-status=show] .icon:before {
  transform: scaleY(1);
}
.slide-trigger__body[data-status=show] {
  overflow: visible !important;
}
.slide-trigger__body[data-status=hide] {
  display: none !important;
}

.list {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  list-style: none;
}
.list li {
  position: relative;
  padding-left: 40px;
  min-height: 32px;
}
.list li + li {
  margin-top: 16px;
}
.list li:before {
  font-family: "icomoon" !important;
  flex-shrink: 0;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: absolute;
  width: 32px;
  font-size: 21px;
  top: 0;
  left: 0;
  pointer-events: none;
}
.list._bullet li:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  top: 9px;
  left: 14px;
  background: currentColor;
}
.list._check li:before {
  content: "\e943";
  color: #08a23b;
}
.list._blocked li:before {
  content: "\e937";
  color: #fb4d4e;
}
.list._star li:before {
  content: "\e92e";
  font-size: 24px;
  color: #ebcb24;
}
.list._ordered {
  counter-reset: list 0;
}
.list._ordered li {
  padding-left: 42px;
  padding-top: 5px;
}
.list._ordered li:before {
  content: counter(list);
  counter-increment: list;
  color: #000;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-top: 6px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #000;
}

.table thead th {
  border: 0;
  vertical-align: middle;
  padding: 20px 16px;
}
.table tbody td {
  vertical-align: middle;
  border: 0;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 16px;
}
.table-bordered {
  border-collapse: separate;
  border-spacing: 1px;
  border: 0;
}
.table-bordered th,
.table-bordered td {
  border: 0;
}
.table-rounded tbody tr:first-child td:first-child {
  border-top-left-radius: 4px;
}
.table-rounded tbody tr:first-child td:last-child {
  border-top-right-radius: 4px;
}
.table-rounded tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}
.table-rounded tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.table-rounded thead tr:first-child th:first-child {
  border-top-left-radius: 4px;
}
.table-rounded thead tr:first-child th:last-child {
  border-top-right-radius: 4px;
}
.table-rounded thead + tbody tr:first-child td:first-child {
  border-top-left-radius: 0;
}
.table-rounded thead + tbody tr:first-child td:last-child {
  border-top-right-radius: 0;
}

.social-services {
  display: flex;
  align-items: flex-start;
  margin: 0 -8px;
  flex-shrink: 0;
}
.social-services__icon {
  flex-shrink: 0;
  position: relative;
  margin: 0 8px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  font-size: 17px;
  transition: background 0.2s;
}
.social-services__icon.icon-facebook {
  background: #3b5998;
}
@media (min-width: 576px) {
  .social-services__icon.icon-facebook:hover {
    background: #355089;
  }
}
.social-services__icon.icon-vk {
  background: #4c75a3;
}
@media (min-width: 576px) {
  .social-services__icon.icon-vk:hover {
    background: #446993;
  }
}
.social-services__icon.icon-telegram {
  background: #08c;
}
@media (min-width: 576px) {
  .social-services__icon.icon-telegram:hover {
    background: #007ab8;
  }
}
.social-services__icon.icon-ok {
  background: #f97400;
}
@media (min-width: 576px) {
  .social-services__icon.icon-ok:hover {
    background: #e06800;
  }
}
.social-services__icon.icon-youtube {
  background: #c4302b;
}
@media (min-width: 576px) {
  .social-services__icon.icon-youtube:hover {
    background: #b02b27;
  }
}
.social-services__icon.icon-insta {
  background: #3f729b;
}
@media (min-width: 576px) {
  .social-services__icon.icon-insta:hover {
    background: #39678c;
  }
}
.social-services__icon.icon-skype {
  background: #00aff0;
}
@media (min-width: 576px) {
  .social-services__icon.icon-skype:hover {
    background: #009ed8;
  }
}
.social-services__icon.icon-twitter {
  background: #00acee;
}
@media (min-width: 576px) {
  .social-services__icon.icon-twitter:hover {
    background: #009bd6;
  }
}
.social-services__icon.icon-whatsapp {
  background: #25d366;
}
@media (min-width: 576px) {
  .social-services__icon.icon-whatsapp:hover {
    background: #21be5c;
  }
}
.social-services__icon.icon-mail {
  background: #346eb8;
}
@media (min-width: 576px) {
  .social-services__icon.icon-mail:hover {
    background: #2f63a6;
  }
}
.social-services__icon.icon-chat {
  background: #08a23b;
}
@media (min-width: 576px) {
  .social-services__icon.icon-chat:hover {
    background: #079235;
  }
}
.social-services__icon.icon-www {
  background: #346eb8;
}
@media (min-width: 576px) {
  .social-services__icon.icon-www:hover {
    background: #2f63a6;
  }
}
.social-services__icon.icon-telegram:before {
  display: block;
  transform: translateX(-1px);
}
.social-services__icon.icon-www {
  font-size: 140%;
}
.social-services__icon .abs-link {
  border-radius: 50%;
}
.social-services__item {
  display: flex;
  align-items: center;
}
.social-services__text {
  font-size: 14px;
}
.social-services._list {
  display: block;
  margin: 0;
}
.social-services._list .social-services__item + .social-services__item {
  margin-top: 16px;
}
.social-services._list .social-services__icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 95%;
  margin: 0 8px 0 0;
}
.social-services._square .social-services__icon {
  border-radius: 4px;
}
.social-services._40 .social-services__icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 140%;
}
.social-services._40 .social-services__icon.icon-www {
  font-size: 170%;
}
.social-services._32 .social-services__icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 130%;
}
.social-services._32 .social-services__icon.icon-www {
  font-size: 160%;
}
.social-services._p4 {
  margin: 0 -4px;
}
.social-services._p4 .social-services__icon {
  margin: 0 4px;
}
.social-services-slider .social-services {
  min-width: 108px;
}
.social-services-slider .social-services[data-status=hide] {
  width: 0;
}
.social-services-slider .js-slide:before {
  display: block;
  transform: translateX(1px);
  transition: all 0.2s;
}
.social-services-slider .js-slide[data-status=show]:before {
  transform: translateX(-1px) scaleX(-1);
}

.form-control {
  color: #000;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 4px;
  transition: color 0.2s, border-color 0.2s, box-shadow 0.2s, background 0.2s;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14) inset;
}
.form-control::-webkit-input-placeholder {
  color: #aaa;
}
.form-control::-moz-placeholder {
  color: #aaa;
}
.form-control:-moz-placeholder {
  color: #aaa;
}
.form-control:-ms-input-placeholder {
  color: #aaa;
}
.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #e9e9e9;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14) inset;
}
.form-control:not(:placeholder-shown):not(:focus) {
  background-color: #fafafa;
}
.form-control._no-shadow {
  box-shadow: none;
}
.form-control._error {
  border-color: #fb4d4e;
  box-shadow: none;
}
.form-control._disabled, .form-control[disabled] {
  background-color: #fafafa;
  color: #6c6b6b;
  box-shadow: none;
}
.form-input {
  position: relative;
  min-height: 62px;
  margin-bottom: 5px;
}
.form-input__label {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.form-input__error {
  display: none;
  padding-top: 42px;
  z-index: 2;
}
.form-input__link {
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 3;
}
.form-input__link + .form-control {
  padding-right: 90px;
}
.form-input__list-item {
  transition: color 0.2s;
  margin-top: 12px;
  cursor: pointer;
}
.form-input__list-item:first-child {
  margin-top: 0;
}
@media (min-width: 576px) {
  .form-input__list-item:hover {
    color: #346eb8;
  }
}
.form-input .form-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.form-input .form-control._error ~ .form-input__error {
  display: block;
}
.form-input textarea.form-control {
  height: 118px !important;
  padding: 16px;
}
.form-input_textarea {
  min-height: 140px;
}
.form-input_textarea .form-input__error {
  padding-top: 120px;
}
.form-input._lg {
  min-height: 78px;
}
.form-input._lg .form-control {
  height: 56px;
  padding: 0 16px;
  font-size: 15px;
}
.form-input._lg .form-input__error {
  padding-top: 58px;
}
.form-input._lg .form-input__link {
  top: 18px;
  right: 16px;
}
.form-input._lg .form-input__link + .form-control {
  padding-right: 100px;
}
.form-input._dropdown .icon-chevron-top {
  width: 20px;
  height: 20px;
  line-height: 18px;
  top: 10px;
  right: 10px;
  font-size: 10px;
  transform: scaleY(-1);
  will-change: transform;
  transition: transform 0.2s;
  position: absolute;
  pointer-events: none;
  z-index: 3;
}
.form-input._dropdown .img-center {
  width: 24px;
  height: 24px;
  padding: 2px;
  margin-right: 10px;
}
.form-input._dropdown .form-control {
  padding-right: 46px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.form-input._dropdown .form-control._disabled {
  background-color: #fafafa;
  cursor: default;
  pointer-events: none;
}
.form-input._dropdown .form-control._disabled ~ .icon.icon-chevron-top {
  display: none;
}
.form-input._dropdown .popover {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  top: 48px;
  max-width: none;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  max-height: 496px;
}
.form-input._dropdown._lg .icon-chevron-top {
  width: 32px;
  height: 32px;
  line-height: 30px;
  top: 12px;
  right: 12px;
  font-size: 16px;
}
.form-input._dropdown._lg .img-center {
  width: 32px;
  height: 32px;
  padding: 3px;
  margin-right: 8px;
}
.form-input._dropdown._lg .form-control {
  padding-right: 56px;
}
.form-input._dropdown._lg .popover {
  top: 64px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 15px;
}
.form-input._dropdown._open-popover .icon-chevron-top {
  transform: scaleY(1);
}
.form-checkbox input {
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  height: 1px;
  width: 1px;
}
.form-checkbox input:checked ~ .form-checkbox__check .icon {
  opacity: 1;
}
.form-checkbox .icon {
  transition: opacity 0.2s;
  opacity: 0;
}
.form-checkbox .text {
  transition: color 0.2s;
}
@media (min-width: 576px) {
  .form-checkbox:hover .text {
    color: #3564a0;
  }
}

.rating-counter {
  display: flex;
  align-items: center;
}
.rating-counter .icon {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  color: #aaa;
  cursor: pointer;
  box-shadow: 0 0 0 1px #e9e9e9 inset;
  transition: color 0.2s;
}
@media (min-width: 576px) {
  .rating-counter .icon-minus:hover {
    color: #fb4d4e;
  }
}
@media (min-width: 576px) {
  .rating-counter .icon-plus:hover {
    color: #08a23b;
  }
}
.rating-counter .text {
  margin: 0 8px;
  flex-shrink: 0;
  color: #6c6b6b;
}
.rating-counter._disabled .icon {
  pointer-events: none;
  opacity: 0;
}

.tip__btn {
  margin-left: 84px;
}

.tabs {
  background: #fff;
  display: flex;
  position: relative;
  align-items: center;
}

.tab {
  position: relative;
  min-height: 60px;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 20px;
  color: #aaa;
  letter-spacing: 1.5px;
}
@media (min-width: 576px) {
  .tab:hover .tab__text {
    color: #000 !important;
  }
}
.tab input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.tab input:checked ~ .tab__text {
  color: #000 !important;
}
.tab input:checked ~ .tab__text:before {
  transform: translateY(0);
}
.tab__text {
  transition: color 0.2s;
}
.tab__text:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #346eb8;
  pointer-events: none;
  transform: translateY(-100%);
  transition: transform 0.2s;
}
.tab._sm {
  min-height: 40px;
  font-size: 12px;
  padding: 5px 16px;
}
.tab._sm .tab__text:before {
  display: none;
}
.tab__content:not(._active) {
  display: none !important;
}
.tab-rubrics {
  position: relative;
}
.tab-rubrics._open-popover .tab-rubrics__btn._shadow {
  box-shadow: none;
  border-color: #aaa;
  color: #000;
}
.tab-rubrics._open-popover .tab-rubrics__btn._blue {
  color: #fff;
  background: #3564a0;
}
.tab-rubrics__popover {
  top: 49px !important;
}

.notifications__item {
  border-top: 1px solid #e9e9e9;
  padding: 12px 16px;
  display: flex;
}
.notifications__item:first-child {
  border-top: 0;
}
.notifications__item .avatar .icon {
  width: 16px;
  height: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: -8px;
  pointer-events: none;
  margin-top: -8px;
  font-size: 8px;
  font-weight: 700;
}

.search-result__item {
  padding: 20px 16px;
  border-top: 1px solid #e9e9e9;
}
.search-result__item:first-child {
  border-top: 0;
}
.search-result__item a.link-underline {
  color: #346eb8;
}
@media (min-width: 576px) {
  .search-result__item a.link-underline:hover {
    color: #000;
  }
}

.reviews__item {
  padding: 24px;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
}
.reviews__item + .reviews__item {
  margin-top: 10px;
}

.table-cards__head {
  border: 1px solid #eff3f9;
}
.table-cards__head [class*=table-cards__w] {
  border-right: 1px solid #eff3f9;
}
.table-cards__w96 {
  width: 96px;
}
.table-cards__w120 {
  width: 120px;
}
.table-cards__w186 {
  width: 186px;
}
.table-cards__w222 {
  width: 222px;
}
.table-cards__w230 {
  width: 230px;
}
.table-cards__w240 {
  width: 240px;
}
.table-cards__w278 {
  width: 278px;
}
.table-cards__w330 {
  width: 330px;
}
.table-cards__w340 {
  width: 340px;
}
.table-cards__collapsed[data-status=hide] {
  display: none !important;
}
.table-cards__collapsed[data-status=show] {
  overflow: visible !important;
}
.table-cards__slide-trigger .icon {
  transition: all 0.2s;
}
.table-cards__slide-trigger[data-status=hide] .text-status-hide {
  display: block !important;
}
.table-cards__slide-trigger[data-status=hide] .icon {
  transform: scaleY(-1);
}
.table-cards__slide-trigger[data-status=show] {
  background: #eff3f9;
}
@media (min-width: 576px) {
  .table-cards__slide-trigger[data-status=show]:hover {
    background: #e6ecf6;
  }
}
.table-cards__slide-trigger[data-status=show] .text-status-show {
  display: block !important;
}
.table-cards__slide-trigger[data-status=show] .icon {
  color: #346eb8;
}
.table-cards__item:not([data-state=ready]) [data-state=ready] {
  display: none !important;
}
.table-cards__item:not([data-state=pending]) [data-state=pending] {
  display: none !important;
}
.table-cards__item[data-state=ready] [data-state=initial] {
  display: none !important;
}
.table-cards__item[data-state=pending] [data-state=initial] {
  display: none !important;
}
.table-cards__item[data-state=pending] [data-state=ready] {
  display: none !important;
}
.table-cards__item .gradient-animation {
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
}
.table-cards [class*=table-cards__w] {
  flex-shrink: 0;
}

.scroll-content__title {
  top: 0;
  left: 0;
  z-index: 5;
}

.comments .tab {
  flex: auto;
  width: 120px;
  flex-shrink: 0;
  padding: 0;
}

.comment {
  transition: background 0.2s;
}
.comment__action {
  transition: color 0.2s;
}
.comment__action > * {
  transition: color 0.2s;
}
@media (min-width: 576px) {
  .comment__action:hover {
    color: #000 !important;
  }
  .comment__action:hover > * {
    color: #000 !important;
  }
}
.comment__img {
  max-width: 426px;
}
.comment__img .icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.comment-indent {
  border-left: 1px dashed #cdcdcd;
}
.comment-input__bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  transition: color 0.2s, border-color 0.2s, box-shadow 0.2s, background 0.2s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14) inset;
  z-index: 1;
}
.comment-input .form-control {
  box-shadow: none !important;
  background: transparent !important;
  min-height: 164px;
  padding: 16px;
  font-size: 16px;
  z-index: 3;
  border-color: transparent;
  overflow: auto;
}
.comment-input .form-control:before {
  content: "Написать комментарий...";
  display: inline;
  color: #aaa;
  pointer-events: none;
}
.comment-input .form-control:focus:before, .comment-input .form-control:not(:empty):before {
  display: none;
}
.comment-input .form-control:not(:empty):not(:focus) ~ .comment-input__bg {
  background-color: #fafafa;
}
.comment-input .form-control._error ~ .comment-input__bg {
  border-color: #fb4d4e;
  box-shadow: none;
}
.comment-input .form-control._disabled ~ .comment-input__bg, .comment-input .form-control[disabled] ~ .comment-input__bg {
  background-color: #fafafa;
  color: #6c6b6b;
  box-shadow: none;
}
.comment-input-media {
  z-index: 2;
  max-width: 810px;
  overflow: auto;
  margin-left: 1px;
}
.comment-input-media:after {
  content: "";
  display: block;
  width: 28px;
  flex-shrink: 0;
  height: 1px;
  pointer-events: none;
}
.comment-input-media ~ .form-control {
  min-height: 100px;
}
.comment-input-media__item {
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.comment-input-media__item + .comment-input-media__item {
  margin-left: 28px;
}
.comment-input-media__icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.comment-input-media__remove {
  z-index: 5;
  top: -12px;
  right: -12px;
}
.comment-input__replied {
  z-index: 2;
}
.comment-input .ps__rail-x {
  background: transparent !important;
}
.comment-controls .icon {
  transition: color 0.2s;
}
@media (min-width: 576px) {
  .comment-controls .icon:hover {
    color: #000 !important;
  }
}
.comment-controls .icon input {
  top: 0;
  left: 0;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.comment-controls .btn {
  min-width: 0;
}

.faq {
  transition: background 0.2s;
}
.faq[data-status=show] {
  background: #fafafa !important;
}
.faq[data-status=show] .faq__title {
  font-weight: 500;
}
.faq[data-status=show] .faq__icon:before {
  transform: rotate(0deg);
}
.faq[data-status=show] .faq__icon:after {
  opacity: 0;
  transform: rotate(90deg);
}
.faq__icon {
  top: 22px;
  right: 14px;
}
.faq__icon:before, .faq__icon:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: #aaa;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -6px;
  transition: all 0.2s;
  transform: rotate(90deg);
}
.faq__icon:after {
  transform: rotate(180deg);
}
.faq__title {
  min-height: 40px;
}
@media (min-width: 576px) {
  .faq__title:hover + .faq__icon:before, .faq__title:hover + .faq__icon:after {
    background: #000;
  }
}
.faq__body[data-status=show] {
  overflow: visible !important;
}
.faq__body[data-status=hide] {
  display: none !important;
}

.hover-img {
  position: relative;
  z-index: 3;
  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
}

.hover-img:hover {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
  background-color: #fafafa !important;
}

@media (min-width: 576px) {
  .table thead th:first-child {
    border-radius: 4px 0 0 4px;
  }
  .table thead th:last-child {
    border-radius: 0 4px 4px 0;
  }
  .table-cards__container {
    transition: all 0.2s;
  }
  .table-cards__container[data-status=show] {
    background-color: #fafafa !important;
    box-shadow: 0 0 0 1px #e9e9e9 !important;
  }
  .table-cards__item {
    min-height: 80px;
  }
  .table-cards__item[class*=c-bg-] [class*=table-cards__w] {
    background: none !important;
  }
  .scroll-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .scroll-content-static {
    max-height: 620px;
    position: relative;
  }
  .comments .tabs {
    margin-right: -24px;
  }
  .comment .rating {
    position: absolute;
    right: 0;
    top: 2px;
  }
  .comment._answered {
    background: #fafafa;
    padding: 12px 8px 8px;
    margin: -12px -8px 16px -8px !important;
  }
  .comment._answered .rating {
    top: 14px;
    right: 8px;
  }
  .comment-controls .btn._blue {
    min-width: 220px !important;
  }
  .comment-controls .btn._shadow {
    min-width: 120px !important;
  }
}
@media (max-width: 575.98px) {
  .btn._sm {
    min-height: 40px;
  }
  .vs-line {
    height: 24px;
  }
  .separator:before, .separator:after {
    width: calc(50% - 23px);
  }
  .tooltip {
    line-height: 20px;
  }
  .tooltip:before, .tooltip:after {
    display: none;
  }
  .tooltip-trigger:hover .tooltip {
    width: 100%;
  }
  .notification__wrap {
    top: 50px;
    z-index: 1050;
  }
  .notification .icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 8px;
    font-size: 16px;
  }
  .table thead th {
    padding: 10px 16px;
  }
  .table tbody td {
    padding: 10px 16px;
  }
  .swiper-container .tooltip {
    transform: translateX(-75%);
  }
  .swiper-container .tooltip-trigger:hover .tooltip {
    width: 200%;
  }
  .swiper-steps-phone .swiper-pagination {
    margin-top: 20px;
    height: 6px;
  }
  .swiper-steps-phone .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: #e9e9e9;
  }
  .swiper-steps-phone .swiper-pagination-bullet-active {
    background: #346eb8;
  }
  .social-services-slider {
    width: 100%;
  }
  .form-control {
    font-size: 16px;
  }
  .form-input {
    min-height: 40px;
    margin-bottom: 20px;
  }
  .form-input_textarea {
    min-height: 118px;
  }
  .form-input._dropdown .form-control {
    font-size: 14px;
  }
  .form-input._dropdown .popover {
    overflow: auto;
    max-height: 276px;
  }
  .form-input._dropdown._lg {
    margin-bottom: 5px;
  }
  .form-input._dropdown._lg .form-control {
    font-size: 15px;
  }
  .tip__btn {
    margin-left: 0;
  }
  .tabs._phone-list {
    display: block;
  }
  ._phone-list .tab {
    min-height: 0;
    padding: 10px 16px;
  }
  ._phone-list .tab:first-child {
    padding-top: 20px;
  }
  ._phone-list .tab:last-child {
    padding-bottom: 20px;
  }
  ._phone-list .tab .tab__text:before {
    display: none;
  }
  ._phone-list .tab._sm {
    padding: 5px 16px;
  }
  ._phone-list .tab._sm:first-child {
    padding-top: 10px;
  }
  ._phone-list .tab._sm:last-child {
    padding-bottom: 10px;
  }
  .notifications__item {
    padding: 16px 20px;
  }
  .notifications__item:first-child {
    padding-top: 0;
  }
  .search-result__item {
    padding: 16px 20px;
    font-size: 15px !important;
    line-height: 20px;
  }
  .reviews__item {
    padding: 20px;
  }
  .table-cards {
    background: none !important;
  }
  .table-cards__container, .table-cards__item {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .table-cards__collapsed .table-cards__item {
    background-color: transparent !important;
  }
  .table-cards__item .gradient-animation {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .table-cards [class*=table-cards__w] {
    width: 100%;
    background: none !important;
    position: relative;
  }
  .table-cards .tooltip-trigger {
    position: static;
  }
  .scroll-content,
  .scroll-content-static {
    position: relative;
    overflow: auto;
    max-height: 450px;
  }
  .comments .tab {
    padding: 0;
    width: auto;
    flex-shrink: 0;
  }
  .comments .tab:first-child {
    margin-right: 12px;
  }
  .comment-input-media ~ .form-control {
    min-height: 180px;
  }
}
.content-layout-small {
  max-width: 700px;
  margin: 0 auto;
}

.btn-4-16 {
  padding: 4px 16px;
  user-select: none;
  transition-property: background-color, white;
  transition-duration: 0.15s;
}
.btn-4-16:hover {
  background-color: #3564a0 !important;
  color: #fff !important;
}

.bpr-card {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1411764706);
  position: relative;
}
.bpr-card-rating {
  position: absolute;
  top: 16px;
  right: 16px;
}
.bpr-card-country {
  padding-left: 4px;
}
.bpr-card-country div.width-min-content {
  width: min-content;
}
.bpr-card-country svg {
  width: 18px;
  height: 18px;
}
.bpr-card-promo {
  align-self: flex-end;
  cursor: pointer;
}
.bpr-card-container {
  padding-bottom: 16px;
}
.bpr-card-container .bpr-card:not(:last-child) {
  margin-bottom: 16px;
}

.banner {
  overflow: hidden;
  transition-property: outline;
  transition-duration: 0.15s;
  position: relative;
  outline: 3px solid transparent;
  cursor: pointer;
}
.banner.w-380 {
  width: 380px;
  max-width: 380px;
  min-width: 380px;
  padding: 16px;
}
.banner.w-280 {
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  padding: 12px;
}
.banner-icon-container.w-48 {
  padding: 8px;
}
.banner-icon-container.w-48 .banner-icon {
  width: 48px;
  height: 48px;
}
.banner-icon-container.w-42 {
  padding: 7px;
  margin-right: 12px;
}
.banner-icon-container.w-42 .banner-icon {
  width: 42px;
  height: 42px;
}
.banner.c-bg-pgreen:hover {
  outline: 3px solid #15873B;
}
.banner.c-bg-white:hover {
  outline: 3px solid #AAAAAA;
}

@media (max-width: 575.98px) {
  .banner {
    overflow: hidden;
    transition-property: background-color;
    transition-duration: 0.15s;
    position: relative;
  }
  .banner-container .banner:not(:last-child) {
    margin-bottom: 10px;
  }
  .banner.w-380 {
    width: 100%;
    max-width: initial;
    min-width: initial;
    padding: 16px;
  }
  .banner.w-280 {
    width: 100%;
    max-width: initial;
    min-width: initial;
    padding: 12px;
  }
}
.freerolls-filter-button {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #FFF;
  border: 1px solid #e9e9e9;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  color: #000000;
}
.freerolls-filter-button:hover {
  border: 1px solid #EBF8EF;
  background: #EBF8EF;
  color: #000000;
}
.freerolls-filter-button._active {
  background: #EBF8EF;
  border: 1px solid #EBF8EF;
  color: #08A23B;
}

.draggable-widgets {
  position: sticky;
  top: 70px;
  margin-bottom: 20px;
  z-index: 10;
}

@media (max-width: 575.98px) {
  .html_content .table th {
    opacity: 0.5;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 0 !important;
  }
  .html_content .table td {
    padding: 16px 0 !important;
    vertical-align: top;
  }
  .html_content .table td:last-child {
    max-width: 70px;
  }
  .html_content .table td:first-child {
    max-width: 210px;
    padding-right: 40px !important;
  }
}
@media (max-width: 575.98px) and (max-width: 641px) {
  .html_content .table {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
  }
  .html_content .table th:not(:first-child), .html_content .table td:not(:first-child) {
    padding: 16px !important;
  }
}
.spoiler-box-text {
  color: #346EB8;
  border-bottom: 2px solid rgba(52, 110, 184, 0.24);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  transition: 0.1s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.spoiler-box-text:hover {
  color: #3564A0;
  border-bottom: 2px solid transparent;
}

.spoiler-box-text.active {
  color: #444444;
  border-bottom: 2px solid transparent;
}

.spoiler-box-inner {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.spoiler-box-inner img {
  width: 100%;
  height: 100%;
}

.spoiler-box-inner.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
  margin-top: 10px;
}

.post-card {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 248px;
}
.post-card:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
@media (min-width: 576px) {
  .post-card:hover .post-card__bookmark {
    opacity: 1;
  }
  .post-card:hover .post-card__bookmark ~ .post-card__icon {
    transform: translateX(-48px);
  }
}
.post-card__link {
  z-index: 4;
}
.post-card__bookmark {
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: all 0.2s;
  border: 1px solid #ebcb24;
  background: rgba(235, 203, 36, 0.16);
  color: #ebcb24;
  cursor: pointer;
  z-index: 5;
}
.post-card__bookmark:after {
  content: "\e92a";
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.post-card__bookmark._active {
  opacity: 1;
}
.post-card__bookmark._active:after {
  opacity: 1;
}
.post-card__bookmark._active ~ .post-card__icon {
  transform: translateX(-48px);
}
@media (min-width: 576px) {
  .post-card__bookmark:hover {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.16);
    color: #fff;
  }
}
.post-card__icon {
  top: 16px;
  right: 16px;
  transition: transform 0.2s;
  z-index: 3;
}
.post-card__body {
  z-index: 5;
}
.post-card__category {
  z-index: 5;
}
.post-card a.badge {
  color: #fff;
  transition: color 0.2s;
  background: rgba(235, 203, 36, 0.16);
}
@media (min-width: 576px) {
  .post-card a.badge:hover {
    color: #ebcb24;
  }
}

@media (min-width: 576px) {
  .post-card._lg {
    min-height: 380px;
  }
  .post-card._sm {
    min-height: 193px;
  }
  .post-row .post-card:before, .post-row .post-card__bookmark, .post-row .post-card__icon, .post-row .post-card__body,
  .post-row .post-card a.badge {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .post-card {
    min-height: 236px;
  }
  .post-card:before {
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.24) 100%);
  }
  .post-card__bookmark {
    opacity: 1;
  }
  .post-card__bookmark ~ .post-card__icon {
    transform: translateX(-48px);
  }
  .post-card__body {
    z-index: 2;
  }
}
@media (min-width: 576px) {
  .rooms-category:hover .text {
    opacity: 1;
  }
  .rooms-category:hover .text:after {
    color: #3564a0;
  }
}
.rooms-category input {
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  height: 1px;
  width: 1px;
}
.rooms-category input:checked + .d-flex img,
.rooms-category input:checked + .d-flex .text {
  opacity: 1;
}
.rooms-category input:checked + .d-flex .text:after {
  font-weight: 500;
  color: #000 !important;
}
.rooms-category img,
.rooms-category .text {
  transition: opacity 0.2s;
  opacity: 0.7;
}
.rooms-category .text {
  color: #fff;
}
.rooms-category .text:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  font-weight: normal;
  transition: color 0.2s;
}

.room-card {
  transition: all 0.2s;
  border: 1px solid #e9e9e9;
}
@media (min-width: 576px) {
  .room-card:hover {
    border-color: transparent;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  }
}
.room-item-head {
  border-radius: 4px 4px 0 0;
}
.room-item-head__icon {
  top: 12px;
  left: 12px;
}
.room-item-scale__label._top:before {
  bottom: -5px;
}
.room-item-scale__label._top:after {
  bottom: -4px;
}
.room-item-scale__label._bottom:before {
  top: -5px;
}
.room-item-scale__label._bottom:after {
  top: -4px;
}
.room-item-scale__label:before, .room-item-scale__label:after {
  content: "";
  position: absolute;
  pointer-events: none;
  right: 50%;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: -5px;
  transform: rotate(45deg);
}
.room-item-scale__label:before {
  background: #e9e9e9;
}
.room-item-scale__label:after {
  background: #fff;
}
.room-item-scale__gradient {
  background: linear-gradient(90deg, #08A23B 1.3%, #EBCB24 49.11%, #EBCB24 50.65%, #FB4D4E 100%);
}
.room-item-scale__gradient:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 50%, #fff 50%);
  background-size: 4px 4px;
}
.room-item__bonus {
  border-style: dashed !important;
}
.room-fixed-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  will-change: transform;
  transition: transform 0.3s;
  transform: translateY(-100%);
  z-index: 2001;
  backdrop-filter: blur(2px);
}
.room-task:not([data-state=ready]) [data-state=ready] {
  display: none !important;
}
.room-task:not([data-state=pending]) [data-state=pending] {
  display: none !important;
}
.room-task[data-state=ready] [data-state=initial] {
  display: none !important;
}
.room-task[data-state=pending] [data-state=initial] {
  display: none !important;
}
.room-task[data-state=pending] [data-state=ready] {
  display: none !important;
}
.room-task[data-state=pending] tr:first-child td, .room-task[data-state=pending] tr:last-child td, .room-task[data-state=ready] tr:first-child td, .room-task[data-state=ready] tr:last-child td {
  background: #fafafa;
}
.room-task .gradient-animation {
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
}
.room__tabs {
  box-shadow: 0 -1px 0 0 #e9e9e9 inset;
}
.room__tabs .tab {
  min-height: 0;
  flex: none;
  display: block;
  padding: 0 0 8px;
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  color: #6c6b6b;
  letter-spacing: 0;
}
.room__tabs .tab + .tab {
  margin-left: 24px;
}
@media (min-width: 576px) {
  .room__tabs .tab:hover input:not(:checked) ~ .tab__text {
    color: #3564a0 !important;
  }
}
.room__tabs .tab__text:before {
  top: auto;
  bottom: 0;
  height: 2px;
  transform: translateY(100%);
}

@media (max-width: 575.98px) {
  .room-item {
    box-shadow: none !important;
  }
  .room-item-scale .tooltip-trigger {
    position: static;
  }
}
.profile__avatar {
  width: 160px;
  height: 160px;
}
.profile__balance {
  min-width: 250px;
}

.wallet-output {
  margin-top: 12px;
}
.wallet-output__icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  font-size: 21px;
}
.wallet-output__text {
  font-size: 14px;
  line-height: 20px;
  padding-top: 7px;
}
.wallet-history__row {
  min-height: 72px;
  padding: 14px 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  margin-top: -1px;
}
.wallet-history__date {
  text-align: center;
  width: 80px;
  margin-right: 60px;
  font-size: 14px;
  line-height: 20px;
}
.wallet-history__time {
  color: #6c6b6b;
}
.wallet-history__logo {
  width: 200px;
  margin-right: 18px;
}
.wallet-history__logo-room {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding: 0;
}
.wallet-history__logo .img-center {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  padding: 3px;
}
.wallet-history__out {
  width: 300px;
}
.wallet-history__out .icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 20px;
  margin-right: 8px;
}
.wallet-history__out .icon.c-green {
  transform: scaleY(-1);
}
.wallet-history__out .text {
  color: #444;
}
.wallet-history__sum {
  text-align: right;
  line-height: 20px;
  margin-left: auto;
}
.wallet-history__val {
  font-size: 15px;
}
.wallet-history__status {
  font-size: 14px;
  margin-top: 4px;
}
.wallet-history__more {
  text-align: center;
  margin-top: 24px;
}

@media (min-width: 576px) {
  .wallet-output {
    margin-bottom: -26px;
  }
}
@media (max-width: 575.98px) {
  .profile__avatar {
    width: 140px;
    height: 140px;
  }
  .wallet-output {
    margin-top: 16px;
  }
  .wallet-history__row {
    padding: 16px 0;
    flex-wrap: wrap;
  }
  .wallet-history__date {
    text-align: left;
    width: 100%;
    margin-right: 0;
    color: #444 !important;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .wallet-history__day {
    flex-shrink: 0;
    margin-right: 3px;
  }
  .wallet-history__day:after {
    content: ", ";
  }
  .wallet-history__time {
    color: #444 !important;
  }
  .wallet-history__logo {
    width: auto;
  }
  .wallet-history__logo .text {
    font-size: 14px !important;
  }
  .wallet-history__out {
    width: 100%;
    order: -1;
    margin-bottom: 16px;
  }
  .wallet-history__out .text {
    color: #000;
    font-size: 14px !important;
  }
}
.popover {
  left: auto;
  right: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  border: 0;
  transition: opacity 0.2s, margin-top 0.2s, transform 0s 0.2s;
  opacity: 0;
  pointer-events: none;
  margin-top: 10px;
  max-width: 440px;
}
.popover:before, .popover:after {
  content: "";
  position: absolute;
  pointer-events: none;
  right: 30px;
  background: #fff;
}
.popover:before {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  top: -4px;
  margin-right: -5px;
  transform: rotate(45deg);
}
.popover:after {
  width: 20px;
  height: 10px;
  top: 0;
  margin-right: -10px;
}
.popover__head {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  min-height: 44px;
  border-bottom: 1px solid #e9e9e9;
}
.popover__body {
  padding: 16px;
}
.popover-rubrics {
  padding: 0 16px;
  min-width: 168px;
  overflow: auto;
  max-height: 494px;
  position: relative;
  z-index: 10;
}
.popover-rubrics__item {
  padding: 10px 0;
  position: relative;
  border-top: 1px solid #e9e9e9;
}
.popover-rubrics__item:before {
  content: "";
  position: absolute;
  pointer-events: none;
  left: -16px;
  height: 40px;
  width: 4px;
  background: #346eb8;
  transition: transform 0.2s;
  transform: translateX(-100%);
  top: 50%;
  margin-top: -20px;
}
.popover-rubrics__item:first-child {
  border-top: 0;
}
@media (min-width: 576px) {
  .popover-rubrics__item:hover .text {
    color: #346eb8 !important;
  }
}
.popover-rubrics__item._active:before {
  transform: translateX(0);
}
.popover-rubrics__item._active .text {
  font-weight: 500;
  color: #000 !important;
}
.popover-rubrics .img-cover {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.popover-rubrics .text {
  transition: color 0.2s;
}
.popover-search {
  position: relative;
}
.popover-search__input {
  height: 56px;
  width: 485px;
  padding: 0 140px 0 16px;
  font-size: 15px;
  background: #fafafa;
}
.popover-search__input:focus {
  background: #fff;
}
.popover-search__submit {
  position: absolute;
  width: 120px;
  top: 8px;
  right: 8px;
}
.popover._center {
  right: 50%;
}
.popover._center:before, .popover._center:after {
  right: 50%;
}
.popover._no-arrow:before, .popover._no-arrow:after {
  display: none;
}
.popover .notifications {
  position: relative;
  max-height: calc(100vh - 150px);
}
.popover .search-result {
  position: relative;
  max-height: calc(100vh - 165px);
}
._open-popover .popover {
  opacity: 1;
  pointer-events: auto;
  margin: 0;
  transition-delay: 0s, 0s, 0s;
}
._open-popover .popover._center {
  transform: translateX(50%);
}

@media (max-width: 575.98px) {
  .popover:before, .popover:after {
    right: 20px;
  }
  .popover__head {
    font-size: 15px;
  }
  .popover-rubrics {
    padding: 0;
    min-width: 0;
    overflow: visible;
    display: flex;
    align-items: center;
  }
  .popover-rubrics__item {
    padding: 16px 0;
    height: 56px;
    margin-left: 20px;
    flex-shrink: 0;
    overflow: hidden;
    border-top: 0;
  }
  .popover-rubrics__item:before {
    left: 0;
    height: 3px;
    width: 100%;
    transform: translateY(100%);
    top: auto;
    bottom: 0;
    margin: 0;
  }
  .popover-rubrics .img-cover {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .popover-search {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  .popover-search__input {
    width: calc(100% - 40px);
    padding: 0 16px;
    margin: 0 20px 16px;
    flex-shrink: 0;
    font-size: 16px;
  }
  .popover-search__submit {
    position: relative;
    width: calc(100% - 40px);
    display: flex;
    top: auto;
    right: auto;
    margin: 0 20px 24px;
    flex-shrink: 0;
    min-height: 56px;
    font-size: 15px;
  }
  .popover._screen {
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    width: 100vw !important;
    box-shadow: none !important;
    height: 100%;
    border-radius: 0;
    padding-top: 50px;
    margin: 0 !important;
    z-index: -2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: transparent;
  }
  .popover._screen:before, .popover._screen:after {
    display: none;
  }
  .popover._screen > .popover__head {
    min-height: 0;
    border-top: 1px solid #e9e9e9;
    border-bottom: 0;
    font-size: 18px;
    flex-shrink: 0;
    padding: 24px 20px 16px;
  }
  .popover._screen > .popover__head .popover__close {
    position: fixed;
    width: 24px;
    height: 24px;
    right: 20px;
    top: 16px;
    z-index: 10;
  }
  .popover .notifications {
    max-height: none;
    height: 100%;
    overflow: auto;
  }
  .popover .search-result {
    height: 100%;
    overflow: auto;
    max-height: none;
  }
}
.header {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  will-change: transform;
  z-index: 2000;
  transform: translateY(-100%);
  min-width: 1220px;
  background: #fff;
  transition: box-shadow 0.3s;
  height: 120px;
}
.header__sticky_element {
  visibility: hidden;
  height: 0;
  width: 0 !important;
}
.header._fixed {
  position: fixed;
  top: 0;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  transform: translateY(0);
  height: 50px;
}
@media (min-width: 576px) {
  .header._fixed .content-layout:first-child {
    display: none;
  }
}
.header._fixed .header__sticky_element {
  visibility: visible;
  height: 100%;
  width: auto !important;
  margin-left: auto;
}
.header._fixed .header__sticky_element.header__logo {
  margin-left: 0;
  margin-right: auto;
}
.header._fixed ~ .room-fixed-info {
  transform: translateY(0);
}
.header._fixed .main-menu-list:not(.submenu) {
  padding-block: 1px;
}
@media (min-width: 576px) {
  .header._fixed .main-menu-list:not(.submenu) {
    column-gap: 10px;
    transform: translateX(4px);
  }
}
.header._fixed .header-search,
.header._fixed .header-notifications,
.header._fixed .header-gift {
  margin-left: 15px !important;
}
@media (min-width: 576px) {
  .header._fixed .header-gift {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) {
  .header._fixed .header__btn-login {
    width: auto;
    padding-inline: 15px;
  }
}
html._popup-menu .header, html._popover-header .header {
  position: fixed;
  top: 0;
  transform: translateY(0);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
}
.header__logo {
  width: 200px;
  top: 18px;
  left: 0;
  transform: translateX(11%);
}
.header__logo._sm {
  position: relative;
  width: 40px;
  top: 0;
}
.header__row {
  height: 72px;
}
.header__btn-login {
  width: 140px;
}
.header-user ~ .header-search .header-search__popover-form {
  right: -100px;
}
.header-user__avatar, .header-user__icon {
  cursor: pointer;
}
.header-user__icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-left: 4px;
  font-size: 8px;
  transform: scaleY(-1);
  transition: transform 0.2s;
}
.header-user__icon:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 28px;
}
.header-user__popover {
  top: 61px;
  min-width: 240px;
}
.header-user__popover._sticky-element {
  top: 41px;
  border-radius: 0;
  border-top: 1px solid #eff3f9;
}
.header-user__popover._sticky-element::before {
  box-shadow: none;
  width: 0;
  height: 0;
}
.header-user__popover:before, .header-user__popover:after {
  right: 52px;
}
.header-user__popover a {
  color: #444;
}
@media (min-width: 576px) {
  .header-user__popover a:hover {
    font-weight: 500;
    color: #3564a0;
  }
}
.header-user._open-popover .header-user__icon {
  transform: scaleY(1);
}
.header-notifications__icon, .header-search__icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 21px;
  cursor: pointer;
}
.header-notifications._open-popover .header-notifications__icon:before {
  opacity: 0;
}
.header-notifications._open-popover .header-notifications__icon:after {
  opacity: 1;
}
.header-notifications__icon {
  font-size: 23px;
  position: relative;
}
.header-notifications__icon.unread:after {
  content: "";
  display: block;
  width: 9px;
  aspect-ratio: 1;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
  transition: background-color 0.2s, border-color 0.2s;
}
.header-notifications__icon:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-color: #EFF3F9;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s;
  opacity: 0;
}
@media (min-width: 576px) {
  .header-notifications__icon:hover:before {
    opacity: 1;
  }
}
.header-notifications__icon:hover.unread:after {
  background-color: #3564A0;
  border-color: #EFF3F9;
}
.header-notifications__icon:hover ._icon path {
  fill: #3564A0;
}
.header-notifications__icon ._icon {
  display: block;
  width: 24px;
  height: 24px;
}
.header-notifications__icon ._icon path {
  fill: #000;
  transition: fill 0.2s;
}
.header-notifications__popover {
  width: 100vw;
  right: -16px;
  top: 40px;
}
.header-search {
  position: relative;
}
.header-search._open-popover .header-search__popover-result {
  opacity: 0;
  pointer-events: none;
  margin-top: 10px;
  transition-delay: 0s, 0s, 0.2s;
}
.header-search._open-popover .header-search__popover-result._show-result {
  opacity: 1;
  pointer-events: auto;
  margin: 0;
  transition-delay: 0.2s, 0.2s, 0s;
}
.header-search__icon {
  position: relative;
}
.header-search__icon:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-color: #EFF3F9;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s;
  opacity: 0;
}
@media (min-width: 576px) {
  .header-search__icon:hover:before {
    opacity: 1;
  }
}
.header-search__icon:hover ._icon path {
  fill: #3564A0;
}
.header-search__icon ._icon {
  display: block;
  width: 24px;
  height: 24px;
}
.header-search__icon ._icon path {
  fill: #000;
  transition: fill 0.2s;
}
.header-search__popover-form {
  right: -164px;
  top: -14px;
  max-width: none;
  box-shadow: none;
  margin: 0;
}
.header-search__popover-result {
  max-width: none;
  width: 100%;
  top: 71px;
  right: 0;
}
.header-gift {
  cursor: pointer;
  /* 		&.show-phone {
  			width: 24px;
  			height: 24px;
  			right: 64px;
  			top: 16px;
  		} */
}
.header-gift__icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header-gift__icon:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-color: #EFF3F9;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s;
  opacity: 0;
}
@media (min-width: 576px) {
  .header-gift__icon:hover:before {
    opacity: 1;
  }
}
.header-gift__icon:hover ._icon path {
  fill: #3564A0;
}
.header-gift__icon ._icon {
  display: block;
  width: 24px;
  height: 24px;
}
.header-gift__icon ._icon path {
  fill: #000;
  transition: fill 0.2s;
}
.header-gift__popover {
  width: 420px;
  right: -173px;
  top: 40px;
}
@media (max-width: 575.98px) {
  .header-gift__popover {
    width: 90vw;
    right: -45px;
    top: 55px;
  }
}
.header-gift__popover-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}
.header-gift__popover .popover__close {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}
.header-gift__popover .popover__body ~ .popover__body {
  border-top: 1px solid #eff3f9;
}
.header-gift .poker-rooms__card-right-side-btn-review {
  padding: 10px;
}
.header .main-menu {
  border-top: 1px solid #e9e9e9;
  position: relative;
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  flex-shrink: 0;
}
.header__sticky_element .header-user__avatar {
  width: 32px;
  height: 32px;
}
.header__sticky_element .header-user__icon {
  margin-left: 0;
}
.header__sticky_element .header-user ~ .header-search .header-search__popover-form {
  right: -85px;
}
.header__sticky_element .header__btn-login {
  padding: 8px 24px;
  max-width: 90px;
}
.header__sticky_element .header-search__popover-form {
  top: -10px;
  padding: 6px;
  background: white;
  right: -100px;
}
.header__sticky_element .header-search__popover-result {
  top: 43px;
  border-radius: 0;
}
.header__sticky_element .header-search__popover-result:before {
  width: 0;
  height: 0;
}
.header__sticky_element .popover-search__input {
  height: 32px;
  width: 318px;
  margin-right: 105px;
}
.header__sticky_element .popover-search__submit {
  width: 100px;
  max-height: 32px;
  min-height: 32px;
  height: 32px;
  top: 0px;
  right: 0px;
  padding: 6px 24px;
}

.sticky-header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  background: #fff;
  z-index: 20;
  display: none;
}
.sticky-header__wrapper {
  width: 100%;
  height: 100%;
}
.sticky-header__wrapper .sticky-header__room-button img {
  display: block;
}
.sticky-header__wrapper .sticky-header__room-button .close-icon {
  display: none;
}
.sticky-header__wrapper .sticky-header__room-button .counter {
  display: flex;
}
.sticky-header__wrapper.menu-open .sticky-header__room-button img {
  display: none;
}
.sticky-header__wrapper.menu-open .sticky-header__room-button .close-icon {
  display: block;
}
.sticky-header__wrapper.menu-open .sticky-header__room-button .counter {
  display: none;
}
.sticky-header__item {
  display: flex;
  width: 64px;
  height: 56px;
  padding: 4px 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  padding-bottom: 10px;
}
.sticky-header__item-text {
  color: #000;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  line-height: 10px;
}
.sticky-header__item-svg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sticky-header__item-svg svg {
  width: 20px;
  height: 20px;
}
.sticky-header__item-svg svg path[fill],
.sticky-header__item-svg svg g g path[fill],
.sticky-header__item-svg svg rect[fill] {
  fill: #000;
}
.sticky-header__item-svg svg path[stroke],
.sticky-header__item-svg svg g g path[stroke],
.sticky-header__item-svg svg rect[stroke] {
  stroke: #000;
}
.sticky-header__item._active svg path[fill],
.sticky-header__item._active svg g g path[fill],
.sticky-header__item._active svg rect[fill] {
  fill: #346eb8;
}
.sticky-header__item._active svg path[stroke],
.sticky-header__item._active svg g g path[stroke],
.sticky-header__item._active svg rect[stroke] {
  stroke: #346eb8;
}
.sticky-header__room-button {
  display: flex;
  width: 64px;
  height: 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  background-color: #fff;
}
.sticky-header__room-button::before {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="12" viewBox="0 0 64 12" fill="none"><path d="M0 12C0 5.37258 5.37258 0 12 0H52C58.6274 0 64 5.37258 64 12H0Z" fill="white"/></svg>');
  position: absolute;
  top: -12px;
}
.sticky-header__room-button .counter {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  width: 14px;
  height: 14px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #08a23b;
}
.sticky-header__body-bg {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}
.sticky-header__swiper {
  position: fixed;
  bottom: 66px;
  z-index: 100;
  left: 0;
  right: 0;
  width: 100%;
}
.sticky-header__swiper .swiper-prev-preview,
.sticky-header__swiper .swiper-next-preview {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
}

@media (max-width: 575.98px) {
  .footer {
    padding-bottom: 60px;
  }
  .header {
    top: 50px;
    min-width: 360px;
    height: 50px;
  }
  html._popup-menu .header .header__btn-menu div {
    background: transparent;
  }
  html._popup-menu .header .header__btn-menu div:before, html._popup-menu .header .header__btn-menu div:after {
    width: 32px;
    right: -4px;
    top: 0;
  }
  html._popup-menu .header .header__btn-menu div:before {
    transform: rotate(45deg);
  }
  html._popup-menu .header .header__btn-menu div:after {
    transform: rotate(-45deg);
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  html._popover-header .header {
    height: 100%;
  }
  html._popover-header .header .header__btn-menu {
    z-index: -2;
  }
}
@media (max-width: 575.98px) {
  .header__logo {
    width: 138px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 575.98px) {
  .header__row {
    height: 0;
    display: none;
  }
}
@media (max-width: 575.98px) {
  .header__btn-menu {
    width: 24px;
    height: 24px;
    left: 10px;
    top: 16px;
  }
  .header__btn-menu div {
    top: 50%;
    margin-top: -1px;
    height: 2px;
    background: #000;
    width: 100%;
    transition: background-color 0.2s;
  }
  .header__btn-menu div:before, .header__btn-menu div:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    background: #000;
  }
  .header__btn-menu div:before {
    top: -8px;
    width: 50%;
  }
  .header__btn-menu div:after {
    top: 8px;
  }
}
@media (max-width: 575.98px) {
  .header-search._open-popover .header-search__popover-result._show-result {
    transition-delay: 0s, 0s, 0s;
  }
  .header-search__popover-result {
    position: static;
    width: auto;
    top: auto;
    right: auto;
    box-shadow: none !important;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    margin: 0 !important;
  }
  .header-search__popover-result:before, .header-search__popover-result:after {
    display: none;
  }
  .header-search__popover-result .popover__head {
    min-height: 0;
    border-bottom: 1px solid #e9e9e9;
  }
}
@media (max-width: 575.98px) {
  .sticky-header {
    display: block;
  }
}
@media (max-width: 575.98px) {
  .header .content-layout {
    height: 100%;
  }
  .header .header__row {
    height: 100%;
    flex-direction: row !important;
    justify-content: flex-end !important;
    padding-right: 10px;
  }
  .header .header__row .header-search {
    display: none !important;
  }
  .open-profile .header .header-user .header-user__icon {
    transform: scaleY(1);
  }
  .header .header-user .avatar {
    width: 32px;
    height: 32px;
  }
  .header .header-user__popover {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none;
    top: 50px;
    margin: 0 !important;
    padding: 16px;
    box-shadow: none !important;
    border-radius: 0;
    border-top: 1px solid #EFF3F9;
  }
  .open-profile .header .header-user__popover {
    opacity: 1;
    pointer-events: auto;
  }
  .header .header-user__popover:before, .header .header-user__popover:after {
    display: none;
  }
  .header .header-user__popover .popover__head {
    display: none;
  }
  .header .header-user__popover .px-16.py-1.c-bg-pgreen {
    order: -1;
    border-radius: 4px;
    padding-block: 8px !important;
  }
  .header .header-user__popover .px-16.py-1.c-bg-pgreen .c-blue.fs-13.lh-18 {
    display: none;
  }
  .header .header-user__popover .px-16.py-1.c-bg-pgreen .fs-15.lh-20 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  .header .header-user__popover .px-16.py-1.c-bg-pgreen .fs-15.lh-20 .c-green {
    display: inline-block;
    margin-right: 8px;
    font-size: 15px;
    line-height: 20px;
  }
  .header .header-user__popover .px-16.py-1.c-bg-pgreen .fs-15.lh-20 .c-black {
    display: inline-block;
    font-size: 18px;
    line-height: 26px;
  }
  .header .header-user__popover .p-16 {
    border-bottom: 1px solid #E9E9E9;
  }
  .header .header-user__popover .p-16 .avatar {
    width: 64px;
    height: 64px;
  }
  .header .header-user__popover .p-16 .text.fw-med {
    width: 100%;
    max-width: 60vw;
  }
  .header .header-user__popover .p-16 .text.fw-med .mobile-link {
    display: table;
    margin-top: 4px;
    color: #346EB8;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
  }
  .header .header-user__popover .p-16 .text.fw-med .mobile-link:hover {
    text-decoration: none;
  }
  .header .header-user__popover .p-16 .link-abs {
    display: none;
  }
  .header .header-user__popover .px-16.pb-16 {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  .header .header-user__popover .px-16.pb-16 .text:hover {
    color: #6C6B6B;
  }
  .header .header-user__popover .h-1.c-bg-ulgray {
    display: none !important;
  }
  .header .header-user__popover .text.d-block.p-16.text-center.c-red {
    margin-top: 16px;
    padding-block: 10px !important;
    background-color: #FB4D4E;
    border: none !important;
    border-radius: 4px;
    color: #fff !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    transition: background-color 0.2s;
  }
  .header .header-user__popover .text.d-block.p-16.text-center.c-red:hover {
    background-color: #fc7f80;
  }
  .header .header-notifications {
    margin: 0 !important;
    position: absolute !important;
    top: 130px;
    right: 20px;
    z-index: 1111;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.2s;
  }
  .open-profile .header .header-notifications {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .header .header-notifications__icon {
    display: block !important;
  }
  ._open-popover .header .header-notifications__icon:before {
    opacity: 0;
  }
  ._open-popover .header .header-notifications__icon:after {
    opacity: 1;
  }
  .header .header-notifications__badge {
    display: block !important;
  }
  .header .header-notifications__popover {
    padding: 0;
    max-width: 100%;
    height: 50dvh;
    position: fixed !important;
    top: 160px !important;
    left: 0 !important;
    right: auto !important;
    z-index: 11 !important;
    overflow: auto !important;
    background-color: #fff;
  }
  .header .header-notifications__popover .popover__head {
    padding-block: 15px;
    border-bottom: 1px solid #e9e9e9;
  }
  .header .header-notifications__popover .notifications__item:first-child {
    padding-top: 16px;
  }
  .header .header__row .header-gift {
    display: block !important;
    margin: 0 !important;
    position: absolute !important;
    top: 15px;
    left: 60px;
    z-index: 1;
    /* &__icon {
    	width: 24px;
    	height: 24px;
    } */
  }
  .header .header__row .header-gift.open-gifts .header-gift__popover {
    opacity: 1;
    pointer-events: auto;
  }
  .header .header__row .header-gift__popover {
    width: 100vw !important;
    max-width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    right: auto;
    margin: 0 !important;
    box-shadow: none !important;
    border-radius: 0;
  }
  ._open-popover .header .header__row .header-gift__popover {
    opacity: 0;
    pointer-events: none;
  }
}

@media (max-width: 575.98px) {
  .header .header__btn-login {
    width: auto;
    height: auto;
    margin: 0 !important;
    padding: 0;
    background: none;
    font-size: 0;
  }
}
.header .header__btn-login .header__login_mob_icon {
  width: 32px;
  height: 32px;
}
@media (min-width: 576px) {
  .header .header__btn-login .header__login_mob_icon {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .main-menu {
    width: 100%;
    flex-direction: column;
    padding: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
  }
}
.main-menu .header-mob-search {
  width: 100%;
  padding: 8px 10px;
  text-align: center;
}
@media (min-width: 576px) {
  .main-menu .header-mob-search {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .main-menu .header-mob-search .header-search {
    margin: 0 !important;
  }
  .main-menu .header-mob-search .header-search__popover-form.popover {
    display: block;
    position: static !important;
    padding: 0 !important;
    width: auto !important;
    height: auto !important;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .main-menu .header-mob-search .header-search__popover-form.popover:before, .main-menu .header-mob-search .header-search__popover-form.popover:after {
    display: none;
  }
  .main-menu .header-mob-search .popover-search {
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    position: relative;
  }
  .main-menu .header-mob-search .popover-search::before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-image: url("data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAntJREFUSInFlsF12kAQhv8ZwQVf6CB0EFJBcAVB79lP6AYVGFcA7sCuQPFpH3rPT0oF4ApCCSqBkziI1eQiyYsQksgzydy02p1vZ3bnnwX+k1HdT8/z+r1eb87M3wEMRaSf/doCiJg5vLu7e/00sOd5/ZubmyWAhxY+ImZeXrqBE7Dv+0MAAYDBJY5EJIzjeDabzXYXg5VSg06n89tIaW4bAKGI5E6HAMZEdLQ5EdnGcXzbBl6AlVIDy7LWpUg3WuuZ67pR1WKl1JSZF+YGROTZcZzH1mDf9z0AU8PB0nGcpyYHSqkBM69NuNb61nXdTd06zhebUABhGygAuK4bpWl6S0RFei3LWjStYwDodrs/zEGtdWOqynAAz8bQ6O3t7UsjWETG+YCIhOfOtM46nc6L+a21Hp+fnYGzWwoAIKLaszlntm3viGibf4tIbTnmERflw8yt6rDKRKQAE1G5JE/Bn2Xlum4DLs5Uaz08P73RiigNsakFF+dKRH8FVkoNRKRYKyLNdQzg3RgbKaVGl4LLtbvf79/Pz/6o47AkAF4mKq1stVqNSwL0s0mvGVkpaK1NpRpYlrVuA1+tVmNm9oyhsq9KO+pOvu8HAMzCj5h5mSTJe1lUsvb5UIoUaZrOJ5PJkZg0goMg6CdJsjYFpZhItCm1xcpsENGGiKIkSZ7qFPDkIRAEQf9wOCxEZN60a8N2aZoumXkMIL+YUdalKuEnAmLb9u7+/v4xc9AknzsRedZaf8vSa0Jq70ntYw8fLXPEzF9zGcxSvo3j+Jd5e6t687nIG8GXWlv4p4Pbwq8CbgO/GrgJflVwHfzq4Cq4iEz/CdiEA1g6jvP6Bx2JRVIUOiAlAAAAAElFTkSuQmCC");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 1;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .main-menu .header-mob-search .popover-search.typing input {
    width: auto !important;
  }
  .main-menu .header-mob-search .popover-search.typing .search-mob-input-clear {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
  .main-menu .header-mob-search .popover-search .search-mob-input-clear {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 80px;
    z-index: -1;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
  }
  .main-menu .header-mob-search .popover-search .search-mob-input-clear .mob-input-clear-icon {
    display: block;
    width: 10px;
    height: 10px;
  }
  .main-menu .header-mob-search input {
    flex-grow: 1;
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    padding: 6px 10px 6px 40px;
    border-radius: 4px;
    position: relative;
  }
  .main-menu .header-mob-search input::placeholder {
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .main-menu .header-mob-search .popover-search__submit {
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    max-height: none;
    margin: 0;
    padding: 6px 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
  .main-menu .header-mob-search .header-search__popover-result {
    display: none;
    height: 60dvh;
    padding-top: 20px;
    position: absolute;
    top: 38px;
    left: -10px;
    right: -10px;
    z-index: 2;
    opacity: 1;
    pointer-events: auto;
    overflow: auto;
    background-color: #EFF3F9;
  }
  .main-menu .header-mob-search .header-search__popover-result._show-result {
    display: block;
  }
  .main-menu .header-mob-search .header-search__popover-result .popover__head {
    margin: 0 !important;
    padding-inline: 20px !important;
  }
}
.main-menu .main-menu-list {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  column-gap: 16px;
}
@media (max-width: 575.98px) {
  .main-menu .main-menu-list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.main-menu .main-menu-list.submenu {
  display: none;
  width: 100%;
  width: clamp(220px, 14vw, 300px);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
.main-menu .main-menu-list.submenu .main-menu-item {
  position: relative;
  color: #000;
  transition: background-color 0.2s;
}
@media (max-width: 575.98px) {
  .main-menu .main-menu-list.submenu .main-menu-item.hidden + .main-menu-item.opened {
    border-top: none;
  }
}
@media (min-width: 576px) {
  .main-menu .main-menu-list.submenu .main-menu-item {
    border-bottom: 1px solid #e9e9e9;
  }
  .main-menu .main-menu-list.submenu .main-menu-item.dropdown .submenu {
    top: 0;
    left: 100%;
  }
  .main-menu .main-menu-list.submenu .main-menu-item:hover {
    background-color: #eff3f9;
    border-color: #e9e9e9;
  }
  .main-menu .main-menu-list.submenu .main-menu-item:hover .dropdown-menu-list {
    display: block;
  }
  .main-menu .main-menu-list.submenu .main-menu-item a {
    width: 100%;
    padding-inline: 20px;
    font-size: 11px;
    font-weight: 700;
  }
}
.main-menu .main-menu-list .main-menu-item {
  display: flex;
  align-items: center;
  transition: border 0.2s, color 0.2s;
}
@media (min-width: 576px) {
  .main-menu .main-menu-list .main-menu-item {
    border-bottom: 2px solid transparent;
  }
  .main-menu .main-menu-list .main-menu-item:hover {
    border-color: #3564A0;
  }
}
@media (max-width: 575.98px) {
  .main-menu .main-menu-list .main-menu-item {
    width: 100%;
    border-top: 1px solid #e9e9e9;
  }
}
.main-menu .main-menu-list .main-menu-item.dropdown {
  position: relative;
}
@media (max-width: 575.98px) {
  .main-menu .main-menu-list .main-menu-item.dropdown.opened > a {
    padding-left: 34px;
    color: #6C6B6B;
    transition-property: none;
  }
  .main-menu .main-menu-list .main-menu-item.dropdown.opened > a:after {
    right: auto;
    left: 20px;
    transform: translateY(-50%) rotate(90deg);
  }
  .main-menu .main-menu-list .main-menu-item.dropdown > a {
    pointer-events: none;
    cursor: pointer;
  }
}
@media (min-width: 576px) {
  .main-menu .main-menu-list .main-menu-item.dropdown:hover > .submenu {
    display: block;
  }
}
@media (min-width: 576px) {
  .main-menu .main-menu-list .main-menu-item.dropdown .dropdown > a {
    padding-right: 20px;
  }
  .main-menu .main-menu-list .main-menu-item.dropdown .dropdown > a:after {
    transform: translateY(-50%) rotate(-90deg);
  }
}
@media (min-width: 576px) {
  .main-menu .main-menu-list .main-menu-item.dropdown > a {
    padding-right: 34px;
  }
}
.main-menu .main-menu-list .main-menu-item.dropdown > a:after {
  content: "";
  display: block;
  width: 8px;
  height: 5px;
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 0;
  transform: translateY(-50%);
  background-image: url("data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAALCAYAAACZIGYHAAAAAXNSR0IArs4c6QAAAGlJREFUKJFjYKAiOM/AwPCfTHyfCWrIBwoccADGEGBgYLhPjisYGBgUkE1UYGBgeE+CAe/RDYABBxIMCcDnxwIiDKgnJrD68RjQT3yYY4/2+6QYwAANtPtoBmANSELAAKoZZ0wwMDAwAADXylGSLsdeSQAAAABJRU5ErkJggg==");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 575.98px) {
  .main-menu .main-menu-list .main-menu-item.dropdown > a:after {
    transform: translateY(-50%) rotate(-90deg);
  }
}
.main-menu .main-menu-list .main-menu-item a {
  display: block;
  padding: 13px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
@media (min-width: 576px) and (max-width: 992px) {
  .main-menu .main-menu-list .main-menu-item a {
    font-weight: 500;
  }
}
@media (max-width: 575.98px) {
  .main-menu .main-menu-list .main-menu-item a {
    width: 100%;
    padding-inline: 20px;
  }
  .main-menu .main-menu-list .main-menu-item a.hidden + .submenu > .main-menu-item.opened {
    border-top: none;
  }
}

.footer__info {
  width: 406px;
}
.footer__logo {
  width: 218px;
}
.footer__text {
  max-width: 380px;
}
.footer__btn {
  min-height: 36px;
  color: #000;
}
.footer__btn:hover {
  color: #000;
}
@media (min-width: 576px) {
  .footer__btn:hover {
    color: #3564a0;
  }
}
.footer__support {
  width: 220px;
}
.footer__rooms {
  width: 300px;
}

@media (max-width: 575.98px) {
  .footer__info, .footer__support, .footer__rooms {
    width: auto;
  }
  .footer__logo {
    width: 152px;
  }
}
.popup {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 60px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 3000;
}
.popup:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.popup._static {
  pointer-events: auto;
}
.popup._static:before, .popup._static:after,
.popup._static .popup__body {
  opacity: 1;
}
.popup._static.popup-guide .popup__body {
  transform: translateX(0);
}
.popup__body {
  opacity: 0;
  transition: opacity 0.3s ease-out;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  max-width: 700px;
  background: #fff;
  z-index: 2;
  margin: auto 0;
  padding: 30px 80px;
}
.popup__close {
  position: absolute;
  color: #fff;
  width: 32px;
  height: 32px;
  line-height: 32px;
  left: 100%;
  bottom: 100%;
  cursor: pointer;
  font-size: 16px;
}
.popup-menu {
  display: none;
}
html._popup-auth .popup-auth {
  pointer-events: auto;
}
html._popup-auth .popup-auth:before, html._popup-auth .popup-auth:after,
html._popup-auth .popup-auth .popup__body {
  opacity: 1;
}
html._popup-registration .popup-auth {
  pointer-events: auto;
}
html._popup-registration .popup-auth:before, html._popup-registration .popup-auth:after,
html._popup-registration .popup-auth .popup__body {
  opacity: 1;
}
.popup-auth__form-registration {
  display: none;
}
.popup-auth__body[data-state=registration] .popup-auth__form-auth {
  display: none;
}
.popup-auth__body[data-state=registration] .popup-auth__form-registration {
  display: block;
}
html._popup-recovery .popup-recovery {
  pointer-events: auto;
}
html._popup-recovery .popup-recovery:before, html._popup-recovery .popup-recovery:after,
html._popup-recovery .popup-recovery .popup__body {
  opacity: 1;
}
.popup-form__cover {
  width: 220px;
  flex-shrink: 0;
  overflow: hidden;
}
.popup-form__cover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 111.24%);
  opacity: 0.8;
  pointer-events: none;
  z-index: 1;
}
.popup-form__cover img {
  position: absolute;
  pointer-events: none;
  width: 181px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.popup-form__head {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #e9e9e9;
  font-size: 15px;
}
.popup-form__body {
  padding: 32px 48px;
}
.popup-form__message._active ~ .popup-form__form {
  display: none;
}
.popup-form__icon {
  width: 80px;
}
.popup-form__info {
  margin-bottom: 32px;
}
html._popup-cashback-alert .popup-cashback-alert {
  pointer-events: auto;
}
html._popup-cashback-alert .popup-cashback-alert:before, html._popup-cashback-alert .popup-cashback-alert:after,
html._popup-cashback-alert .popup-cashback-alert .popup__body {
  opacity: 1;
}
html._popup-cashback-disable .popup-cashback-disable {
  pointer-events: auto;
}
html._popup-cashback-disable .popup-cashback-disable:before, html._popup-cashback-disable .popup-cashback-disable:after,
html._popup-cashback-disable .popup-cashback-disable .popup__body {
  opacity: 1;
}
html._popup-tasks-congratulations .popup-tasks-congratulations {
  pointer-events: auto;
}
html._popup-tasks-congratulations .popup-tasks-congratulations:before, html._popup-tasks-congratulations .popup-tasks-congratulations:after,
html._popup-tasks-congratulations .popup-tasks-congratulations .popup__body {
  opacity: 1;
}
html._popup-tasks-alert .popup-tasks-alert {
  pointer-events: auto;
}
html._popup-tasks-alert .popup-tasks-alert:before, html._popup-tasks-alert .popup-tasks-alert:after,
html._popup-tasks-alert .popup-tasks-alert .popup__body {
  opacity: 1;
}
html._popup-tasks-disable .popup-tasks-disable {
  pointer-events: auto;
}
html._popup-tasks-disable .popup-tasks-disable:before, html._popup-tasks-disable .popup-tasks-disable:after,
html._popup-tasks-disable .popup-tasks-disable .popup__body {
  opacity: 1;
}
html._popup-comment-link .popup-comment-link {
  pointer-events: auto;
}
html._popup-comment-link .popup-comment-link:before, html._popup-comment-link .popup-comment-link:after,
html._popup-comment-link .popup-comment-link .popup__body {
  opacity: 1;
}
html._popup-comment-video .popup-comment-video {
  pointer-events: auto;
}
html._popup-comment-video .popup-comment-video:before, html._popup-comment-video .popup-comment-video:after,
html._popup-comment-video .popup-comment-video .popup__body {
  opacity: 1;
}
.popup-guide {
  padding: 0;
  display: block;
  overflow: hidden;
}
html._popup-guide .popup-guide {
  pointer-events: auto;
}
html._popup-guide .popup-guide:before,
html._popup-guide .popup-guide .popup__body {
  opacity: 1;
}
html._popup-guide .popup-guide .popup__body {
  transform: translateX(0);
}
.popup-guide:before {
  background: transparent;
}
.popup-guide .popup__body {
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 510px;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  max-width: none;
}
.popup-guide .popup__close {
  color: #000;
  top: 24px;
  right: 24px;
  left: auto;
  bottom: auto;
}
html._popup-review-form .popup-review-form {
  pointer-events: auto;
}
html._popup-review-form .popup-review-form:before, html._popup-review-form .popup-review-form:after,
html._popup-review-form .popup-review-form .popup__body {
  opacity: 1;
}
.popup-review-form__stars:not(:hover) input:checked ~ label:after {
  opacity: 1;
}
.popup-review-form__stars input {
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  height: 1px;
  width: 1px;
}
.popup-review-form__stars label:before, .popup-review-form__stars label:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-review-form__stars label:before {
  content: "\e952";
}
.popup-review-form__stars label:after {
  content: "\e92e";
  transition: opacity 0.2s;
  opacity: 0;
}
.popup-review-form__stars label:hover:after, .popup-review-form__stars label:hover ~ label:after {
  opacity: 1;
}
@media (max-width: 575.98px) {
  .popup-ad-module {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.popup-ad-module .popup__body {
  background-color: transparent !important;
  padding: 0 !important;
}
@media (max-width: 575.98px) {
  .popup-ad-module .popup__body {
    position: initial;
  }
}
.popup-ad-module .popup__container {
  max-width: 700px;
  max-height: 350px;
}
@media (min-width: 576px) {
  .popup-ad-module .popup__container {
    position: relative;
  }
}
.popup-ad-module .popup__container .popup__close {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  color: black;
  transform: translateX(-100%);
  width: 44px;
  height: 44px;
  line-height: 44px;
}
.popup-ad-module .popup__container img.popup-ad-module__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.popup-ad-module .popup__container .popup__not-show-form {
  position: absolute;
  background: white;
  bottom: 0;
  right: 0;
}
.popup-ad-module .popup__container .popup__not-show-form .form-checkbox__check {
  outline: 2px solid black;
}
html._popup-ad-module .popup-ad-module {
  pointer-events: auto;
}
html._popup-ad-module .popup-ad-module:before, html._popup-ad-module .popup-ad-module:after,
html._popup-ad-module .popup-ad-module .popup__body {
  opacity: 1;
}

@media (min-width: 576px) {
  .popup-rooms-filter {
    position: absolute;
    width: 100%;
    height: auto;
    overflow: visible;
    padding: 0;
    display: block;
  }
  html._popup-rooms-filter .popup-rooms-filter {
    pointer-events: auto;
  }
  html._popup-rooms-filter .popup-rooms-filter:before,
  html._popup-rooms-filter .popup-rooms-filter .popup__body {
    opacity: 1;
    pointer-events: auto;
  }
  .popup-rooms-filter .popup__body {
    max-width: 860px !important;
    margin: 0;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 120px - 40px);
  }
  .popup-rooms-filter .popup__close {
    color: #000;
    top: 16px;
    right: 16px;
    left: auto;
    bottom: auto;
  }
  .popup-guide .popup__body {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .popup {
    padding: 70px 20px;
    /* &:after {
    	@include icomoonInit();
    	content: $icon-close;
    	position: fixed;
    	color: map_get($colors, white);
    	font-size: 20px;
    	top: 20px;
    	right: 20px;
    	z-index: 1;
    	transition: opacity 0.3s ease-out;
    	pointer-events: none;
    	opacity: 0;
    } */
  }
  .popup__body {
    padding: 20px;
    border-radius: 4px;
  }
  .popup__close {
    position: fixed;
    top: 20px;
    left: auto;
    right: 20px;
    /* &:not(._show) {
    	display: none !important;
    } */
  }
  .popup-menu {
    pointer-events: none;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3000;
    display: block;
  }
  html._popup-menu .popup-menu, .popup-menu._static {
    pointer-events: auto;
  }
  html._popup-menu .popup-menu .popup-menu__body, .popup-menu._static .popup-menu__body {
    opacity: 1;
    transform: translateY(0);
  }
  .popup-menu__body {
    opacity: 0;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
    transform: translateY(-50px);
    will-change: transform;
    overflow: auto;
  }
  .popup-auth, .popup-recovery {
    padding-left: 0;
    padding-right: 0;
  }
  .popup-auth .popup__body, .popup-recovery .popup__body {
    border-radius: 0;
  }
  .popup-form__body {
    padding: 20px;
  }
  .popup-form__message .text._xsm {
    font-size: 13px;
    line-height: 18px;
  }
  .popup-form__icon {
    width: 64px;
  }
  .popup-form__info {
    margin-bottom: 20px;
    text-align: center;
  }
  .popup-guide {
    padding: 0;
  }
  .popup-guide:before, .popup-guide:after {
    display: none;
  }
  .popup-guide .popup__body {
    padding: 64px 0 0 0;
    border-radius: 0;
    width: 100%;
  }
  .popup-guide .popup__close {
    width: 48px;
    height: 48px;
    line-height: 48px;
    display: block !important;
    top: 8px;
    right: 8px;
    font-size: 24px;
  }
}
.widget {
  background: #fff;
  margin-bottom: 20px;
  padding: 24px;
}
@media (min-width: 576px) {
  .widget-profile-menu > a:hover {
    font-weight: 500;
  }
}
.widget-profile-menu > a._active {
  font-weight: 500;
}
.widget-rubrics__item {
  transition: background 0.2s, color 0.2s;
}
@media (min-width: 576px) {
  .widget-rubrics__item:hover {
    background: #fafafa;
    color: #346eb8;
  }
}
.widget-rubrics__item._active {
  background: #fafafa;
  color: #346eb8;
}

@media (max-width: 575.98px) {
  .widget {
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.poker-rooms__link {
  text-decoration: none;
  position: relative;
}
.poker-rooms__link::after {
  content: "";
  width: 100%;
  height: 1px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -2px;
  background-color: rgba(52, 110, 184, 0.24);
  transition: background-color 0.2s;
  transition-property: opacity;
  transition-duration: 0.15s;
}
.poker-rooms__link:hover {
  color: #3564a0 !important;
}
.poker-rooms__link:hover::after {
  opacity: 0;
}
.poker-rooms__tabs {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
}
.poker-rooms__tabs-wrapper {
  width: 276px;
}
.poker-rooms__tabs input {
  display: none;
}
.poker-rooms__tabs input:checked + .poker-rooms__tab {
  background-color: #fafafa;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
  border: none;
}
.poker-rooms__deposit {
  padding-left: 4px;
}
.poker-rooms__card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
  position: relative;
  outline: 4px solid transparent;
  transition-property: outline;
  transition-duration: 0.2s;
}
.poker-rooms__card:hover {
  outline: 4px solid #E7EEF6;
}
.poker-rooms__card-promo-block {
  position: absolute;
  top: -13px;
  left: 441px;
  padding: 4px 4px 4px 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.poker-rooms__card-promo-block p {
  margin-right: 4px !important;
}
.poker-rooms__card-left-side {
  width: 50%;
}
.poker-rooms__card-left-side-icon {
  display: block;
  width: 100%;
  height: 100%;
}
.poker-rooms__card-left-side-icon-container {
  width: 56px;
  height: 56px;
  padding: 6px;
  border: 1px solid #E9E9E9;
  position: relative;
  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
}
.poker-rooms__card-left-side-icon-container:hover {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
  background-color: #fafafa !important;
}
.poker-rooms__card-left-side-icon-badge {
  display: block;
  width: 16px;
  height: 16px;
}
.poker-rooms__card-left-side-icon-badge-container {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background-color: #FAEFDE;
  transition-property: background-color;
  transition-duration: 0.1s;
}
.poker-rooms__card-left-side-icon-badge-container:hover {
  background-color: #853232;
}
.poker-rooms__card-left-side-icon-badge-container:hover .poker-rooms__card-left-side-icon-badge-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-8px);
}
.poker-rooms__card-left-side-icon-badge-tooltip {
  padding: 4px 8px;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%) translateY(-1px);
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  z-index: 9;
}
.poker-rooms__card-left-side-icon-badge-tooltip p {
  opacity: 0.64;
}
.poker-rooms__card-left-side-name-container a {
  margin-bottom: 6px;
  line-height: 22px;
}
.poker-rooms__card-left-side-rating-stars-container {
  margin-right: 4px;
}
.poker-rooms__card-left-side-deposit {
  text-wrap: nowrap;
}
.poker-rooms__card-middle {
  height: 64px;
  width: 1px;
  border: 1px solid #e9e9e9;
}
.poker-rooms__card-right-side {
  width: 50%;
  padding-left: 25px;
  justify-content: space-between;
}
.poker-rooms__card-right-side-list li:not(:last-child) {
  margin-bottom: 4px;
}
.poker-rooms__card-right-side-list-icon {
  display: block;
  width: 100%;
  height: 100%;
}
.poker-rooms__card-right-side-list-icon-container {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.poker-rooms__card-right-side-buttons {
  max-width: 120px;
}
.poker-rooms__card-right-side-btn-review {
  border: none;
  padding: 10px 26px;
  width: 100%;
  flex: 1;
}
.poker-rooms__card-right-side-btn-review-icon {
  display: block;
  width: 100%;
  height: 100%;
  fill: #08a23b;
}
.poker-rooms__card-right-side-btn-review-icon-container {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.poker-rooms__card-right-side-btn-review:hover {
  background-color: #15873b !important;
}
.poker-rooms__card-right-side-btn-review:hover .poker-rooms__card-right-side-btn-review-icon {
  fill: #fff;
}
.poker-rooms__card-right-side-btn-review:hover p {
  color: #fff !important;
}
.poker-rooms__card-right-side-btn-promo {
  width: 100%;
  padding: 4px 15px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
}
.poker-rooms__card-right-side-btn-promo-text p {
  margin-right: 4px !important;
  line-height: 22px;
}
.poker-rooms__card-right-side-btn-promo-icon {
  display: block;
  width: 100%;
  height: 100%;
}
.poker-rooms__card-right-side-btn-promo-icon-container {
  width: 18px;
  height: 18px;
}
.poker-rooms__card-right-side-btn-promo-none {
  line-height: 22px;
}
.poker-rooms__card-right-side-btn-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 15px;
  border: none;
  text-align: center;
  flex: 1;
}
.poker-rooms__card-right-side-btn-registration span {
  opacity: 0.7;
}
.poker-rooms__card-right-side-btn-registration:hover {
  background: #e94344 !important;
  color: #fff !important;
}
.poker-rooms__card-right-side-btn-registration_small {
  padding: 10px 15px;
}
.poker-rooms__card-promo-hidden {
  display: none;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.14);
  padding: 4px 8px;
}
.poker-rooms__card-promo-hidden p {
  margin-right: 4px !important;
}
.poker-rooms__card._widget .poker-rooms__card-left-side-name-container {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
}
.poker-rooms__tab {
  transition-property: background-color, box-shadow;
  transition-duration: 0.15s;
  cursor: pointer;
  user-select: none;
}
.poker-rooms__tab:hover:not(.poker-rooms__tabs input:checked + .poker-rooms__tab) {
  background-color: #E7EEF6;
  color: #346eb8;
}
.poker-rooms__tab-icon {
  display: block;
  width: 100%;
  height: 100%;
}
.poker-rooms__tab-icon-container {
  width: 24px;
  height: 24px;
}
.poker-rooms__tab:not(:last-child) {
  margin-bottom: 16px;
}
.poker-rooms__name {
  padding: 4px;
  transition-property: color, background-color;
  transition-duration: 0.15s;
}
.poker-rooms__name:hover {
  color: #346eb8;
  background-color: #E7EEF6;
}

@media (max-width: 575.98px) {
  .poker-rooms__heading {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .poker-rooms__heading h1 {
    margin-bottom: 16px;
  }
  .poker-rooms__wrapper {
    flex-direction: column;
  }
  .poker-rooms__tabs {
    margin-bottom: 24px;
  }
  .poker-rooms__tabs-wrapper {
    width: 100%;
    margin: 0 !important;
  }
  .poker-rooms__card {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .poker-rooms__card-left-side {
    align-items: flex-start !important;
    width: 100%;
    flex-direction: column;
    margin: 0 0 16px 0;
  }
  .poker-rooms__card-left-side-content {
    margin: 0 0 16px 0 !important;
  }
  .poker-rooms__card-left-side-icon-badge-container:hover .poker-rooms__card-left-side-icon-badge-tooltip {
    transform: translateY(-8px);
  }
  .poker-rooms__card-left-side-icon-badge-tooltip {
    left: 0;
    transform: translateY(-1px);
  }
  .poker-rooms__card-middle {
    height: 1px;
    width: 100%;
    margin-bottom: 16px;
  }
  .poker-rooms__card-right-side {
    width: 100%;
    flex-direction: column;
    padding: 0;
    align-items: flex-start !important;
  }
  .poker-rooms__card-right-side-list {
    margin: 0 0 16px 0 !important;
  }
  .poker-rooms__card-right-side-list li:not(:last-child) {
    margin-bottom: 0;
  }
  .poker-rooms__card-right-side-buttons {
    display: flex;
    max-width: initial;
    width: 100%;
  }
  .poker-rooms__card-right-side-btn-promo {
    padding: 4px 8px;
    width: initial;
  }
  .poker-rooms__card-right-side-btn-promo-none {
    white-space: nowrap;
  }
  .poker-rooms__card-right-side-btn-review {
    width: initial;
  }
  .poker-rooms__card-promo-block {
    display: none !important;
  }
  .poker-rooms__card-promo-hidden {
    display: flex;
    margin-bottom: 16px;
  }
}
.hidden {
  display: none !important;
}

.box-shadow-0-1-4 {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
}

.poker-rooms__tabs-sticky {
  position: sticky;
  top: 20px;
}
.poker-rooms__selection {
  padding: 18px 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition-property: background-color;
  transition-duration: 0.15s;
  cursor: pointer;
}
.poker-rooms__selection:hover {
  background-color: #15873b !important;
}
.poker-rooms__selection:hover .poker-rooms__selection-icon, .poker-rooms__selection:hover span {
  fill: white;
  color: white !important;
}
.poker-rooms__selection span {
  transition-property: color;
  transition-duration: 0.15s;
}
.poker-rooms__selection-icon {
  fill: #08A23B;
  transition-property: fill;
  transition-duration: 0.15s;
}
.poker-rooms__tabs input:checked + .poker-rooms__tab-font > p {
  color: black !important;
}
.poker-rooms__tabs-noshadow {
  box-shadow: none;
}
.poker-rooms__tabs-first {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
}
.poker-rooms__tabs-wrapper-large {
  width: 300px;
}
.poker-rooms__more {
  position: relative;
  cursor: pointer;
}
.poker-rooms__more-wrapper {
  margin-top: -8px;
  padding: 0 16px 16px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.poker-rooms__more:hover {
  color: #3564a0 !important;
}
.poker-rooms__more:hover::after {
  opacity: 0;
}
.poker-rooms__more::after {
  content: "";
  width: 100%;
  height: 1px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -2px;
  background-color: rgba(52, 110, 184, 0.24);
  transition: background-color 0.2s;
  transition-property: opacity;
  transition-duration: 0.15s;
}
.poker-rooms__trainer-wrapper {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
}
.poker-rooms__trainer-avatar {
  width: 80px;
  height: 80px;
}
.poker-rooms__trainer-avatar-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #E7EEF6;
  overflow: hidden;
  flex-shrink: 0;
}
.poker-rooms__card-right-side-buttons > :first-child {
  margin-bottom: 8px !important;
}

.social-services-circle-hover {
  display: flex;
}
.social-services-circle-hover__icon {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  position: relative;
  transition-property: width, height;
  transition-duration: 0.2s;
  font-size: 20px;
}
.social-services-circle-hover__icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  transition-property: font-size;
  transition-duration: 0.2s;
}
.social-services-circle-hover__icon.icon-chat {
  background-color: #08a23b;
}
.social-services-circle-hover__icon.icon-mail {
  background-color: #346eb8;
}
.social-services-circle-hover__icon.icon-telegram {
  background-color: #08c;
}
.social-services-circle-hover__icon.icon-skype {
  background-color: #00aff0;
}
.social-services-circle-hover-background {
  width: 60px;
  height: 60px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid transparent;
  border-radius: 100px;
  transition-property: background-color, border;
  transition-duration: 0.2s;
}
.social-services-circle-hover-background:not(:last-child) {
  margin-right: 4px;
}
.social-services-circle-hover-background:hover {
  background-color: #E7EEF6;
  border: 4px solid rgba(52, 110, 184, 0.24);
}
.social-services-circle-hover-background:hover .social-services-circle-hover__icon {
  width: 39px;
  height: 39px;
}
.social-services-circle-hover-background:hover .social-services-circle-hover__icon::before {
  font-size: 26px;
}

@media (max-width: 575.98px) {
  .poker-rooms__tabs-wrapper-large {
    width: 100%;
  }
  .poker-rooms__card-right-side-buttons > :first-child {
    margin-bottom: 0px !important;
    margin-right: 8px !important;
  }
}