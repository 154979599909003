@import "config";
@import "icon_names";

.popup {
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 60px 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	z-index: $popup_zindex;

	&:before {
		content: "";
		pointer-events: none;
		z-index: 1;
		opacity: 0;
		transition: opacity 0.3s ease-out;
		background: rgba(0, 0, 0, 0.8);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&._static {
		pointer-events: auto;

		&:before,
		&:after,
		.popup__body {
			opacity: 1;
		}

		&.popup-guide {
			.popup__body {
				transform: translateX(0);
			}
		}
	}

	&__body {
		opacity: 0;
		transition: opacity 0.3s ease-out;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		max-width: 700px;
		background: map_get($colors, white);
		z-index: 2;
		margin: auto 0;
		padding: 30px 80px;
	}

	&__close {
		$size: 32px;
		position: absolute;
		color: map_get($colors, white);
		width: $size;
		height: $size;
		line-height: $size;
		left: 100%;
		bottom: 100%;
		cursor: pointer;
		font-size: 16px;
	}

	&-menu {
		display: none;
	}

	&-auth {
		html._popup-auth & {
			pointer-events: auto;

			&:before,
			&:after,
			.popup__body {
				opacity: 1;
			}
		}

		html._popup-registration & {
			pointer-events: auto;

			&:before,
			&:after,
			.popup__body {
				opacity: 1;
			}
		}

		&__form-registration {
			display: none;
		}

		&__body {
			&[data-state="registration"] {
				.popup-auth__form-auth {
					display: none;
				}

				.popup-auth__form-registration {
					display: block;
				}
			}
		}
	}

	&-recovery {
		html._popup-recovery & {
			pointer-events: auto;

			&:before,
			&:after,
			.popup__body {
				opacity: 1;
			}
		}
	}

	&-form {
		&__cover {
			width: 220px;
			flex-shrink: 0;
			overflow: hidden;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(180deg, map_get($colors, black) 0%, rgba(0, 0, 0, 0) 111.24%);
				opacity: 0.8;
				pointer-events: none;
				z-index: 1;
			}

			img {
				position: absolute;
				pointer-events: none;
				width: 181px;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
			}
		}

		&__head {
			min-height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			border-bottom: 1px solid map_get($colors, ulgray);
			font-size: 15px;
		}

		&__body {
			padding: 32px 48px;
		}

		&__message {
			&._active {
				& ~ .popup-form__form {
					display: none;
				}
			}
		}

		&__icon {
			width: 80px;
		}

		&__info {
			margin-bottom: 32px;
		}
	}

	&-cashback {
		&-alert {
			html._popup-cashback-alert & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}

		&-disable {
			html._popup-cashback-disable & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}
	}

	&-tasks {
		&-congratulations {
			html._popup-tasks-congratulations & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}

		&-alert {
			html._popup-tasks-alert & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}

		&-disable {
			html._popup-tasks-disable & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}
	}

	&-comment {
		&-link {
			html._popup-comment-link & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}

		&-video {
			html._popup-comment-video & {
				pointer-events: auto;

				&:before,
				&:after,
				.popup__body {
					opacity: 1;
				}
			}
		}
	}

	&-guide {
		padding: 0;
		display: block;
		overflow: hidden;

		html._popup-guide & {
			pointer-events: auto;

			&:before,
			.popup__body {
				opacity: 1;
			}

			.popup__body {
				transform: translateX(0);
			}
		}

		&:before {
			background: transparent;
		}

		.popup__body {
			transition:
				opacity 0.3s ease-out,
				transform 0.3s ease-out;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 510px;
			margin: 0;
			display: flex;
			flex-direction: column;
			padding: 24px 0 0 0;
			max-width: none;
		}

		.popup__close {
			color: map_get($colors, black);
			top: 24px;
			right: 24px;
			left: auto;
			bottom: auto;
		}
	}

	&-review-form {
		html._popup-review-form & {
			pointer-events: auto;

			&:before,
			&:after,
			.popup__body {
				opacity: 1;
			}
		}

		&__stars {
			&:not(:hover) {
				input:checked ~ label:after {
					opacity: 1;
				}
			}

			input {
				top: 0;
				left: 0;
				z-index: -1;
				opacity: 0;
				height: 1px;
				width: 1px;
			}

			label {
				&:before,
				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				&:before {
					content: $icon-star-null;
				}

				&:after {
					content: $icon-star;
					transition: opacity 0.2s;
					opacity: 0;
				}

				&:hover {
					&:after,
					& ~ label:after {
						opacity: 1;
					}
				}
			}
		}
	}

	&-ad-module {
		@media ($media_max_phone) {
			padding-left: 0!important;
			padding-right: 0!important;
		}
		.popup__body {
			background-color: transparent !important;
			padding: 0 !important;
			@media ($media_max_phone) {
				position: initial;
			}
		}
		.popup__container {
			max-width: 700px;
			max-height: 350px;
			@media ($media_min_phone) {
				position: relative;
			}
			.popup__close {
				position: absolute;
				right: 0;
				top: 0;
				background-color: white;
				color: black;
				transform: translateX(-100%);
				width: 44px;
				height: 44px;
				line-height: 44px;
			}
			img.popup-ad-module__img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
			.popup__not-show-form {
				position: absolute;
				background: white;
				bottom: 0;
				right: 0;
				.form-checkbox__check {
					outline: 2px solid black;
				}
			}
		}
		html._popup-ad-module & {
			pointer-events: auto;
			&:before,
			&:after,
			.popup__body {
				opacity: 1;
			}
		}
	}
}

@media ($media_min_phone) {
	.popup {
		&-rooms-filter {
			position: absolute;
			width: 100%;
			height: auto;
			overflow: visible;
			padding: 0;
			display: block;

			html._popup-rooms-filter & {
				pointer-events: auto;

				&:before,
				.popup__body {
					opacity: 1;
					pointer-events: auto;
				}
			}

			.popup__body {
				max-width: 860px !important;
				margin: 0;
				padding: 24px 40px;
				display: flex;
				flex-direction: column;
				max-height: calc(100vh - #{$header_height} - 40px);
			}

			.popup__close {
				color: map_get($colors, black);
				top: 16px;
				right: 16px;
				left: auto;
				bottom: auto;
			}
		}

		&-guide {
			.popup__body {
				box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
				transform: translateX(-100%);
			}
		}
	}
}

@media ($media_max_phone) {
	.popup {
		padding: 70px 20px;

		/* &:after {
			@include icomoonInit();
			content: $icon-close;
			position: fixed;
			color: map_get($colors, white);
			font-size: 20px;
			top: 20px;
			right: 20px;
			z-index: 1;
			transition: opacity 0.3s ease-out;
			pointer-events: none;
			opacity: 0;
		} */

		&__body {
			padding: 20px;
			border-radius: 4px;
		}

		&__close {
			position: fixed;
			top: 20px;
			left: auto;
			right: 20px;

			/* &:not(._show) {
				display: none !important;
			} */
		}

		&-menu {
			pointer-events: none;
			position: fixed;
			top: $header_height_phone;
			left: 0;
			//bottom: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: $popup_zindex;
			display: block;

			html._popup-menu &,
			&._static {
				pointer-events: auto;

				.popup-menu__body {
					opacity: 1;
					transform: translateY(0);
				}
			}

			&__body {
				opacity: 0;
				transition:
					opacity 0.2s ease-out,
					transform 0.2s ease-out;
				position: absolute;
				top: 0;
				left: 0;
				//height: 100%;
				width: 100%;
				background: map_get($colors, white);
				z-index: 2;
				transform: translateY($header_height_phone * -1);
				will-change: transform;
				overflow: auto;
			}
		}

		&-auth,
		&-recovery {
			padding-left: 0;
			padding-right: 0;

			.popup__body {
				border-radius: 0;
			}
		}

		&-form {
			&__body {
				padding: 20px;
			}

			&__message {
				.text._xsm {
					font-size: 13px;
					line-height: 18px;
				}
			}

			&__icon {
				width: 64px;
			}

			&__info {
				margin-bottom: 20px;
				text-align: center;
			}
		}

		&-guide {
			padding: 0;

			&:before,
			&:after {
				display: none;
			}

			.popup__body {
				padding: 64px 0 0 0;
				border-radius: 0;
				width: 100%;
			}

			.popup__close {
				$size: 48px;
				width: $size;
				height: $size;
				line-height: $size;
				display: block !important;
				top: 8px;
				right: 8px;
				font-size: 24px;
			}
		}
	}
}
