@import "config";

.rooms {
	&-category {
		@include hover() {
			.text {
				opacity: 1;

				&:after {
					color: map_get($colors, dblue);
				}
			}
		}

		input {
			top: 0;
			left: 0;
			z-index: -1;
			opacity: 0;
			height: 1px;
			width: 1px;

			&:checked {
				& + .d-flex {
					img,
					.text {
						opacity: 1;
					}

					.text:after {
						font-weight: 500;
						color: map_get($colors, black) !important;
					}
				}
			}
		}

		img,
		.text {
			transition: opacity .2s;
			opacity: .7;
		}

		.text {
			color: map_get($colors, white);

			&:after {
				content: attr(data-text);
				position: absolute;
				top: 0;
				left: 0;
				color: map_get($colors, black);
				font-weight: normal;
				transition: color .2s;
			}
		}
	}
}

.room {
	&-card {
		transition: all .2s;
		border: 1px solid map_get($colors, ulgray);

		@include hover() {
			border-color: transparent;
			box-shadow: 0 2px 20px rgba(0, 0, 0, .08);
		}
	}

	&-item {
		&-head {
			border-radius: 4px 4px 0 0;

			&__icon {
				top: 12px;
				left: 12px;
			}
		}

		&-scale {
			&__label {
				&._top {
					&:before {
						bottom: -5px;
					}

					&:after {
						bottom: -4px;
					}
				}

				&._bottom {
					&:before {
						top: -5px;
					}

					&:after {
						top: -4px;
					}
				}

				&:before,
				&:after {
					content: '';
					position: absolute;
					pointer-events: none;
					right: 50%;
					width: 10px;
					height: 10px;
					border-radius: 2px;
					margin-right: -5px;
					transform: rotate(45deg);
				}

				&:before {
					background: map_get($colors, ulgray);
				}

				&:after {
					background: map_get($colors, white);
				}
			}

			&__gradient {
				background: linear-gradient(90deg, #08A23B 1.3%, #EBCB24 49.11%, #EBCB24 50.65%, #FB4D4E 100%);

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: linear-gradient(90deg, transparent 50%, #fff 50%);
					background-size: 4px 4px;
				}
			}
		}

		&__bonus {
			border-style: dashed !important;
		}
	}

	&-fixed-info {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, .8);
		will-change: transform;
		transition: transform .3s;
		transform: translateY(-100%);
		z-index: $header_zindex + 1;
		backdrop-filter: blur(2px);
	}

	&-task {
		&:not([data-state='ready']) {
			[data-state='ready'] {
				display: none !important;
			}
		}

		&:not([data-state='pending']) {
			[data-state='pending'] {
				display: none !important;
			}
		}

		&[data-state='ready'] {
			[data-state='initial'] {
				display: none !important;
			}
		}

		&[data-state='pending'] {
			[data-state='initial'] {
				display: none !important;
			}

			[data-state='ready'] {
				display: none !important;
			}
		}

		&[data-state='pending'],
		&[data-state='ready'] {
			tr {
				&:first-child td,
				&:last-child td {
					background: map_get($colors, mulgray);
				}
			}
		}

		.gradient-animation {
			top: 8px;
			left: 8px;
			right: 8px;
			bottom: 8px;
		}
	}

	&__tabs {
		box-shadow: 0 -1px 0 0 map_get($colors, ulgray) inset;

		.tab {
			min-height: 0;
			flex: none;
			display: block;
			padding: 0 0 8px;
			font-weight: 400;
			text-transform: none;
			font-size: 15px;
			color: map_get($colors, gray);
			letter-spacing: 0;

			& + .tab {
				margin-left: 24px;
			}

			@include hover() {
				input:not(:checked) ~ .tab__text {
					color: map_get($colors, dblue) !important;
				}
			}

			&__text {
				&:before {
					top: auto;
					bottom: 0;
					height: 2px;
					transform: translateY(100%);
				}
			}
		}
	}
}


@media ($media_max_phone) {
	.room {
		&-item {
			box-shadow: none !important;

			&-scale {
				.tooltip {
					&-trigger {
						position: static;
					}
				}
			}
		}
	}
}
