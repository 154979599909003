@import "config";

.popover {
	left: auto;
	right: 0;
	background: map_get($colors, white);
	border-radius: 4px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .14);
	border: 0;
	transition: opacity .2s, margin-top .2s, transform 0s .2s;
	opacity: 0;
	pointer-events: none;
	margin-top: 10px;
	max-width: 440px;

	&:before,
	&:after {
		content: '';
		position: absolute;
		pointer-events: none;
		right: 30px;
		background: map_get($colors, white);
	}

	&:before {
		width: 10px;
		height: 10px;
		border-radius: 2px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, .14);
		top: -4px;
		margin-right: -5px;
		transform: rotate(45deg);
	}

	&:after {
		width: 20px;
		height: 10px;
		top: 0;
		margin-right: -10px;
	}

	&__head {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: map_get($colors, black);
		padding: 12px 16px;
		display: flex;
		align-items: center;
		min-height: 44px;
		border-bottom: 1px solid map_get($colors, ulgray);
	}

	&__body {
		padding: 16px;
	}

	&-rubrics {
		padding: 0 16px;
		min-width: 168px;
		overflow: auto;
		max-height: 494px;
		position: relative;
		z-index: 10;

		&__item {
			padding: 10px 0;
			position: relative;
			border-top: 1px solid map_get($colors, ulgray);

			&:before {
				content: '';
				position: absolute;
				pointer-events: none;
				left: -16px;
				height: 40px;
				width: 4px;
				background: map_get($colors, blue);
				transition: transform .2s;
				transform: translateX(-100%);
				top: 50%;
				margin-top: -20px;
			}

			&:first-child {
				border-top: 0;
			}

			@include hover() {
				.text {
					color: map_get($colors, blue) !important;
				}
			}

			&._active {
				&:before {
					transform: translateX(0);
				}

				.text {
					font-weight: 500;
					color: map_get($colors, black) !important;
				}
			}
		}

		.img-cover {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}

		.text {
			transition: color .2s;
		}
	}

	&-search {
		position: relative;

		&__input {
			height: 56px;
			width: 485px;
			padding: 0 140px 0 16px;
			font-size: 15px;
			background: map_get($colors, mulgray);

			&:focus {
				background: map_get($colors, white);
			}
		}

		&__submit {
			position: absolute;
			width: 120px;
			top: 8px;
			right: 8px;
		}
	}

	&._center {
		right: 50%;

		&:before,
		&:after {
			right: 50%;
		}
	}

	&._no-arrow {
		&:before,
		&:after {
			display: none;
		}
	}

	.notifications {
		position: relative;
		max-height: calc(100vh - 150px);
	}

	.search-result {
		position: relative;
		max-height: calc(100vh - 165px);
	}

	._open-popover & {
		opacity: 1;
		pointer-events: auto;
		margin: 0;
		transition-delay: 0s, 0s, 0s;

		&._center {
			transform: translateX(50%);
		}
	}
}


@media ($media_max_phone) {
	.popover {
		&:before,
		&:after {
			right: 20px;
		}

		&__head {
			font-size: 15px;
		}

		&-rubrics {
			padding: 0;
			min-width: 0;
			overflow: visible;
			display: flex;
			align-items: center;

			&__item {
				padding: 16px 0;
				height: 56px;
				margin-left: 20px;
				flex-shrink: 0;
				overflow: hidden;
				border-top: 0;

				&:before {
					left: 0;
					height: 3px;
					width: 100%;
					transform: translateY(100%);
					top: auto;
					bottom: 0;
					margin: 0;
				}
			}

			.img-cover {
				width: 20px;
				height: 20px;
				margin-right: 6px;
			}
		}

		&-search {
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow: hidden;

			&__input {
				width: calc(100% - 40px);
				padding: 0 16px;
				margin: 0 20px 16px;
				flex-shrink: 0;
				font-size: 16px;
			}

			&__submit {
				position: relative;
				width: calc(100% - 40px);
				display: flex;
				top: auto;
				right: auto;
				margin: 0 20px 24px;
				flex-shrink: 0;
				min-height: 56px;
				font-size: 15px;
			}
		}

		&._screen {
			position: fixed;
			top: 0 !important;
			right: 0 !important;
			width: 100vw !important;
			box-shadow: none !important;
			height: 100%;
			border-radius: 0;
			padding-top: $header_height_phone;
			margin: 0 !important;
			z-index: -2;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			background: transparent;

			&:before,
			&:after {
				display: none;
			}

			> .popover__head {
				min-height: 0;
				border-top: 1px solid map_get($colors, ulgray);
				border-bottom: 0;
				font-size: 18px;
				flex-shrink: 0;
				padding: 24px 20px 16px;

				.popover__close {
					position: fixed;
					$size: 24px;
					width: $size;
					height: $size;
					right: 20px;
					top: 16px;
					z-index: 10;
				}
			}
		}

		.notifications {
			max-height: none;
			height: 100%;
			overflow: auto;
		}

		.search-result {
			height: 100%;
			overflow: auto;
			max-height: none;
		}
	}
}
