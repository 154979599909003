@import "config";

.profile {
	&__avatar {
		width: 160px;
		height: 160px;
	}

	&__balance {
		min-width: 250px;
	}
}

.wallet {
	&-output {
		margin-top: 12px;

		&__icon {
			$size: 32px;
			width: $size;
			height: $size;
			line-height: $size;
			margin-right: 8px;
			font-size: 21px;
		}

		&__text {
			font-size: 14px;
			line-height: 20px;
			padding-top: 7px;
		}
	}

	&-history {
		&__row {
			min-height: 72px;
			padding: 14px 0;
			border-top: 1px solid map_get($colors, ulgray);
			border-bottom: 1px solid map_get($colors, ulgray);
			margin-top: -1px;
		}

		&__date {
			text-align: center;
			width: 80px;
			margin-right: 60px;
			font-size: 14px;
			line-height: 20px;
		}

		&__time {
			color: map_get($colors, gray);
		}

		&__logo {
			width: 200px;
			margin-right: 18px;

			&-room {
				border-radius: 4px;
				border: 1px solid map_get($colors, ulgray);
				padding: 0;
			}

			.img-center {
				width: 32px;
				height: 32px;
				margin-right: 8px;
				padding: 3px;
			}
		}

		&__out {
			width: 300px;

			.icon {
				$size: 24px;
				width: $size;
				height: $size;
				line-height: $size;
				font-size: 20px;
				margin-right: 8px;

				&.c-green {
					transform: scaleY(-1);
				}
			}

			.text {
				color: map_get($colors, dgray);
			}
		}

		&__sum {
			text-align: right;
			line-height: 20px;
			margin-left: auto;
		}

		&__val {
			font-size: 15px;
		}

		&__status {
			font-size: 14px;
			margin-top: 4px;
		}

		&__more {
			text-align: center;
			margin-top: 24px;
		}
	}
}


@media ($media_min_phone) {
	.wallet {
		&-output {
			margin-bottom: -26px;
		}
	}
}

@media ($media_max_phone) {
	.profile {
		&__avatar {
			width: 140px;
			height: 140px;
		}
	}

	.wallet {
		&-output {
			margin-top: 16px;
		}

		&-history {
			&__row {
				padding: 16px 0;
				flex-wrap: wrap;
			}

			&__date {
				text-align: left;
				width: 100%;
				margin-right: 0;
				color: map_get($colors, dgray) !important;
				display: flex;
				align-items: center;
				margin-bottom: 16px;
			}

			&__day {
				flex-shrink: 0;
				margin-right: 3px;

				&:after {
					content: ', ';
				}
			}

			&__time {
				color: map_get($colors, dgray) !important;
			}

			&__logo {
				width: auto;

				.text {
					font-size: 14px !important;
				}
			}

			&__out {
				width: 100%;
				order: -1;
				margin-bottom: 16px;

				.text {
					color: map_get($colors, black);
					font-size: 14px !important;
				}
			}
		}
	}
}
