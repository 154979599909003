@import "config";
@import "icon_names";

.icon {
	@include icomoonInit();
	line-height: 1;
}

.icon-triangle-top {
	&:before {
		content: $icon-triangle-top;
	}
}
.icon-cards {
	&:before {
		content: $icon-cards;
	}
}
.icon-book {
	&:before {
		content: $icon-book;
	}
}
.icon-rating-null {
	&:before {
		content: $icon-rating-null;
	}
}
.icon-rating {
	&:before {
		content: $icon-rating;
	}
}
.icon-youtube {
	&:before {
		content: $icon-youtube;
	}
}
.icon-skype {
	&:before {
		content: $icon-skype;
	}
}
.icon-twitter {
	&:before {
		content: $icon-twitter;
	}
}
.icon-google {
	&:before {
		content: $icon-google;
	}
}
.icon-insta {
	&:before {
		content: $icon-insta;
	}
}
.icon-ok {
	&:before {
		content: $icon-ok;
	}
}
.icon-telegram {
	&:before {
		content: $icon-telegram;
	}
}
.icon-vk {
	&:before {
		content: $icon-vk;
	}
}
.icon-facebook {
	&:before {
		content: $icon-facebook;
	}
}
.icon-chat {
	&:before {
		content: $icon-chat;
	}
}
.icon-mail {
	&:before {
		content: $icon-mail;
	}
}
.icon-whatsapp {
	&:before {
		content: $icon-whatsapp;
	}
}
.icon-www {
	&:before {
		content: $icon-www;
	}
}
.icon-minus-round {
	&:before {
		content: $icon-minus-round;
	}
}
.icon-plus-round {
	&:before {
		content: $icon-plus-round;
	}
}
.icon-menu-burger {
	&:before {
		content: $icon-menu-burger;
	}
}
.icon-exit {
	&:before {
		content: $icon-exit;
	}
}
.icon-arrow-down {
	&:before {
		content: $icon-arrow-down;
	}
}
.icon-people {
	&:before {
		content: $icon-people;
	}
}
.icon-fish {
	&:before {
		content: $icon-fish;
	}
}
.icon-exchange {
	&:before {
		content: $icon-exchange;
	}
}
.icon-gears {
	&:before {
		content: $icon-gears;
	}
}
.icon-wallet {
	&:before {
		content: $icon-wallet;
	}
}
.icon-gift {
	&:before {
		content: $icon-gift;
	}
}
.icon-info-bubble {
	&:before {
		content: $icon-info-bubble;
	}
}
.icon-voice {
	&:before {
		content: $icon-voice;
	}
}
.icon-reward {
	&:before {
		content: $icon-reward;
	}
}
.icon-treasure {
	&:before {
		content: $icon-treasure;
	}
}
.icon-ticket {
	&:before {
		content: $icon-ticket;
	}
}
.icon-timetable {
	&:before {
		content: $icon-timetable;
	}
}
.icon-money-transfer {
	&:before {
		content: $icon-money-transfer;
	}
}
.icon-cash-hand {
	&:before {
		content: $icon-cash-hand;
	}
}
.icon-info-circle {
	&:before {
		content: $icon-info-circle;
	}
}
.icon-chip-v {
	&:before {
		content: $icon-chip-v;
	}
}
.icon-todo {
	&:before {
		content: $icon-todo;
	}
}
.icon-reply {
	&:before {
		content: $icon-reply;
	}
}
.icon-plane-solid {
	&:before {
		content: $icon-plane-solid;
	}
}
.icon-plane {
	&:before {
		content: $icon-plane;
	}
}
.icon-bookmark-solid {
	&:before {
		content: $icon-bookmark-solid;
	}
}
.icon-bookmark {
	&:before {
		content: $icon-bookmark;
	}
}
.icon-comment-solid {
	&:before {
		content: $icon-comment-solid;
	}
}
.icon-comment {
	&:before {
		content: $icon-comment;
	}
}
.icon-star {
	&:before {
		content: $icon-star;
	}
}
.icon-star-null {
	&:before {
		content: $icon-star-null;
	}
}
.icon-wifi {
	&:before {
		content: $icon-wifi;
	}
}
.icon-bell-solid {
	&:before {
		content: $icon-bell-solid;
	}
}
.icon-bell {
	&:before {
		content: $icon-bell;
	}
}
.icon-info {
	&:before {
		content: $icon-info;
	}
}
.icon-megaphone {
	&:before {
		content: $icon-megaphone;
	}
}
.icon-quote {
	&:before {
		content: $icon-quote;
	}
}
.icon-clock {
	&:before {
		content: $icon-clock;
	}
}
.icon-warning {
	&:before {
		content: $icon-warning;
	}
}
.icon-blocked {
	&:before {
		content: $icon-blocked;
	}
}
.icon-minus {
	&:before {
		content: $icon-minus;
	}
}
.icon-plus {
	&:before {
		content: $icon-plus;
	}
}
.icon-close {
	&:before {
		content: $icon-close;
	}
}
.icon-pen {
	&:before {
		content: $icon-pen;
	}
}
.icon-video {
	&:before {
		content: $icon-video;
	}
}
.icon-link {
	&:before {
		content: $icon-link;
	}
}
.icon-camera {
	&:before {
		content: $icon-camera;
	}
}
.icon-image {
	&:before {
		content: $icon-image;
	}
}
.icon-search {
	&:before {
		content: $icon-search;
	}
}
.icon-login {
	&:before {
		content: $icon-login;
	}
}
.icon-chevron-right {
	&:before {
		content: $icon-chevron-right;
	}
}
.icon-check-circle {
	&:before {
		content: $icon-check-circle;
	}
}
.icon-check {
	&:before {
		content: $icon-check;
	}
}
.icon-arrow-right {
	&:before {
		content: $icon-arrow-right;
	}
}
.icon-avatar {
	&:before {
		content: $icon-avatar;
	}
}
.icon-download {
	&:before {
		content: $icon-download;
	}
}
.icon-gear {
	&:before {
		content: $icon-gear;
	}
}
.icon-clock2 {
	&:before {
		content: $icon-clock2;
	}
}
.icon-mic {
	&:before {
		content: $icon-mic;
	}
}
.icon-youtube-play {
	&:before {
		content: $icon-youtube-play;
	}
}
.icon-bubble {
	&:before {
		content: $icon-bubble;
	}
}
.icon-dots {
	&:before {
		content: $icon-dots;
	}
}
.icon-fire {
	&:before {
		content: $icon-fire;
	}
}
.icon-eye {
	&:before {
		content: $icon-eye;
	}
}
.icon-chevron-top {
	&:before {
		content: $icon-chevron-top;
	}
}
