@import "config";

@font-face {
	font-family: 'icomoon';
	src: url($fonts_dir + 'icomoon.ttf') format('truetype'),
	url($fonts_dir + 'icomoon.woff') format('woff'),
	url($fonts_dir + 'icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
