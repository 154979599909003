@import "config";
@import "icon_names";

.header {
	position: absolute;
	top: $header_height;
	left: 0;
	width: 100%;
	will-change: transform;
	z-index: $header_zindex;
	transform: translateY(-100%);
	min-width: $content_min_width;
	background: map_get($colors, white);
	transition: box-shadow 0.3s;
	height: $header_height;

	&__sticky_element {
		// display: none;
		visibility: hidden;
		height: 0;
		width: 0 !important;
		// max-width: 200px;
		// width: 200px;
		// transition: all .2s;
	}
	&._fixed {
		position: fixed;
		top: 0;
		transition:
			transform 0.3s,
			box-shadow 0.3s;
		box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
		transform: translateY(0);
		height: 50px;

		.content-layout:first-child {
			@media ($media_min_phone) {
				display: none;
			}
		}

		.header__sticky_element {
			// display: flex;
			visibility: visible;
			height: 100%;
			width: auto !important;
			margin-left: auto;

			&.header__logo {
				margin-left: 0;
				margin-right: 20px;
			}
		}

		& ~ .room-fixed-info {
			transform: translateY(0);
		}

		.main-menu-list:not(.submenu) {
			padding-block: 1px;
		}

		.header-search,
		.header-notifications,
		.header-gift {
			margin-left: 15px !important;
		}

		.header__btn-login {
			@media ($media_min_phone) {
				width: auto;
				padding-inline: 15px;
			}
		}
	}

	html._popup-menu &,
	html._popover-header & {
		position: fixed;
		top: 0;
		transform: translateY(0);
		box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
	}

	&__logo {
		width: 200px;
		top: 18px;
		left: 0;
		transform: translateX(11%);
		&._sm {
			position: relative;
			width: 40px;
			top: 0;
			// margin-right: 80px;
		}
	}

	&__row {
		height: 72px;
	}

	&__btn {
		&-login {
			width: 140px;
		}
	}

	&-user {
		& ~ .header-search {
			.header-search__popover-form {
				right: -100px;
			}
		}

		&__avatar,
		&__icon {
			cursor: pointer;
		}

		&__icon {
			$size: 24px;
			width: $size;
			height: $size;
			line-height: $size;
			margin-left: 4px;
			font-size: 8px;
			transform: scaleY(-1);
			transition: transform 0.2s;

			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: $size + 4px;
			}
		}

		&__popover {
			top: 61px;
			min-width: 240px;

			&._sticky-element {
				top: 41px;
				border-radius: 0;
				border-top: 1px solid #eff3f9;
				&::before {
					box-shadow: none;
					width: 0;
					height: 0;
				}
			}
			&:before,
			&:after {
				right: 52px;
			}

			a {
				color: map_get($colors, dgray);

				@include hover() {
					font-weight: 500;
					color: map_get($colors, dblue);
				}
			}
		}

		&._open-popover {
			.header-user__icon {
				transform: scaleY(1);
			}
		}
	}

	&-notifications,
	&-search {
		&__icon {
			$size: 24px;
			width: $size;
			height: $size;
			line-height: $size;
			font-size: 21px;
			cursor: pointer;
		}
	}

	&-notifications {
		&._open-popover {
			.header-notifications__icon {
				&:before {
					opacity: 0;
				}

				&:after {
					opacity: 1;
				}
			}
		}

		&__icon {
			$font_size: 23px;
			font-size: $font_size;
			position: relative;

			&.unread {
				&:after {
					content: '';
					display: block;
					width: 9px;
					aspect-ratio: 1;
					background-color: #000;
					border: 2px solid #fff;
					border-radius: 50%;
					position: absolute;
					top: 5px;
					right: 3px;
					z-index: 1;
					transition: background-color .2s, border-color .2s;
				}
			}
		
			&:before {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				background-color: #EFF3F9;
				border-radius: 4px;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				transform: translate(-50%, -50%);
				transition: opacity .2s;
				opacity: 0;
			}

			&:hover {
				&:before {
					@media ($media_min_phone) {
						opacity: 1;
					}
				}

				&.unread:after {
					background-color: #3564A0;
					border-color: #EFF3F9;
				}

				._icon path {
					fill: #3564A0;
				}
			}
		
			._icon {
				display: block;
				width: 24px;
				height: 24px;
				
				path {
					fill: #000;
					transition: fill .2s;
				}
			}
		}

		&__popover {
			width: 100vw;
			right: -16px;
			top: 40px;
		}
	}

	&-search {
		position: relative;

		&._open-popover {
			.header-search__popover-result {
				opacity: 0;
				pointer-events: none;
				margin-top: 10px;
				transition-delay: 0s, 0s, 0.2s;

				&._show-result {
					opacity: 1;
					pointer-events: auto;
					margin: 0;
					transition-delay: 0.2s, 0.2s, 0s;
				}
			}
		}

		&__icon {
			position: relative;
		
			&:before {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				background-color: #EFF3F9;
				border-radius: 4px;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				transform: translate(-50%, -50%);
				transition: opacity .2s;
				opacity: 0;
			}

			&:hover {
				&:before {
					@media ($media_min_phone) {
						opacity: 1;
					}
				}

				._icon path {
					fill: #3564A0;
				}
			}
		
			._icon {
				display: block;
				width: 24px;
				height: 24px;
				
				path {
					fill: #000;
					transition: fill .2s;
				}
			}
		}

		&__popover {
			&-form {
				right: -164px;
				top: -14px;
				max-width: none;
				box-shadow: none;
				margin: 0;
			}

			&-result {
				max-width: none;
				width: 100%;
				top: 71px;
				right: 0;
			}
		}
	}

	&-gift {
		cursor: pointer;
		//position: relative;

/* 		&.show-phone {
			width: 24px;
			height: 24px;
			right: 64px;
			top: 16px;
		} */

		&__icon {
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		
			&:before {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				background-color: #EFF3F9;
				border-radius: 4px;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				transform: translate(-50%, -50%);
				transition: opacity .2s;
				opacity: 0;
			}

			&:hover {
				&:before {
					@media ($media_min_phone) {
						opacity: 1;
					}
				}

				._icon path {
					fill: #3564A0;
				}
			}
			
			._icon {
				display: block;
				width: 24px;
				height: 24px;
				
				path {
					fill: #000;
					transition: fill .2s;
				}
			}
		}

		&__popover {
			width: 420px;
			right: -173px;
			top: 40px;
			@media ($media_max_phone) {
				width: 90vw;
				right: -45px;
				top: 55px;
			}
			&-wrapper {
				max-height: 70vh;
				overflow-y: auto;
			}
			.popover__close {
				position: absolute;
				right: 0;
				top: 0;
				transform: translateY(-50%);
			}
			.popover__body ~ .popover__body {
				border-top: 1px solid map-get($colors, pblue);
			}
		}
		.poker-rooms__card-right-side-btn-review {
			padding: 10px;
		}
	}
	.main-menu {
		//height: 48px;
		border-top: 1px solid map_get($colors, ulgray);
		position: relative;
		max-width: 1220px;
		padding: 0 20px;
		margin: 0 auto;
		width: 100%;
		flex-shrink: 0;
	}

	&__sticky_element {
		.header-user {
			&__avatar {
				width: 32px;
				height: 32px;
			}
			&__icon {
				margin-left: 0;
			}
			& ~ .header-search {
				.header-search__popover-form {
					right: -85px;
				}
			}
		}
		.header__btn-login {
			padding: 8px 24px;
			max-width: 90px;
		}
		.header-search__popover-form {
			top: -10px;
			padding: 6px;
			background: white;
			right: -100px;
		}
		.header-search__popover-result {
			top: 43px;
			border-radius: 0;
			&:before {
				width: 0;
				height: 0;
			}
		}
		.popover-search {
			&__input {
				height: 32px;
				width: 318px;
				margin-right: 105px;
			}
			&__submit {
				width: 100px;
				max-height: 32px;
				min-height: 32px;
				height: 32px;
				top: 0px;
				right: 0px;
				padding: 6px 24px;
			}
		}
	}
}

.sticky-header {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	width: 100%;
	background: map-get($colors, white);
	z-index: 20;
	display: none;

	&__wrapper {
		width: 100%;
		height: 100%;
		.sticky-header__room-button img {
			display: block;
		}
		.sticky-header__room-button .close-icon {
			display: none;
		}
		.sticky-header__room-button .counter {
			display: flex;
		}

		&.menu-open {
			.sticky-header__room-button img {
				display: none;
			}
			.sticky-header__room-button .close-icon {
				display: block;
			}
			.sticky-header__room-button .counter {
				display: none;
			}
		}
	}

	&__item {
		display: flex;
		width: 64px;
		height: 56px;
		padding: 4px 2px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;
		flex-shrink: 0;
		padding-bottom: 10px;
		&-text {
			color: map-get($colors, black);
			text-align: center;
			font-size: 10px;
			font-style: normal;
			line-height: 10px;
		}
		&-svg {
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 20px;
				height: 20px;
				path[fill],
				g g path[fill],
				rect[fill] {
					fill: #000;
				}

				path[stroke],
				g g path[stroke],
				rect[stroke] {
					stroke: #000;
				}
			}
		}

		&._active {
			svg {
				path[fill],
				g g path[fill],
				rect[fill] {
					fill: #346eb8;
				}
				path[stroke],
				g g path[stroke],
				rect[stroke] {
					stroke: #346eb8;
				}
			}
		}
	}
	&__room-button {
		display: flex;
		width: 64px;
		height: 46px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		position: relative;
		cursor: pointer;
		background-color: map-get($colors, white);
		&::before {
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="12" viewBox="0 0 64 12" fill="none"><path d="M0 12C0 5.37258 5.37258 0 12 0H52C58.6274 0 64 5.37258 64 12H0Z" fill="white"/></svg>');
			position: absolute;
			top: -12px;
		}
		.counter {
			position: absolute;
			right: 0;
			top: 0;
			color: map-get($colors, white);
			text-align: center;
			font-size: 10px;
			font-weight: 700;
			line-height: 10px;
			width: 14px;
			height: 14px;
			padding: 10px;
			justify-content: center;
			align-items: center;
			border-radius: 100px;
			background: map-get($colors, green);
		}
	}
	&__body-bg {
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(4px);
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 11;
	}
	&__swiper {
		position: fixed;
		bottom: 66px;
		z-index: 100;
		left: 0;
		right: 0;
		width: 100%;
		.swiper-prev-preview,
		.swiper-next-preview {
			width: 32px;
			height: 32px;
			background-size: cover;
			background-position: center;
		}
	}
}

@media ($media_max_phone) {
	.footer {
		padding-bottom: 60px;
	}
	.header {
		top: $header_height_phone;
		min-width: $content_min_width_phone;
		height: $header_height_phone;

		html._popup-menu & {
		//html._popover-header & {
			.header__btn-menu {
				div {
					background: transparent;
	
					&:before,
					&:after {
						width: 32px;
						right: -4px;
						top: 0;
					}
	
					&:before {
						transform: rotate(45deg);
					}
	
					&:after {
						transform: rotate(-45deg);
					}
				}
			}
		}

		@media ($media_min_phone) {
			html._popover-header & {
				height: 100%;
		
				.header__btn-menu {
					z-index: -2;
				}
			}
		}

		&__logo {
			width: 138px;
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
		}

		&__row {
			height: 0;
			display: none;
		}

		&__btn {
			&-menu {
				$size: 24px;
				width: $size;
				height: $size;
				left: 10px;
				top: 16px;

				div {
					top: 50%;
					margin-top: -1px;
					height: 2px;
					background: map_get($colors, black);
					width: 100%;
					transition: background-color 0.2s;

					&:before,
					&:after {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						width: 100%;
						height: 100%;
						transition: all 0.2s;
						background: map_get($colors, black);
					}

					&:before {
						top: -8px;
						width: 50%;
					}

					&:after {
						top: 8px;
					}
				}
			}
		}

		&-search {
			&._open-popover {
				.header-search__popover-result {
					&._show-result {
						transition-delay: 0s, 0s, 0s;
					}
				}
			}

			&__popover {
				&-result {
					position: static;
					width: auto;
					top: auto;
					right: auto;
					box-shadow: none !important;
					border-radius: 0;
					display: flex;
					flex-direction: column;
					height: 100%;
					overflow: hidden;
					margin: 0 !important;

					&:before,
					&:after {
						display: none;
					}

					.popover__head {
						min-height: 0;
						border-bottom: 1px solid map_get($colors, ulgray);
					}
				}
			}
		}
	}
	.sticky-header {
		display: block;
	}
}


//--- new mobile menu---
.header {
	@media ($media_max_phone) {
		.content-layout {
			height: 100%;
		}

		.header__row {
			height: 100%;
			flex-direction: row !important;
			justify-content: flex-end !important;
			padding-right: 10px;
		}

		.header__row .header-search {
			display: none !important;
		}

		.header-user {
			.open-profile & .header-user__icon {
				transform: scaleY(1);
			}
			.avatar {
				width: 32px;
				height: 32px;
			}
		}

		.header-user__popover {
			display: flex;
			flex-direction: column;
			width: 100%;
			max-width: none;
			top: 50px;
			margin: 0 !important;
			padding: 16px;
			box-shadow: none !important;
			border-radius: 0;
			border-top: 1px solid #EFF3F9;

			.open-profile & {
				opacity: 1;
				pointer-events: auto;
			}

			&:before,
			&:after {
				display: none;
			}

			.popover__head {
				display: none;
			}

			//vzm balance
			.px-16.py-1.c-bg-pgreen {
				order: -1;
				border-radius: 4px;
				padding-block: 8px !important;

				.c-blue.fs-13.lh-18 {
					display: none;
				}

				.fs-15.lh-20 {
					font-family: $font_roboto;
					font-weight: 700;
					
					.c-green {
						display: inline-block;
						margin-right: 8px;
						font-size: 15px;
						line-height: 20px;
					}
					.c-black {
						display: inline-block;
						font-size: 18px;
						line-height: 26px;
					}
				}
			}

			//profile
			.p-16 {
				border-bottom: 1px solid #E9E9E9;
				
				.avatar {
					width: 64px;
					height: 64px;
				}

				.text.fw-med {
					width: 100%;
					max-width: 60vw;

					.mobile-link {
						display: table;
						margin-top: 4px;
						color: #346EB8;
						font-size: 14px;
						font-weight: 400;
						line-height: 24px;
						text-decoration: underline;

						&:hover {
							text-decoration: none;
						}
					}
				}
				.link-abs {
					display: none;
				}
			}

			//profile menu list
			.px-16.pb-16 {
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;

				.text {
					&:hover {
						color: #6C6B6B;
					}
				}
			}

			//line border
			.h-1.c-bg-ulgray {
				display: none !important;
			}

			//btn logout
			.text.d-block.p-16.text-center.c-red {
				margin-top: 16px;
				padding-block: 10px !important;
				background-color: #FB4D4E;
				border: none !important;
				border-radius: 4px;
				color: #fff !important;
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
				transition: background-color .2s;

				&:hover {
					background-color: lighten(#FB4D4E, 10%);
				}
			}
		}// /.header-user__popover

		.header-notifications {
			//display: none;
			margin: 0 !important;
			//width: 20px;
			//height: 20px;
			position: absolute !important;
			top: 130px;
			right: 20px;
			z-index: 1111;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: all .2s;

			.open-profile & {
				//display: block;
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}

			&__icon {
				display: block !important;

				._open-popover & {
					&:before {
						opacity: 0;
					}

					&:after {
						opacity: 1;
					}
				}
			}

			&__badge {
				display: block !important;
			}

			&__popover {
				padding: 0;
				max-width: 100%;
				height: 50dvh;
				position: fixed !important;
				top: 160px !important;
				left: 0 !important;
				right: auto !important;
				z-index: 11 !important;
				overflow: auto !important;
				background-color: #fff;

				.popover__head {
					padding-block: 15px;
					border-bottom: 1px solid #e9e9e9;
				}

				.notifications__item:first-child {
					padding-top: 16px;
				}
			}
		}// /.header-notifications

		.header__row .header-gift {
			display: block !important;
			margin: 0 !important;
			position: absolute !important;
			top: 15px;
			left: 60px;
			z-index: 1;

			&.open-gifts .header-gift__popover {
				opacity: 1;
				pointer-events: auto;
			}

			/* &__icon {
				width: 24px;
				height: 24px;
			} */

			&__popover {
				width: 100vw !important;
				max-width: 100%;
				position: fixed;
				top: 50px;
				left: 0;
				right: auto;
				margin: 0 !important;
				box-shadow: none !important;
				border-radius: 0;

				._open-popover & {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}// /media
}// /.header

.header .header__btn-login {
	@media ($media_max_phone) {
		width: auto;
		height: auto;
		margin: 0 !important;
		padding: 0;
		background: none;
		font-size: 0;
	}

	.header__login_mob_icon {
		width: 32px;
		height: 32px;

		@media ($media_min_phone) {
			display: none;
		}
	}
}
.main-menu {
	@media ($media_max_phone) {
		width: 100%;
		flex-direction: column;
		padding: 0 !important;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		background-color: #fff;
	}
	

	.header-mob-search {
		width: 100%;
		padding: 8px 10px;
		text-align: center;

		@media ($media_min_phone) {
			display: none;
		}

		@media ($media_max_phone) {
			.header-search {
				margin: 0 !important;
			}

			.header-search__popover-form.popover {
				display: block;
				position: static !important;
				padding: 0 !important;
				width: auto !important;
				height: auto !important;
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
	
				&:before,
				&:after {
					display: none;
				}
			}

			.popover-search {
				flex-direction: row;
				align-items: center;
				column-gap: 10px;
				position: relative;

				&::before {
					content: '';
					display: block;
					width: 15px;
					height: 15px;
					background-image: url('data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAntJREFUSInFlsF12kAQhv8ZwQVf6CB0EFJBcAVB79lP6AYVGFcA7sCuQPFpH3rPT0oF4ApCCSqBkziI1eQiyYsQksgzydy02p1vZ3bnnwX+k1HdT8/z+r1eb87M3wEMRaSf/doCiJg5vLu7e/00sOd5/ZubmyWAhxY+ImZeXrqBE7Dv+0MAAYDBJY5EJIzjeDabzXYXg5VSg06n89tIaW4bAKGI5E6HAMZEdLQ5EdnGcXzbBl6AlVIDy7LWpUg3WuuZ67pR1WKl1JSZF+YGROTZcZzH1mDf9z0AU8PB0nGcpyYHSqkBM69NuNb61nXdTd06zhebUABhGygAuK4bpWl6S0RFei3LWjStYwDodrs/zEGtdWOqynAAz8bQ6O3t7UsjWETG+YCIhOfOtM46nc6L+a21Hp+fnYGzWwoAIKLaszlntm3viGibf4tIbTnmERflw8yt6rDKRKQAE1G5JE/Bn2Xlum4DLs5Uaz08P73RiigNsakFF+dKRH8FVkoNRKRYKyLNdQzg3RgbKaVGl4LLtbvf79/Pz/6o47AkAF4mKq1stVqNSwL0s0mvGVkpaK1NpRpYlrVuA1+tVmNm9oyhsq9KO+pOvu8HAMzCj5h5mSTJe1lUsvb5UIoUaZrOJ5PJkZg0goMg6CdJsjYFpZhItCm1xcpsENGGiKIkSZ7qFPDkIRAEQf9wOCxEZN60a8N2aZoumXkMIL+YUdalKuEnAmLb9u7+/v4xc9AknzsRedZaf8vSa0Jq70ntYw8fLXPEzF9zGcxSvo3j+Jd5e6t687nIG8GXWlv4p4Pbwq8CbgO/GrgJflVwHfzq4Cq4iEz/CdiEA1g6jvP6Bx2JRVIUOiAlAAAAAElFTkSuQmCC');
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					top: 50%;
					left: 10px;
					z-index: 1;
					transform: translateY(-50%);
					pointer-events: none;
				}

				&.typing {
					input {
						width: auto !important;
					}
					.search-mob-input-clear {
						z-index: 1;
						opacity: 1;
						visibility: visible;
					}
				}

				.search-mob-input-clear {
					display: block;
					width: 10px;
					height: 10px;
					position: absolute;
					top: 50%;
					right: 80px;
					z-index: -1;
					transform: translateY(-50%);
					opacity: 0;
					visibility: hidden;

					.mob-input-clear-icon {
						display: block;
						width: 10px;
						height: 10px;
					}
				}
			}// /.popover-search

			input {
				flex-grow: 1;
				width: 100% !important;
				height: auto !important;
				margin: 0 !important;
				padding: 6px 10px 6px 40px;
				border-radius: 4px;
				position: relative;

				&::placeholder {
					color: #aaaaaa;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
				}
			}

			.popover-search__submit {
				width: auto;
				min-width: auto;
				max-width: none;
				height: auto;
				min-height: auto;
				max-height: none;
				margin: 0;
				padding: 6px 8px;
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
			}

			.header-search__popover-result {
				display: none;
				height: 60dvh;
				padding-top: 20px;
				position: absolute;
				top: 38px;
				left: -10px;
				right: -10px;
				z-index: 2;
				opacity: 1;
				pointer-events: auto;
				overflow: auto;
				background-color: #EFF3F9;

				&._show-result {
					display: block;
				}

				.popover__head {
					margin: 0 !important;
					padding-inline: 20px !important;
				}
			}
		}// /media
	}// /.header-mob-search

	.main-menu-list {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style-type: none;
		column-gap: 16px;

		@media ($media_max_phone) {
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
		}

		&.submenu {
			display: none;
			width: 100%;
			min-width: 200px;
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 1;
			background-color: #fff;

			.main-menu-item {
				position: relative;
				color: #000;
				transition: background-color .2s;
				
				@media ($media_max_phone) {
					&.hidden + .main-menu-item.opened {
						border-top: none;
					}
				}
				
				@media ($media_min_phone) {
					border-bottom: 1px solid #e9e9e9;

					&.dropdown .submenu {
						top: 0;
						left: 100%;
					}
					&:hover {
						background-color: #eff3f9;
						border-color:#e9e9e9;
			
						.dropdown-menu-list {
							display: block;
						}
					}

					a {
						width: 100%;
						padding-inline: 20px;
					}
				}
			}
		}// /.submenu

		.main-menu-item {
			display: flex;
			align-items: center;
			transition: border .2s, color .2s;

			@media ($media_min_phone) {
				border-bottom: 2px solid transparent;
		
				&:hover {
					border-color: #3564A0;
				}
			}
		
			@media ($media_max_phone) {
				width: 100%;
				border-top: 1px solid #e9e9e9;
			}

			&.dropdown {
				position: relative;

				@media ($media_max_phone) {
					&.opened {
						> a {
							padding-left: 34px;
							color: #6C6B6B;
							transition-property: none;

							&:after {
								right: auto;
								left: 20px;
								transform: translateY(-50%) rotate(90deg);
							}
						}
					}
					> a {
						pointer-events: none;
						cursor: pointer;
					}
				}

				@media ($media_min_phone) {
					&:hover {
						> .submenu {
							display: block;
						}
					}
				}

				.dropdown > a {
					@media ($media_min_phone) {
						padding-right: 20px;

						&:after {
							transform: translateY(-50%) rotate(-90deg);
						}
					}
				}

				> a {
					@media ($media_min_phone) {
						padding-right: 34px;
					}
			
					&:after {
						content: '';
						display: block;
						width: 8px;
						height: 5px;
						position: absolute;
						top: 50%;
						right: 16px;
						z-index: 0;
						transform: translateY(-50%);
						background-image: url('data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAALCAYAAACZIGYHAAAAAXNSR0IArs4c6QAAAGlJREFUKJFjYKAiOM/AwPCfTHyfCWrIBwoccADGEGBgYLhPjisYGBgUkE1UYGBgeE+CAe/RDYABBxIMCcDnxwIiDKgnJrD68RjQT3yYY4/2+6QYwAANtPtoBmANSELAAKoZZ0wwMDAwAADXylGSLsdeSQAAAABJRU5ErkJggg==');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
			
						@media ($media_max_phone) {
							transform: translateY(-50%) rotate(-90deg);
						}
					}
				}
			}// /.dropdown

			a {
				display: block;
				padding: 13px 8px;
				font-size: 12px;
				font-weight: 700;
				line-height: 20px;
				letter-spacing: 1.5px;
				text-transform: uppercase;

				@media (min-width: 576px) and (max-width: 992px) {
					font-weight: 500;
				}
				@media ($media_max_phone) {
					width: 100%;
					padding-inline: 20px;

					&.hidden + .submenu > .main-menu-item.opened {
						border-top: none;
					}
				}
			}
		}// /.main-menu-item
	}// /.main-menu-list
}// /.main-menu