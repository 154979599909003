@import "config";

.widget {
	background: map_get($colors, white);
	margin-bottom: 20px;
	padding: 24px;

	&-profile-menu {
		> a {
			@include hover() {
				font-weight: 500;
			}

			&._active {
				font-weight: 500;
			}
		}
	}

	&-rubrics {
		&__item {
			transition: background .2s, color .2s;

			@include hover() {
				background: map_get($colors, mulgray);
				color: map_get($colors, blue);
			}

			&._active {
				background: map_get($colors, mulgray);
				color: map_get($colors, blue);
			}
		}
	}
}


@media ($media_max_phone) {
	.widget {
		margin-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}
}
